import React from 'react';
import {Link} from 'react-router-dom';

// Images
import Statistikat from '../../../images/Statistics.png';

// CSS
import './TeSkaduara.scss';

const TeSkaduara = () => {
    // const [active, setActive] = useState(true);

    return (
        <>
            <div className="TeSkaduara">
                <div className="statistikat1">
                    <p>Statistikat e Tranzakcioneve per Produkt Skaduara</p>
                    <div className="statistikat">
                        <img src={Statistikat} alt="statistikat"/>
                    </div>
                </div>
                <div className="op">
                    <div className="kerkesa_tereja">
                        <div className="emri_produktit">
                            <p className="produkt">Chees Burger</p>
                            <p className="person">Filan Fisteku</p>
                        </div>
                        <div className="data">
                            <p className="data_text">Data</p>
                            <p className="data_">26/03/2021</p>
                        </div>
                        <hr/>
                        <div className="prano_anulo">
                            <Link to="/">
                                <span className="prano">Prano</span>
                            </Link>
                            <Link to="/">
                                <span className="anulo">Anulo</span>
                            </Link>
                        </div>
                    </div>
                    <div className="kerkesa_tereja">
                        <div className="emri_produktit">
                            <p className="produkt">Chees Burger</p>
                            <p className="person">Filan Fisteku</p>
                        </div>
                        <div className="data">
                            <p className="data_text">Data</p>
                            <p className="data_">26/03/2021</p>
                        </div>
                        <hr/>
                        <div className="prano_anulo">
                            <Link to="/">
                                <span className="prano">Prano</span>
                            </Link>
                            <Link to="/">
                                <span className="anulo">Anulo</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="op2">
                    <div className="kerkesa_tereja">
                        <div className="emri_produktit">
                            <p className="produkt">Chees Burger</p>
                            <p className="person">Filan Fisteku</p>
                        </div>
                        <div className="data">
                            <p className="data_text">Data</p>
                            <p className="data_">26/03/2021</p>
                        </div>
                        <hr/>
                        <div className="prano_anulo">
                            <Link to="/">
                                <span className="prano">Prano</span>
                            </Link>
                            <Link to="/">
                                <span className="anulo">Anulo</span>
                            </Link>
                        </div>
                    </div>
                    <div className="kerkesa_tereja">
                        <div className="emri_produktit">
                            <p className="produkt">Chees Burger</p>
                            <p className="person">Filan Fisteku</p>
                        </div>
                        <div className="data">
                            <p className="data_text">Data</p>
                            <p className="data_">26/03/2021</p>
                        </div>
                        <hr/>
                        <div className="prano_anulo">
                            <Link to="/">
                                <span className="prano">Prano</span>
                            </Link>
                            <Link to="/">
                                <span className="anulo">Anulo</span>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default TeSkaduara;
