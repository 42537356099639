import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

// CSS
import './ShtoStokun.scss';
import jwt_decode from 'jwt-decode';



const FshijStokun = (props) => {
  const history = useHistory();

  const [stockData, addstockData] = useState([]);

  const [productData, setproductData] = useState([]);
  const [measurmentsData, setMeasurmentsData] = useState([]);

  // const [errors, addErrors] = useState(false);

//   const token = window.localStorage.getItem('cd');
//   const decode = token ? jwt_decode(token) : null;


  const fshijStokunn = (e) => {
    e.preventDefault();
    // console.log('data', stockData);
    // console.log('tikeni', decode);
    // console.log('categories', productData);

    axios.delete('/admin/store-stock/' + props.match.params.id)
      .then(res => {
        console.log(res.data);
        toast.success('Stoku u fshij me sukses!');
        history.goBack();
      })
      .catch(err => {
        toast.error('Diçka shkoi gabim!');
        history.goBack();
        // window.localStorage.removeItem('cd'); 
        // window.location = "/login";
        console.log(err);
      });
      // window.history.back();

  }

  useEffect(() => {
    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;
    axios.get('/admin/store-stock/' + props.match.params.id)
    .then((res) => {
    //   console.log('stockData', res.data);
      addstockData(res.data);
    })
    .catch(err => {
      console.log(err);
    //   window.localStorage.removeItem('cd'); 
    //   window.location = "/kyqu"; 
    });


    axios.get('/admin/store-products/all')
      .then((res) => {
        // console.log('axios', res.data);
        setproductData(res.data);
      })
      .catch(err => {
        console.log(err);
        window.localStorage.removeItem('cd'); 
        window.location = "/kyqu"; 
      });
    axios.get('/general/product-measurments/all')
      .then((res) => {
        // console.log('axios', res.data);
        setMeasurmentsData(res.data);
      })
      .catch(err => {
        console.log(err);
        window.localStorage.removeItem('cd'); 
        window.location = "/kyqu"; 
      })
  }, [props.match.params.id])



  return (
    <>
      <div className="shto_produkt">
            <div className="fjeshil-container">
              <div className="row">

                <h3 className='p-3 w-100'>Fshij Stokun</h3>
                <h6 className='p-3 w-100'>Konfirmo fshirjen e stokut për produktin në vijim:</h6>
                <div className="col-md-8">
                  <div className="shp_groupform">
                    <form onSubmit={fshijStokunn}>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Emri i Produktit"
                        onChange={emri}
                        required
                      /> */}
                      <label>
                        <select  value={stockData.product_id} className="form-control" disabled >
                          <option value={null}>Produkt Pa Emer</option>
                          {productData?.map(
                            (el, index) => {
                              return <option key={index} value={el._id}>{el.name}</option>
                            }
                          )}


                        </select>
                      </label>
                      <label>
                        <select value={stockData.is_payed} className="form-control"  disabled>
                          <option value={null}>Pagesa nuk eshte deklaruar</option>
                          <option value={true}>Me Pagese</option>
                          <option value={false}>Donacion</option>
                        </select>
                      </label>
                      <label>
                        <select  value={stockData.messurment_id} className="form-control" disabled >
                          <option value={null}>Njesia Matese nuk eshte deklaruar</option>
                          {measurmentsData?.map((el, index) => {
                            return <option value={el._id}>{el.name}</option>

                          })}
                        </select>
                      </label>
                      <input type='text' className="form-control" placeholder='Sasia (numer)' value={stockData?.qty +' qty'}  disabled></input>
                      <label>
                        {/* Çmimi per cope */}
                        <input type='text' className="form-control" placeholder='Çmimi per cope' value={stockData?.price + ' EUR'} disabled></input>
                      </label>
                      <label>
                        Data e Skadimit
                      <input type='text' className="form-control" value={stockData?.expiration_date?.slice(0,10) }  disabled></input>
                      </label>

                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Cmimi"
                        required
                      /> */}
                      {/* <input
                        type="file"
                        name='file'
                        className="form-control"
                        placeholder="picture"
                        onChange={image}
                        required
                      /> */}
                      <button type="submit">Fshij </button>

                      {/* store_id: req.params.sid,
        product_id: req.body.product_id,
        is_payed: req.body.is_payed,
        messurment_id: req.body.messurment_id,
        // pic_url: req.body.pic_url,
        qty: req.body.qty,
        product_cat_id: req.body.product_cat_id,
        price: req.body.price,
        expiration_date: req.body.expiration_date, */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
 
    </>
  );
};

export default FshijStokun;
