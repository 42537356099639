import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
} from 'react-router-dom';
import Kycu from './components/Kycu/Kycu';
import MenaxhoProduktet from './components/MenaxhoProduktet/MenaxhoProduktet';
import Dashbordi from './components/Dashbordi/Dashbordi';
import MenaxhoStokun from './components/MenaxhoStokun/MenaxhoStokun';
import ShtoProdukt from './components/MenaxhoProduktet/ShtoProdukt';
import EditoProdukt from './components/MenaxhoProduktet/EditoProdukt';
import FshijProdukt from './components/MenaxhoProduktet/FshijProdukt';
import ShtoStokun from './components/MenaxhoStokun/ShtoStokun';
import EditoStokun from './components/MenaxhoStokun/EditoStokun';
import FshijStokun from './components/MenaxhoStokun/FshijStokun';
import BiznesFaqja from './components/MenaxhimiDyqaneve/BiznesFaqja';
import MenaxhoShfrytezuesit from './components/MenaxhoShfrytezuesit/MenaxhoShfrytezuesit';
import Profili from './components/Profili/Profili';
import MenaxhimiUserave from './components/MenaxhoShfrytezuesit/MenaxhimiUserave';
import MenaxhimiDyqaneve from './components/MenaxhimiDyqaneve/MenaxhimiDyqaneve';
import MenaxhimiQyteteve from './components/MenaxhimiQyteteve/MenaxhimiQyteteve';
import MenaxhimiShteteve from './components/MenaxhimiShteteve/MenaxhimiShteteve';
import ShtoShtetin from './components/MenaxhimiShteteve/Shto';
import EditoShtetin from './components/MenaxhimiShteteve/Edito';
import FshijShtetin from './components/MenaxhimiShteteve/Fshij';
import ShtoQytet from './components/MenaxhimiQyteteve/Shto';
import EditoQytet from './components/MenaxhimiQyteteve/Edito';
import FshijQytet from './components/MenaxhimiQyteteve/Fshij';
import KategoriteProdukteve from './components/KategoriteProdukteve/KategoriteProdukteve';
import FshijKategorineProdukteve from './components/KategoriteProdukteve/Fshij';
import EditoKategorineProdukteve from './components/KategoriteProdukteve/Edito';
import ShtoKategoriTeProdukteve from './components/KategoriteProdukteve/Shto';
import KategoriteBizneseve from './components/KategoriteBizneseve/KategoriteBizneseve';
import ShtoKategoriTeBizneseve from './components/KategoriteBizneseve/Shto';
import EditoKategorineBizneseve from './components/KategoriteBizneseve/Edito';
import FshijKategorineBizneseve from './components/KategoriteBizneseve/Fshij';
import FshijShfrytezues from './components/MenaxhoShfrytezuesit/FshijShfrytzues';
import RegjistroBiznesin from "./components/RegjistroBiznesin/RegjistroBiznesin";
import MenaxhimiAgerange from './components/MenaxhimiAgerange/MenaxhimiAgerange';
import ShtoGrupmosh from './components/MenaxhimiAgerange/Shto';
import EditoGrupmosh from './components/MenaxhimiAgerange/Edito';
import FshijGrupmoshe from './components/MenaxhimiAgerange/Fshij';
import MenaxhimiBexhit from './components/MenaxhimiBexhit/MenaxhimiBexhit';
import ShtoBexhin from './components/MenaxhimiBexhit/Shto';
import EditoBexhin from './components/MenaxhimiBexhit/Edito';
import FshijBexhin from './components/MenaxhimiBexhit/Fshij';
import StatsProdukt from './components/MenaxhoProduktet/StatsProdukt';
import ProdCatStats from './components/KategoriteProdukteve/Stats';
import Auth from './Auth';
import Sidebar from './components/Sidebar/Sidebar';
import './App.scss';
import Logo from './images/newfoodsafe.png';
import ShtoShfrytzues from "./components/MenaxhoShfrytezuesit/ShtoShfrytzues";
import Transactions from "./components/Transactions/Transactions";
import BulkData from "./components/BulkData/BulkData";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import NukKeniQasje from './components/NukKeniQasje';
import jwt_decode from 'jwt-decode';
import Statistics from "./components/Statistics/Statistics";
import StatistikatPerBiznes from "./components/StatistikatPerBiznes/StatistikatPerBiznes";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminEmails from './components/AdminEmails/AdminEmails';
import FshijBiznesin from "./components/MenaxhimiDyqaneve/FshijBiznesin";
import MobileMenu from './components/Sidebar/mobilemenu';

const token = window.localStorage.getItem('cd');
const role = token ? jwt_decode(token).role_id : null;


function App() {
    return (
        <>
            <div className="container-fluid">
                <ToastContainer/>
                <Router>
                    <div className='fjeshil-logo '>
                        <img src={Logo} alt="logo"/>
                    </div>
                    {Auth() ? <MobileMenu/> : null}
                    <div className='row'>
                        <div className='fjeshil-sidebar'>
                            {Auth() ? <Sidebar/> : null}
                        </div>
                        <div className='fjeshil-content bg-light'>
                            {Auth() ? (
                                <Switch>
                                    <Route exact path="/" component={Dashbordi}/>
                                    <Route exact path="/admin-emails" component={AdminEmails}/>
                                    <Route exact path="/menaxhoproduktet" component={MenaxhoProduktet}/>
                                    <Route exact path="/menaxhoshfrytezuesit" component={MenaxhimiUserave}/>
                                    <Route exact path="/menaxhodyqanet" component={MenaxhimiDyqaneve}/>
                                    <Route exact path="/edito-shfrytezuesin/:id" component={MenaxhoShfrytezuesit}/>
                                    <Route exact path="/fshij-shfrytezuesin/:id" component={FshijShfrytezues}/>
                                    <Route exact path="/shtoshfrytzues" component={ShtoShfrytzues}/>
                                    <Route exact path="/bexhi" component={role == 1 ? MenaxhimiBexhit : NukKeniQasje}/>
                                    <Route exact path="/bexhi/shto" component={role == 1 ? ShtoBexhin : NukKeniQasje}/>
                                    <Route exact path="/bexhi/edito/:id"
                                           component={role == 1 ? EditoBexhin : NukKeniQasje}/>
                                    <Route exact path="/bexhi/fshij/:id"
                                           component={role == 1 ? FshijBexhin : NukKeniQasje}/>
                                    <Route exact path="/grupmoshat"
                                           component={role == 1 ? MenaxhimiAgerange : NukKeniQasje}/>
                                    <Route exact path="/grupmoshat/shto"
                                           component={role == 1 ? ShtoGrupmosh : NukKeniQasje}/>
                                    <Route exact path="/grupmoshat/edito/:id"
                                           component={role == 1 ? EditoGrupmosh : NukKeniQasje}/>
                                    <Route exact path="/grupmoshat/fshij/:id"
                                           component={role == 1 ? FshijGrupmoshe : NukKeniQasje}/>
                                    <Route exact path="/qytetet"
                                           component={role == 1 ? MenaxhimiQyteteve : NukKeniQasje}/>
                                    <Route exact path="/qytetet/shto" component={role == 1 ? ShtoQytet : NukKeniQasje}/>
                                    <Route exact path="/qytetet/edito/:id"
                                           component={role == 1 ? EditoQytet : NukKeniQasje}/>
                                    <Route exact path="/qytetet/fshij/:id"
                                           component={role == 1 ? FshijQytet : NukKeniQasje}/>
                                    <Route exact path="/shtetet"
                                           component={role == 1 ? MenaxhimiShteteve : NukKeniQasje}/>
                                    <Route exact path="/shtetet/shto"
                                           component={role == 1 ? ShtoShtetin : NukKeniQasje}/>
                                    <Route exact path="/shtetet/edito/:id"
                                           component={role == 1 ? EditoShtetin : NukKeniQasje}/>
                                    <Route exact path="/shtetet/fshij/:id"
                                           component={role == 1 ? FshijShtetin : NukKeniQasje}/>
                                    <Route exact path="/kategorite/produktet"
                                           component={role == 1 ? KategoriteProdukteve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/produktet/shto"
                                           component={role == 1 ? ShtoKategoriTeProdukteve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/produktet/edito/:id"
                                           component={role == 1 ? EditoKategorineProdukteve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/produktet/fshij/:id"
                                           component={role == 1 ? FshijKategorineProdukteve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/produktet/stats/:id"
                                           component={role == 1 ? ProdCatStats : NukKeniQasje}/>
                                    <Route exact path="/kategorite/bizneset"
                                           component={role == 1 ? KategoriteBizneseve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/bizneset/shto"
                                           component={role == 1 ? ShtoKategoriTeBizneseve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/bizneset/edito/:id"
                                           component={role == 1 ? EditoKategorineBizneseve : NukKeniQasje}/>
                                    <Route exact path="/kategorite/bizneset/fshij/:id"
                                           component={role == 1 ? FshijKategorineBizneseve : NukKeniQasje}/>
                                    <Route exact path="/biznesstoku" component={MenaxhoStokun}/>
                                    <Route exact path="/shtoprodukt" component={ShtoProdukt}/>
                                    <Route exact path="/stats-produkt/:id" component={StatsProdukt}/>
                                    <Route exact path="/shtobiznes" component={RegjistroBiznesin}/>
                                    <Route exact path="/editoprodukt/:id" component={EditoProdukt}/>
                                    <Route exact path="/fshijprodukt/:id" component={FshijProdukt}/>
                                    <Route exact path="/shtostokun" component={ShtoStokun}/>
                                    <Route exact path="/editostokun/:id" component={EditoStokun}/>
                                    <Route exact path="/fshijstokun/:id" component={FshijStokun}/>
                                    <Route exact path="/edito-dyqanin/:id" component={BiznesFaqja}/>
                                    <Route exact path="/bulk-data" component={BulkData}/>
                                    <Route exact path="/transactions" component={Transactions}/>
                                    <Route exact path="/statistics" component={Statistics}/>
                                    <Route exact path="/statistics-per-business" component={StatistikatPerBiznes}/>
                                    <Route exact path="/fshijbiznesin/:id" component={FshijBiznesin}/>
                                    <Route exact path="/profili" component={Profili}/>

                                </Switch>
                            ) : (
                                <>
                                    <Redirect exact to="/kyqu"/>
                                    <Route exact path="/kyqu" component={Kycu}/>
                                    <Route exact path="/forgot-password" component={ForgotPassword}/>

                                </>
                            )}

                        </div>
                    </div>

                </Router>
            </div>
        </>
    );
}

export default App;
