import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi.scss';
import jwt_decode from 'jwt-decode';



const EditoKategorineProdukteve = (props) => {

  const [catProdData, addcatProdData] = useState({});
  const [imgData, addimgData] = useState({});
  const history = useHistory();
  const emri = e => {
    const ud = Object.assign({}, catProdData);
    ud.name = e.target.value;
    ud.active = true;
    addcatProdData(ud);
  }

  const image = e => {

    // e.preventDefault();
    const ud = Object.assign({}, catProdData);
    ud.image = e.target.files[0];
    addcatProdData(ud);
    // addimgData(e.target.files);
    
  }

  const isActive = e => {
    const ud = Object.assign({}, catProdData);
    ud.active = e.target.value;
    addcatProdData(ud);
  }

  const editoKategori = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', catProdData.image);
    formData.append('name', catProdData.name);
    formData.append('active', catProdData.active);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      }
    }

    axios.post('/admin/product-categories/update/' + props.match.params.id , formData, config)
      .then((res) => {
        // window.location = `/kategorite/produktet`;

        console.log(res.data);
        toast.success('Kategoria u shtua me sukses!');
        history.goBack();
      })
      .catch(err => {
        // window.localStorage.removeItem('cd');
        // window.location = "/kyqu";
        // window.localStorage.removeItem('cd'); 
        // window.location = "/login";
        console.log(err);
        toast.error('Diçka shkoi gabim!');
        history.goBack();
      });


  }

  useEffect(() => {

    axios.get('/admin/product-categories/'+ props.match.params.id)
      .then((res) => {
        // console.log('produkti', res.data);
        addcatProdData(res.data);
      })
      .catch(err => {
          console.log(err);
        // window.localStorage.removeItem('cd');
        // window.location = "/kyqu";
        //   window.localStorage.removeItem('cd');
        //   window.location = "/kyqu";
        });

  }, [])



  return (
    <>
        <div className="shto_produkt">

<div className="fjeshil-container">
  <div className="row">

      <h3 className='w-100 p-3'> Edit Kategorine e Produkteve</h3>
      {/* <h5 className='w-100 p-3'>{catProdData.name}</h5> */}
    <div className="col-md-12">
      <div className="shp_groupform">
        <form onSubmit={editoKategori}>

        <img src={process.env.REACT_APP_BACK_URL + catProdData.pic_url} width={'150px'} alt={'kategoria'} />

        <input
            type="file"
            name='file'
            className="form-control"
            placeholder="picture"
            onChange={image}
            required
          />
          <input
            type="text"
            className="form-control"
            placeholder="Emri i Kategorisë"
            defaultValue={catProdData.name}
            onChange={emri}
            required
          />

          <select className="form-control" value={catProdData.active} onChange={isActive} required>
              <option value={null} >Selekto</option>
              <option value={true} >Aktiv</option>
              <option value={false} >Pasiv</option>
          </select>
          
  

          <button type="submit">Edito </button>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
    </>
  );
};

export default EditoKategorineProdukteve;
