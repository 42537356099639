import React, {useState, useEffect, Fragment} from 'react';
import jwt_decode from 'jwt-decode';
import axios from '../../axios';
import './MenaxhoShfrytezuesit.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import silhouette from "../../images/imagesss.jpg";

const token = window.localStorage.getItem('cd');
const roleId = token ? jwt_decode(token).role_id : null;

const MenaxhoShfrytezuesit = (props) => {

    const history = useHistory();

    const [userData, setuserData] = useState([]);
    const [isUserEdit, setisUserEdit] = useState(false);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isPasswordEdit, setisPasswordEdit] = useState(false);
    const [passData, setpassData] = useState([]);
    const [ageRange, setAgeRange] = useState([]);
    const [passValidation, setPasswordValidation] = useState('');
    const [pictureSource, setPictureSource] = useState(process.env.REACT_APP_BACK_URL, userData.pic_url)

    console.log(pictureSource)

    const vleratBaze = () => {
        // e.preventDefault();
        const ud = Object.assign({}, userData);
        // // ud.gender = 'it';
        // ud.agerange = '0-0';
        // setuserData(ud);
    }

    console.log('picture source', pictureSource)


    const fjalkalimi = (e) => {
        const ud = Object.assign({}, passData);
        ud.pass = e.target.value;
        if (ud.pass.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$')) {
            setPasswordValidation('Te Lutem plotëso komfirmim fjalëkalimin')
            setpassData(ud);
        } else {
            setPasswordValidation('Fjalekalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer')
        }
    };

    const fjalkalimiKonfirmimi = (e) => {
        const ud = Object.assign({}, passData);
        ud.pass2 = e.target.value;
        setpassData(ud);
    };


    const emri = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setuserData(ud);
    };

    const mbiemri = (e) => {
        const ud = Object.assign({}, userData);
        ud.surname = e.target.value;
        setuserData(ud);
    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setuserData(ud);
    };

    const telefoni = (e) => {
        const ud = Object.assign({}, userData);
        ud.phone = e.target.value;
        setuserData(ud);
    };

    const setGender = (e) => {
        // console.log(e.target.value)
        const ud = Object.assign({}, userData);
        ud.gender = e.target.value
        setuserData(ud)
    }

    const role = (e) => {
        // console.log('log incopming', e.target.value)
        const ud = Object.assign({}, userData);
        ud.role_id = e.target.value;
        setuserData(ud);
    }

    const active = (e) => {
        console.log('checkboxi', e.target.value)
        const ud = Object.assign({}, userData);
        ud.active = e.target.value;
        setuserData(ud);
    }


    const age = (e) => {
        console.log('age rage id incoming', e.target.value)
        const ud = Object.assign({}, userData);
        ud.agerange = e.target.value;
        setuserData(ud)
    }

    const ndryshoUserin = (e) => {
        e.preventDefault();
        let userEdit = isUserEdit;

        if (userEdit === true) {
            setisUserEdit(false);
        } else {
            setisUserEdit(true);
            vleratBaze();
        };
    }

    console.log('userdaeeeeeeeeeta',userData?.pic_url)

    console.log('pCCCCCCCCC', pictureSource)

    const ruajNdryshimet = (e) => {
        e.preventDefault();
        console.log('para se me shku', userData)
        axios.post("/admin/users/update/" + props.match.params.id, userData)
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data);
                    setisUserEdit(false);
                    getUserData();
                    toast.success('Ndryshimet u ruajtën me sukses!');
                    // history.push('/menaxhoshfrytezuesit');
                }
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
                if (res.status == 500) {
                    console.log(res.data);
                    toast.error('Diçka shkoi gabim!');
                    // history.goBack();
                    setisUserEdit(false);
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const ruajFjalkalimineRi = (e) => {
        e.preventDefault();
        let pass = passData.pass;
        console.log('passData', {pass});
        axios.post("/admin/users/updatepassword/" + props.match.params.id, {pass})
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data);
                    toast.success('Fjalëkalimi u ndryshua me sukses!');
                    // history.push('/menaxhoshfrytezuesit');
                    setisPasswordEdit(false);
                }
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
                if (res.status == 500) {
                    console.log(res.data);
                    toast.error('Diçka shkoi gabim!');
                    // history.goBack();
                    setisPasswordEdit(false);
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const imageDataChange = (e) => {
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        console.log('target files', e.target.files[0])
        setimageData(ud);

    }
    console.log('fajllimrena', imageData)

    const imazhiToggle = (e) => {
        e.preventDefault();
        let imazhiEdit = ndryshoImazhin;
        if (imazhiEdit) {
            setndryshoImazhin(false);
        } else {
            setndryshoImazhin(true);
        }
    }

    const passwordToggle = (e) => {
        e.preventDefault();
        let paswdEdit = isPasswordEdit;
        if (paswdEdit) {
            setisPasswordEdit(false);
        } else {
            setisPasswordEdit(true);
        }
    }

    const ruajImazhin = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', imageData?.image)
        console.log('form data', formData)

        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         'Access-Control-Allow-Origin': '*'
        //     }
        // }
        axios.post("/admin/users/update-image/" + props.match.params.id, formData)
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data);
                    toast.success('Imazhi u ndryshua me sukses!');
                    setndryshoImazhin(false);
                    getUserData();

                }
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
                if (res.status == 500 || res.status == 400) {
                    console.log(res.data);
                    toast.error('Diçka shkoi gabim!');
                    getUserData();
                    setndryshoImazhin(false);
                }

                // console.log('image',formData)
            })
            .catch((err) => {
                console.log(err)
            });
        // getStore();
        // window.location.reload();
    }

    const getUserData = () => {
        axios.get('/admin/users/' + props.match.params.id)
            .then((res) => {
                console.log('userData', res.data);
                setuserData(res.data);
                setPictureSource(process.env.REACT_APP_BACK_URL+res.data.pic_url)
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
            });
    }

    console.log('ZZZZZZZZZZZZZZZZZZZZZZZ', pictureSource)

    useEffect(() => {
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;

        getUserData();
        // vleratBaze();
        axios.get('/admin/agerange/all')
            .then((res) => {
                setAgeRange(res?.data)
            })
            .catch((err) => {
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
                console.log(err)
            })


    }, []);

    // console.log('userdata', userData.role_id);
    // console.log('userData', passData);
// console.log('user edit', isUserEdit);

    return (
        <>
            <div className="shfrytezuesi">
                {/* <div className={'titulli'}>  Menaxhimi i Shfrytezuesit</div> */}
                <div className="fjeshil-container">
                    {ndryshoImazhin &&
                    <div className='upload-imazhi'>
                        <form onSubmit={ruajImazhin}>
                            <input type='file' name='file' onChange={imageDataChange}/>
                            <button type='submit' disabled={imageData ? false : true}>Ruaje imazhin e ri!</button>
                        </form>
                    </div>
                    }
                    <div className="row pl-md-3">
                        <div className="col-md-12">
                            <div className={'row w-100 '}>
                                <div className={'col-md-10 col-12'}>
                                    <div className="shp_groupform">
                                        <button className='ndrysho-imazhin'
                                                onClick={imazhiToggle}>{ndryshoImazhin ? 'Largohu' : 'Ndrysho Imazhin'}</button>
                                        <div className='user-image-container'>
                                            {/* <img className='user-image' src={'http://localhost:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            {/* <img className='user-image' src={'http://10.10.10.206:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            <img className='picture-location'
                                                 src={pictureSource}
                                                    onError={() => {
                                                        setPictureSource(silhouette)
                                                    }}
                                            >
                                            </img>
                                        </div>
                                        {!isPasswordEdit &&
                                        <form onSubmit={ruajNdryshimet} className='shp_form'>
                                            <div className='row'>
                                                <h4 className='pl-3 pb-4 w-100 text-left'> Menaxhimi i
                                                    Shfrytëzuesit</h4>
                                                <label className='col-6'>Emri: </label>
                                                <input className='col-6 form-control' defaultValue={userData.name}
                                                       onChange={emri} disabled={!isUserEdit}/>
                                                <label className='col-6'> Mbiemri:</label>
                                                <input className='col-6 form-control' defaultValue={userData.surname}
                                                       onChange={mbiemri} disabled={!isUserEdit}/>

                                                <label className='col-6 '> Email: </label>
                                                <input type='email' className='col-6 form-control'
                                                       defaultValue={userData.email}
                                                       onChange={email} disabled={!isUserEdit}/>

                                                <label className='col-6'> Telefoni: </label>
                                                <input className='col-6 form-control' defaultValue={userData.phone}
                                                       onChange={telefoni} disabled={!isUserEdit}/>
                                                <label className="col-6 mb-4">Roli</label>
                                                <select
                                                    value={userData.role_id}
                                                    onChange={role}
                                                    disabled={!isUserEdit}
                                                    className="form-control col-6">
                                                    {roleId == 1 && <option value={1}>SuperAdmin</option>}
                                                    {roleId == 1 && <option value={2}>Administrator</option>}
                                                    <option value={3}>Furnitor</option>
                                                    <option value={4}>Mobil</option>
                                                </select>
                                                <label className="col-6 mb-4">Grupmosha</label>
                                                <select disabled={!isUserEdit}
                                                        value={userData.agerange}
                                                        onChange={age}
                                                        className="form-control col-6">
                                                    {
                                                        ageRange?.map((el, i) => {
                                                            // console.log('agEEEEEEEEEEEEEEEEEEEe range comming', el)
                                                            return (
                                                                <option key={el?._id}
                                                                        value={el?._id}>{el?.agerange}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <label className="col-6 mb-4">Gjinia</label>
                                                <select
                                                    value={userData?.gender}
                                                    onChange={setGender}
                                                    className="form-control col-6" disabled={!isUserEdit}>
                                                    <option value="Make">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>


                                                <label className='col-6'> Aktiv:</label>

                                                <select className='col-6 form-control' value={userData.active}
                                                        onChange={active} disabled={!isUserEdit}>
                                                    <option value={null}>Selekto</option>
                                                    <option value={true}>Aktiv</option>
                                                    <option value={false}>Pasiv</option>
                                                </select>

                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <button className='form-control'
                                                            onClick={ndryshoUserin}> {isUserEdit ? 'Largohu' : 'Ndrysho Të Dhënat'} </button>
                                                </div>
                                                <div className='col-6'>
                                                    {isUserEdit &&
                                                    <button type='submit' className='form-control'> Ruaj
                                                        Ndryshimet </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                        }

                                        {isPasswordEdit &&
                                        <form className='shp_form'>
                                            <div className='row w-100'>
                                                <h4 className='pl-3 pb-4 w-100 text-left'> Ndrysho Fjalëkalimin e
                                                    Shfrytëzuesit</h4>

                                                <label className='col-6'> Fjalëkalimi:</label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimi}
                                                       required/>

                                                <label className='col-6 '> Konfirmo fjalëkalimin: </label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       onChange={fjalkalimiKonfirmimi}
                                                       title=""
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       required/>

                                                {/* <label className='col-6'> Telefoni: </label>
                                        <input className='col-6 form-control'  onChange={''} /> */}
                                            </div>
                                        </form>
                                        }
                                        <form className='row'>
                                            <div className='col-6'>
                                                <button className='form-control'
                                                        onClick={passwordToggle}> {isPasswordEdit ? 'Largohu' : 'Ndrysho Fjalkalimin'} </button>
                                            </div>
                                            {isPasswordEdit &&
                                            <div className='col-6'>
                                                <button className='form-control ' onClick={ruajFjalkalimineRi}
                                                        disabled={passData.pass != null && passData?.pass === passData?.pass2 ? false : true}>  {passData.pass != null && passData?.pass === passData?.pass2 ? 'Ruaj Fjalkalimin' : '...nuk perputhen'} </button>
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>

                    <br/>
                    {isPasswordEdit &&
                    <p className='pl-3'> * Nese passwordi juaj ndryshohet me sukses ju ridirektoheni ne formen e
                        kyqjes!</p>}
                    <p className="pl-3">{passValidation}</p>
                </div>
            </div>

        </>
    );

}
export default MenaxhoShfrytezuesit;