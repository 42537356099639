import React, {useState, useEffect, Fragment} from 'react';
import axios from '../../axios';
import './ShtoShfrytzues.scss';
import jwt_decode from 'jwt-decode';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const token = window.localStorage.getItem('cd');
const roleId = token ? jwt_decode(token).role_id : null;

const ShtoShfrytzues = (props) => {

    const history = useHistory();

    const [userData, setuserData] = useState([]);
    const [isUserEdit, setisUserEdit] = useState(false);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isPasswordEdit, setisPasswordEdit] = useState(false);
    const [passData, setpassData] = useState([]);
    const [ageRange, setAgeRange] = useState([]);
    const [passValidation, setPasswordValidation] = useState('');

    const vleratBaze = () => {
        // e.preventDefault();
        const ud = Object.assign({}, userData);
        ud.gender = 'it';
        ud.agerange = '0-0';
        setuserData(ud);
    }


    const fjalkalimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        if (ud.pass.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$')) {
            setPasswordValidation('Te Lutem plotëso komfirmim fjalëkalimin')
            setuserData(ud);
        }
        else {
            setPasswordValidation('Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer')
        }
    };

    const fjalkalimiKonfirmimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass2 = e.target.value;
        setuserData(ud)
    };


    const emri = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setuserData(ud);
    };

    const mbiemri = (e) => {
        const ud = Object.assign({}, userData);
        ud.surname = e.target.value;
        setuserData(ud);
    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setuserData(ud);
    };

    const telefoni = (e) => {
        const ud = Object.assign({}, userData);
        ud.phone = e.target.value;
        setuserData(ud);
    };

    const setGender = (e) => {
        console.log(e.target.value)
        const ud = Object.assign({}, userData);
        ud.gender = e.target.value
        setuserData(ud)
    }

    const role = (e) => {
        console.log('log incopming', e.target.value)
        const ud = Object.assign({}, userData);
        ud.role_id = e.target.value;
        setuserData(ud);
    }
    //
    // const active = (e) => {
    //     console.log('checkboxi', e.target.checked)
    //     const ud = Object.assign({}, userData);
    //     ud.active = e.target.checked;
    //     setuserData(ud);
    // }


    const age = (e) => {
        console.log('age rage id incoming', e.target.value)
        const ud = Object.assign({}, userData);
        ud.agerange = e.target.value;
        setuserData(ud)
    }

    const ndryshoUserin = (e) => {
        e.preventDefault();
        let userEdit = isUserEdit;

        if (userEdit === true) {
            setisUserEdit(false);
        } else {
            setisUserEdit(true);
            vleratBaze();
        }
        ;
    }

    const ruajNdryshimet = async (e) => {
        e.preventDefault();

        // console.log('para se me shku', userData)
        axios.post("/admin/users/add/", userData)
            .then(async  (res)  =>  {
                console.log('resstatus',res.status);
                if (res.status == 200) {
                    console.log(res.data);
                    setisUserEdit(false);
                    // getUserData();
                    history.goBack();
                    toast.success('Shfrytëzuesi u shtua me sukses!');
                } 
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
                // if (res.status == 500 || res.status == 400) {
                //     console.log(res.data);
                //     toast.error('Diçka shkoi gabim!');
                //     // history.goBack();
                //     setisUserEdit(false);

                // }
            await ruajImazhin(e,res.data.user._id);
            
            })
            .catch((err) => {
                console.log('myerr',err)
             
                    // toast.error('Diçka shkoi gabim!');
                    // history.goBack();
                    setisUserEdit(false);

                
            });
    }

    // const ruajFjalkalimineRi = (e) => {
    //     e.preventDefault();
    //     let pass = passData.pass;
    //     console.log('passData', {pass});
    //     axios.post("/admin/users/updatepassword/" + props.match.params.id, {pass})
    //         .then((res) => {
    //             console.log('Passwordi u Ndryshua!');
    //             setisUserEdit(false);
    //             if (res.status === 200) {
    //                 window.localStorage.removeItem('cd');
    //                 window.location = "/kyqu";
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         });


    // }

    const imageDataChange = (e) => {
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        setimageData(ud);
        console.log('fajllimrena', imageData)
    }

    const imazhiToggle = (e) => {
        e.preventDefault();
        let imazhiEdit = ndryshoImazhin;
        if (imazhiEdit) {
            setndryshoImazhin(false);
        } else {
            setndryshoImazhin(true);
        }
    }

    const passwordToggle = (e) => {
        e.preventDefault();
        let paswdEdit = isPasswordEdit;
        if (paswdEdit) {
            setisPasswordEdit(false);
        } else {
            setisPasswordEdit(true);
        }
    }

    const ruajImazhin = async (e, id) => {
        e.preventDefault();

        console.log("" + id)
        const formData = new FormData();
        formData.append('image', imageData.image)
       await axios.post(`/admin/users/update-image/${id}`, formData)
            .then((res) => {
                // if (res.status == 200) {
                //     console.log(res.data);
                //     toast.success('Imazhi u ndryshua me sukses!');
                //     setndryshoImazhin(false);
                //     // getUserData();

                // } 
                // if (res.status == 401) {
                //     toast.error('Authentikimi ju ka skaduar!');
                //     window.localStorage.removeItem('cd');
                //     history.push('/kyqu');
                // }
                // if (res.status == 500 || res.status == 400) {
                //     console.log(res.data);
                //     toast.error('Diçka shkoi gabim me Imazhin!');
                //     // getUserData();
                //     setndryshoImazhin(false);
                // }
            })
            .catch((err) => {
                console.log(err)
            });
        // getStore();
        // window.location.reload();
    }
    //
    const getAgeRange = async () => {
        try {
            let ageRange = await axios.get("/admin/agerange/all");
            console.log("AHJHAJHAHH", ageRange.data)
            setAgeRange(ageRange.data)
        } catch (error) {
            window.localStorage.removeItem('cd');
            window.location = "/kyqu";
            console.log(error)
        }
    }


    useEffect(() => {
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;

        getAgeRange();
    }, []);



    return (
        <>
            <div className="shfrytezuesi">
                {/* <div className={'titulli'}>  Menaxhimi i Shfrytezuesit</div> */}
                <div className="fjeshil-container">
                    {ndryshoImazhin &&
                    <div className='upload-imazhi'>

                            <input type='file' name='file' onChange={imageDataChange}/>
                            {/*<button type='submit' disabled={imageData ? false : true}>Ruaje imazhin e ri!</button>*/}
                    </div>
                    }
                    <div className="row pl-3">
                        <div className="col-md-12">

                            <div className={'row w-100 '}>

                                <div className={'col-10'}>
                                    <div className="shp_groupform">

                                        <button className='ndrysho-imazhin'
                                                onClick={imazhiToggle}>{ndryshoImazhin ? 'Largohu' : 'Ngarko Imazhin'}</button>

                                        <div className='user-image-container'>
                                            {/* <img className='user-image' src={'http://localhost:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            {/* <img className='user-image' src={'http://10.10.10.206:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            <img className='user-image'
                                                 src={process.env.REACT_APP_BACK_URL + userData.pic_url}
                                                 alt='userPicture'></img>
                                        </div>

                                        <form onSubmit={ruajNdryshimet} className='shp_form'>
                                            <div className='row'>

                                                <h4 className='pl-3 pb-4 w-100 text-left'> Shto
                                                    Shfrytëzuesin</h4>
                                                <label className='col-6'>Emri: </label>
                                                <input className='col-6 form-control'
                                                       onChange={emri}/>
                                                <label className='col-6'> Mbiemri:</label>
                                                <input className='col-6 form-control' defaultValue={userData.surname}
                                                       onChange={mbiemri}/>

                                                <label className='col-6 '> Email: </label>
                                                <input className='col-6 form-control' defaultValue={userData.email}
                                                       onChange={email}/>

                                                <label className='col-6'> Telefoni: </label>
                                                <input className='col-6 form-control' defaultValue={userData.phone}
                                                       onChange={telefoni}/>
                                                <label className="col-6 mb-4">Roli</label>
                                                <select
                                                    onChange={role}
                                                    className="form-control col-6">
                                                    <option disabled selected>Selekto Rolin</option>
                                                    {roleId == 1 && <option value={1}>SuperAdmin</option>}
                                                    {roleId == 1 && <option value={2}>Administrator</option>}
                                                    <option value={3}>Furnitor</option>
                                                    <option value={4}>Mobil</option>
                                                </select>
                                                <label className="col-6 mb-4">Grupmosha</label>
                                                <select
                                                    onChange={age}
                                                    className="form-control col-6">
                                                    <option disabled selected>Zgjidhe Grupmoshen</option>
                                                    {
                                                        ageRange?.map((el, i) => {
                                                            // console.log('agEEEEEEEEEEEEEEEEEEEe range comming', el)
                                                            return (
                                                                <option key={el?._id}
                                                                        value={el?._id}>{el?.agerange}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                <label className="col-6 mb-4">Gjinia</label>
                                                <select
                                                    onChange={setGender}
                                                    className="form-control col-6">
                                                    <option disabled selected>Zgjidhe Gjininë</option>
                                                    <option value="Make">Mashkull</option>
                                                    <option value="Female">Femer</option>
                                                    <option value="Other">Tjeter</option>
                                                </select>
                                                {/*<label className='col-6'> Active:</label>*/}
                                                {/*<input className='col-6 form-control'*/}
                                                {/*       type="checkbox"*/}
                                                {/*       // checked={userData?.active ? 'checked' : null}*/}
                                                {/*       // defaultValue={userData.active}*/}
                                                {/*       onChange={active}*/}
                                                {/*    // disabled={!isUserEdit }*/}
                                                {/*/>*/}
                                                {/*Fjalekalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje karakter special.*/}
                                                <label className='col-6'> Fjalëkalimi:</label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimi}
                                                       title="Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer "
                                                       required/>
                                                <label className='col-6 '> Konfirmo fjalëkalimin: </label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       title="Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer"
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimiKonfirmimi}
                                                       required/>
                                            </div>
                                            <p className="text-password">{passValidation}</p>
                                            <div className='row'>
                                                {/*<div className='col-6'>*/}
                                                {/*    <button className='form-control'*/}
                                                {/*            onClick={ndryshoUserin}> {isUserEdit ? 'Largohu' : 'Ndrysho Te Dhenat'} </button>*/}
                                                {/*</div>*/}
                                                <div className='col-6'>

                                                    <button type='submit' className='form-control'> Ruaj
                                                        Ndryshimet
                                                    </button>

                                                </div>
                                            </div>
                                        </form>


                
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    {isPasswordEdit &&
                    <p className='pl-3'> * Nese passwordi juaj ndryshohet me sukses ju ridirektoheni ne formen e
                        kyqjes!</p>}
                </div>
            </div>

        </>
    );

}
export default ShtoShfrytzues;