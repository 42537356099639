import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './ShtoProdukt.scss';
// import jwt_decode from 'jwt-decode';



const FshijProdukt = (props) => {
  const [productData, addproductdata] = useState({});
  const [categoriesData, setCategoriesData] = useState([]);
  const history = useHistory();


  const fshijProduktin = (e) => {
    e.preventDefault();

    axios.delete('/admin/store-products/' + props.match.params.id  , productData)
      .then((res) => {
        toast.success('Produkti u fshij me sukses!');
        history.push(`/menaxhoproduktet`);
        console.log(res.data);
      })
      .catch(err => {
        toast.error('Diçka shkoi gabim!');
        history.goBack();
        // window.localStorage.removeItem('cd')
        // window.location = "/login";
        console.log(err);
      });


  }

  useEffect(() => {

    axios.get('/admin/product-categories/all')
      .then((res) => {
        console.log('axios', res);
        setCategoriesData(res.data);
      })
      .catch( err => { 
        console.log(err); 
        window.localStorage.removeItem('cd'); 
        window.location = "/kyqu"; 
      });


    axios.get('/admin/store-products/'+ props.match.params.id)
      .then((res) => {
        console.log('produkti', res.data);
        addproductdata(res.data);
      })
      .catch(err => { 
          console.log(err); 
          window.localStorage.removeItem('cd'); 
          window.location = "/kyqu"; 
        });

  }, [props.match.params.id])



  return (
    <>
      <div className="shto_produkt">

            <div className="fjeshil-container">
              <div className="row">
  
                  <h3 className='w-100 p-3'>Konfirmo fshirjen e produktit dhe stokut te tij në vijim:</h3>
                  {/* <h5 className='w-100 p-3'>{productData.name}</h5> */}
                <div className="col-md-6">
                  <div className="shp_groupform">
                    <form onSubmit={fshijProduktin}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Emri i Produktit"
                        defaultValue={productData.name}
                        disabled
                      />
                      <label>
                        <select value={productData.product_cat_id} className="form-control" disabled>
                          <option value={null}>Selekto</option>
                          {categoriesData?.map(
                            (el, index) => {
                              return <option key={index} value={el._id}>{el.name}</option>
                            }
                          )}
                        </select>
                      </label>
                      <label>
                        <textarea
                          className="rb_textarea"
                          rows={3}
                          cols={60}
                          placeholder="Përshkrimi..."
                          defaultValue={productData.details}
                          disabled
                        ></textarea>
                      </label>
 
                      <button type="submit">Fshij </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

    </>
  );
};

export default FshijProdukt;
