import React, {useState} from 'react';
import {FaExpand} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {SidebarData} from "./SidebarData"
import SubNav from "./SubNav"
import jwt_decode from 'jwt-decode';
// CSS
import './Sidebar.scss';
import logo from '../../logo_white.png';

const token = window.localStorage.getItem('cd');
const role = token ? jwt_decode(token).role_id : null;

const MobileMenu = () => {

    const [open, setopen] = useState(false);


    const signout = (e) => {
        e.preventDefault();
        window.localStorage.removeItem('cd');
        window.location = "/kycu";
    }

    // console.log('asdf', SidebarData);
    return (
        <>
            <div className="mobile_menu">
                <div className='row text-light'>
                    <div className='col-9'><img src={logo} alt={logo} className='mobile-logo'/></div>
                    <div className='col-2'>
                        <button className='menu-button' onClick={() => setopen(!open)}>
                            <i className={open ? 'fa fa-times' : 'fa fa-bars'}></i>
                        </button>
                    </div>
                </div>
                {open &&
                <div className='mobile-data'>
                    <ul>
                        <li>
                            {SidebarData.map((item, index) => {
                                if (role == 2 && item.title == 'Administrimi') {
                                    return null
                                } else if (item.title != "Administrimi") {
                                    return (
                                        <span onClick={() => setopen(!open)}>
                                            <SubNav item={item} setopen={setopen} open={open} key={index}
                                                    className={item.cName}/>
                                        </span>
                                    )
                                } else {
                                    return (
                                        <span onClick={() => null}>
                                            <SubNav item={item} setopen={setopen} open={open} key={index}
                                                    className={item.cName}/>
                                        </span>
                                    )
                                    // console.log('')
                                }
                            })}
                        </li>
                        {/*</li>*/}
                        <li className={'shkyqu'}>
                            <FaExpand value={{color: "#00000", className: "global-class-name"}}/>
                            <a onClick={signout}>
                            <span>
                            Shkyçu
                             </span>
                            </a>
                        </li>
                    </ul>
                </div>
                }
            </div>
        </>
    );
};

export default MobileMenu;
