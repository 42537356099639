import React, {useState, useEffect} from 'react';

import axios from '../../axios';
import Select from "react-select";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

// CSS
import './ShtoProdukt.scss';
import jwt_decode from 'jwt-decode';


const ShtoProdukt = () => {
    const history = useHistory();

    const [productData, addproductdata] = useState({});
    const [categoriesData, setCategoriesData] = useState([]);
    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;
    const [userStores, setUserStores] = useState([]);


    const vleratBaze = () => {
        const ud = Object.assign({}, productData);
        ud.active = true;
        addproductdata(ud);
    }
    const emri = e => {
        const ud = Object.assign({}, productData);
        ud.name = e.target.value;
        addproductdata(ud);
    }

    const kategoria = e => {
        const ud = Object.assign({}, productData);
        ud.product_cat_id = e.value;
        addproductdata(ud);
    }

    const description = e => {
        const ud = Object.assign({}, productData);
        ud.details = e.target.value;
        addproductdata(ud);
    }
    const image = e => {

        // e.preventDefault();
        const ud = Object.assign({}, productData);
        ud.image = e.target.files[0];
        addproductdata(ud);
    }

    const storeKategori = async (e) => {
        const ud = Object.assign({}, productData);
        ud.store_id = e.target.value;
        await addproductdata(ud);
    }

    const shtoProduktin = async (e) => {
        e.preventDefault();

        // console.log('data', productData);
        // console.log('tikeni', decode);
        // console.log('categories', categoriesData);

        let formDataa = new FormData();

        formDataa.append('name', e.target.name.value);
        formDataa.append('product_cat_id', e.target.product_cat_id.value);
        formDataa.append('store_id', e.target.store_id.value);
        formDataa.append("image", e.target.pic_url.files[0]);
        formDataa.append('details', e.target.description.value);
        // formDataa.append("store_id", store_id);
        formDataa.append("active", true);


         axios.post('/admin/store-products/add', formDataa)
            .then((res) => {
                toast.success('Produkti u shtua me sukses!');
                history.push(`/menaxhoproduktet`);
            })
            .catch(err => {
                toast.error('Diçka shkoi gabim!');
                history.goBack();
                // window.localStorage.removeItem('cd');
                // window.location = "/login";
                console.log(err);
            });
    }


    const getUserStores = async () => {
        try {
            let userStores = await axios.get('/admin/user-store/all');
            console.log(userStores.data);
            setUserStores(userStores?.data)
        } catch (error) {
            // window.localStorage.removeItem('cd');
            // window.location = '/kycu';
            console.log(error)
        }
    }


    useEffect(() => {
        // getCategories();
        axios.get('/admin/product-categories/all')
            .then((res) => {
                console.log('axios', res);
                setCategoriesData(res.data);
            })
            .catch(err => {
                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
        getUserStores();
    }, [])



    console.log('here', productData)


    let categories = categoriesData?.map((el, index) => {
        // return <option key={index} value={el._id}>{el.name}</option>
        return {value: el?._id, label: el?.name}
    })


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container">
                    <div className="row">
                        <h3 className='w-100 p-3'> Shto Produkt</h3>
                        <div className="col-md-12 col-12">
                            <div className="shp_groupform">
                                <form onSubmit={shtoProduktin}>
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        onChange={emri}
                                        required
                                    />
                                    <label>
                                        <Select
                                            name="product_cat_id"
                                            onChange={kategoria}
                                            placeholder="Selekto Kategorinë"
                                            // className="form-control"
                                            // style={{backgroundColor: 'Grey'}}
                                            options={categories}>
                                            {/*<option value={null}>Selekto Produktin</option>*/}
                                            {/*{productData?.map(*/}
                                            {/*  (el, index) => {*/}
                                            {/*    return <option key={index} value={el._id}>{el.name}</option>*/}
                                            {/*  }*/}
                                            {/*)}*/}


                                        </Select>
                                    </label>
                                    <label>
                                        <select
                                            className="form-control"
                                            name="store_id"
                                            onChange={storeKategori}>
                                            <option selected disabled hidden>Selekto Dyqanin</option>
                                            {
                                                userStores?.map((el, i) => {
                                                    return (
                                                        <option value={el?._id} key={el?._id}>{el?.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </label>
                                    <label>
                        <textarea
                            name="description"
                            className="rb_textarea"
                            rows={3}
                            cols={60}
                            placeholder="   Përshkrimi..."
                            onChange={description}
                        ></textarea>
                                    </label>
                                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Cmimi"
                        required
                      /> */}
                                    <input
                                        type="file"
                                        name='pic_url'
                                        className="form-control"
                                        placeholder="picture"
                                        onChange={image}

                                    />
                                    {/* <Link to="/menaxhoproduktet"> */}
                                    <button type="submit">Shto</button>
                                    {/* </Link> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoProdukt;
