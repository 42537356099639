import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Listimi from './Listimi';
import axios from '../../axios';
import jwt_decode from 'jwt-decode';
import ReactPaginate from 'react-paginate';
import Loader from '../Loader/loader';


// CSS
import './Listimi.scss';


const MenaxhimiQyteteve = (props) => {


    const [productsData, setProductsData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    useEffect(() => {
        // getCategories();
        axios.get('/admin/cities/all')
            .then((res) => {
                // console.log('axios', res);
                setFilteredUsers(res?.data)
                setProductsData(res?.data);
                setIsLoading(false);
            })
            .catch(err => {

                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }, []);


    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = productsData?.filter((users) => {
            // console.log('users incoming', users)
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }


    return ( isLoading ? <Loader /> :
        <>
            <div className="biznes_produktet">
                <div className="fjeshil-container">
                    <div className="row">
                        <div className="col-md-12 biznes_title">
                            <h1>Lista e Qyteteve</h1>
                            <Link to="/qytetet/shto">
                                <button className="add-shop">Shto Qytet</button>
                            </Link>
                            <div className="col-6 col-sm-6 md-form mt-5 pb-3">
                                <input onChange={searcher} className="form-control"
                                       type="text" placeholder="Kërko..." aria-label="Search"/>
                                {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                            </div>
                        </div>
                        <div className={'row p-3 w-100'}>
                            {displayUsers = filteredUsers?.slice
                            (pagesVisited, pagesVisited + usersPerPage)?.map
                            (filteredUsers => {
                                return (
                                    <Listimi data={filteredUsers} key={filteredUsers?._id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactPaginate
                            previousLabel={"Mbrapa"}
                            nextLabel={"Para"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenaxhimiQyteteve;
