import React, { useState, useEffect } from 'react';
import axios from '../../axios';

// CSS
import './Listimi.scss';
import jwt_decode from 'jwt-decode';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const FshijKategorineBizneseve = (props) => {

  const [catProdData, addcatProdData] = useState({});
  const [imgData, addimgData] = useState({});
  const history = useHistory();
  const emri = e => {
    const ud = Object.assign({}, catProdData);
    ud.name = e.target.value;
    ud.active = true;
    addcatProdData(ud);
  }

  const image = e => {

    // e.preventDefault();
    const ud = Object.assign({}, catProdData);
    ud.image = e.target.files[0];
    addcatProdData(ud);
    // addimgData(e.target.files);
    
  }

  const isActive = e => {
    const ud = Object.assign({}, catProdData);
    ud.active = e.target.value;
    addcatProdData(ud);
  }

  const fshijKategori = (e) => {
    e.preventDefault();
    axios.delete('/admin/store-categories/' + props.match.params.id)
      .then((res) => {
        toast.success('Kategorija u fshij me sukses!');
        history.push(`/kategorite/bizneset`);
        console.log(res.data);
      })
      .catch(err => {
        toast.error('Diçka shkoi gabim!');
        // window.localStorage.removeItem('cd'); 
        // window.location = "/login";
        console.log(err);
      });


  }

  useEffect(() => {

    axios.get('/admin/store-categories/'+ props.match.params.id)
      .then((res) => {
        // console.log('produkti', res.data);
        addcatProdData(res.data);
      })
      .catch(err => {
          console.log(err);
        toast.error('Diçka shkoi gabim!');
        //   window.localStorage.removeItem('cd');
        //   window.location = "/kyqu";
        });

  }, [])



  return (
    <>
        <div className="shto_produkt">

<div className="fjeshil-container">
  <div className="row">

      <h3 className='w-100 p-3'> Fshij Kategorine e Produkteve</h3>
      {/* <h5 className='w-100 p-3'>{catProdData.name}</h5> */}
    <div className="col-md-12">
      <div className="shp_groupform">
        <form onSubmit={fshijKategori}>

        <img src={process.env.REACT_APP_BACK_URL + catProdData.pic_url} width={'150px'} alt={'kategoria'} />

        {/* <input
            type="file"
            name='file'
            className="form-control"
            placeholder="picture"
            onChange={image}
            disabled
            // required
          /> */}
          <input
            type="text"
            className="form-control"
            placeholder="Emri i Kategorisë"
            defaultValue={catProdData.name}
            onChange={emri}
            disabled
            // required
          />

          <select className="form-control" defaultValue={catProdData.active} onChange={isActive} disabled required>
              {/* <option value={null} >Selekto</option> */}
              <option value={true} >Aktiv</option>
              <option value={false} >Pasiv</option>
          </select>
          
  

          <button type="submit">Fshij </button>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
    </>
  );
};

export default FshijKategorineBizneseve;
