import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';
import Report from '../../images/chart-bar-regular.svg';

// CSS
import './MenaxhoProduktet.scss';

const ListaProdukteve = (props) => {


    console.log(props.data)


    return (
        <>
            <div className={'listimi-produkt'}>
                <div className={'row '}>
                    {/*foto*/}
                    <div className={'col-3'}>

                            <img src={ process.env.REACT_APP_BACK_URL + (props.data.pic_url != null ? props.data.pic_url : props.data.store_prod[0].pic_url)} alt='produkti' className="picture-location"/>

                    </div>
                    {/*content*/}
                    <div className={'col-9'}>
                        <div className={'row'}>
                            {/*name*/}
                            <div className={'col-12'}><h5>{props.data.name}</h5></div>
                            {/*actions*/}
                            <div className={'img-container'}>
                                <div className="iimg-container">
                                <Link to={"/stats-produkt/" + props.data._id}>
                                    <img
                                        alt="delete"
                                        src={Report}
                                        className='actions'/>
                                </Link>
                                <Link to={"/editoprodukt/" + props.data._id}>
                                    <img
                                        alt="delete"
                                        src={EditIcon}
                                        className='actions'/>
                                </Link>
                                <Link to={"/fshijprodukt/" + props.data._id}>
                                    <img
                                        alt="delete"
                                        src={DeleteIcon}
                                        className='actions'/>
                                </Link>
                                </div>
                            </div>
                        </div>
                        <div className={'row category--name '}>Ofruar nga: &nbsp;<b> { props.data.store_data[0].name}</b> </div>
                        <div className={'row category--name '}>{props.data.store_prod[0].name}</div>
                        <div
                            className={props.data.active ? 'row status--name text-success' : 'row status--name text-danger' }>{props.data.active && "Aktiv"} {!props.data.active && 'Pasiv'}</div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default ListaProdukteve;
