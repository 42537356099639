import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';
import silhouette from '../../images/imagesss.jpg'


// CSS
import './Listimi.scss';

const Listimi = (props) => {

    const [pictureSource, setPictureSource] = useState(process.env.REACT_APP_BACK_URL + props.data.pic_url)


    return (
        <>
            <div className={'listimi-user'}>
                <div className={'row '}>
                    {/*foto*/}
                    <div className={'col-3'}>
                        {/*<img src={ process.env.REACT_APP_BACK_URL + props.data.pic_url} alt='#' className="picture-location"/>*/}
                        <img className='picture-location'
                             src={pictureSource}
                             onError={() => {
                                 setPictureSource(silhouette)
                             }}>
                        </img>
                    </div>
                    {/*content*/}
                    <div className={'col-9 pl-4'}>
                        <div className={'row'}>
                            {/*name*/}
                            <div className={'col-10 text-break'}><h5>{props.data.name} {props.data.surname}</h5></div>
                            {/*actions*/}
                            <div className={'img-container'}>
                                <div className="iimg-container">
                                    <Link to={"/edito-shfrytezuesin/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={EditIcon}
                                            className='actions'/>
                                    </Link>
                                    <Link to={"/fshij-shfrytezuesin/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={DeleteIcon}
                                            className='actions'/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={'row category--name text-break'}>{props.data.email}</div>
                        <div
                            className={'row category--name '}> Privilegjet: {props.data.role_id === 1 && 'SuperAdmin'} {props.data.role_id === 2 && 'Admin'} {props.data.role_id === 3 && 'Dyqan'} {props.data.role_id === 4 && 'Mobil'}</div>
                        <div
                            className={props.data.active ? 'row status--name text-success' : 'row status--name text-danger'}>{props.data.active && "Aktiv"} {!props.data.active && 'Pasiv'}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Listimi;
