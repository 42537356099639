import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Listimi from './Listimi';
import axios from '../../axios';
import Loader from '../Loader/loader';
// CSS
import './Listimi.scss';
import ReactPaginate from "react-paginate";
import ListaProdukteve from "../MenaxhoProduktet/ListaProdukteve";
import ListView from '../MenaxhoShfrytezuesit/ListViewComponent/ListView';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jwt_decode from 'jwt-decode';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

const token = window.localStorage.getItem('cd');
const role = token ? jwt_decode(token).role_id : null;

const MenaxhimiUserave = (props) => {

    const history = useHistory();
    const [productsData, setProductsData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');


    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const userPerList = 50;

    const pagesVisited = pageNumber * usersPerPage;
    const pageListVisited = pageNumber * userPerList
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);
    const pageListCount = Math.ceil(filteredUsers.length / userPerList)

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        // getCategories();
        axios.get('/admin/users/all')
            .then((res) => {
                // console.log('axios', res);
                console.log('REEEEEEEEEEEEZS', res)
                setProductsData(res?.data);
                setFilteredUsers(res?.data);
                setIsLoading(false);
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
            })
            .catch(err => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }, []);


    const searcher = async (text) => {
        let searchText = text.target.value.toLowerCase();
        let filteredUser = productsData?.filter((users) => {
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }

    const filterByDropDown = (text) => {
        let searchText = text?.target.value.toLowerCase();
        let toNumber = Number(searchText)
        console.log('nnumber', toNumber)
        let filteredUser = productsData?.filter((filterByStatus) => {
            if (toNumber === 0) {
                return filterByStatus;
            }
            return (filterByStatus?.role_id === toNumber)
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }


    console.log(filteredUsers)

    return (
        isLoading ? <Loader/> :
            <>
                <div className="biznes_produktet">
                    <div className="fjeshil-container">
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="stoku_title">
                                    <h1>Lista e Shfrytëzuesve</h1>
                                    <Link to="/shtoshfrytzues">
                                        <button>Shto Shfrytëzues</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-12 biznes_title">
                                <div className="row">
                                    <div className="col-md-5 col-sm-6 md-form mt-5">
                                        <input onChange={searcher} className="form-control"
                                               type="text" placeholder="Kërko..." aria-label="Search"/>
                                        {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                                    </div>
                                    <div className="col-md-2 mt-3">
                                        <label>Roli:</label>
                                        <select onChange={filterByDropDown} className="form-control">
                                            <option disabled selected>Selekto Rolin</option>
                                            <option value="">Të Gjitha</option>
                                            {role == 1 && <option value={1}>SuperAdmin</option>}
                                            {role == 1 && <option value={2}>Administrator</option>}
                                            <option value={3}>Furnitor</option>
                                            <option value={4}>Mobil</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="button-group">
                                    <button type="button" onClick={() => setComponentToBeRendered('grid')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-large "></i>
                                    </button>
                                    <button type="button" onClick={() => setComponentToBeRendered('list')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-list"></i>
                                    </button>
                                </div>
                            </div>
                            <div className={'row w-100 p-3'}>
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + usersPerPage)?.map
                                        (filteredUsers => {
                                            if (role == 2 && filteredUsers.role_id > 2) {
                                                return (
                                                    <Listimi data={filteredUsers} key={filteredUsers?._id}
                                                    />
                                                )
                                            }
                                            if (role == 1 && filteredUsers.role_id >= 1) {
                                                return (
                                                    <Listimi data={filteredUsers} key={filteredUsers?._id}
                                                    />
                                                )
                                            }
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row w-100 ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>

                                                        <div className={'row p-2 w-100'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th><b>Emri Mbiemri</b></th>
                                                                        <th><b>Statusi</b></th>
                                                                        <th><b>Roli</b></th>
                                                                        <th><b>Email</b></th>
                                                                        <th><b>Email Verifikimi</b>
                                                                        </th>
                                                                        <th><b>Modifiko</b></th>
                                                                        <th><b>Fshij</b></th>
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pageListVisited, pageListVisited + userPerList)?.map
                                                                            (filteredUsers => {
                                                                                if (role == 2 && filteredUsers.role_id > 2) {
                                                                                    return (
                                                                                        <ListView data={filteredUsers}
                                                                                                  key={filteredUsers?._id}
                                                                                        />
                                                                                    )
                                                                                } else if (role == 1 && filteredUsers.role_id >= 1) {
                                                                                    return (
                                                                                        <ListView data={filteredUsers}
                                                                                                  key={filteredUsers?._id}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            })
                                                                            : ''
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData.length >= usersPerPage &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }

                                </div>
                            </div>
                            : ''
                    }
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData.length >= userPerList &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageListCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </>
    );
};

export default MenaxhimiUserave;
