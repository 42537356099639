import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Images
import ListoStokun from './ListoStokun';
import axios from '../../axios';
import jwt_decode from 'jwt-decode';
import Loader from '../Loader/loader';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
//CSS
import './MenaxhoStokun.scss';
import ReactPaginate from "react-paginate";
import Listimi from "../MenaxhoShfrytezuesit/Listimi";
import ListView from "./ListViewComponent/ListView";

const MenaxhoStokun = () => {

    const history = useHistory();
    const [stockData, setStockData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');


    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const userPerList = 50;
    const pagesVisited = pageNumber * usersPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        // getCategories();
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;
        axios.get('/admin/store-stock/all')
            .then((res) => {
                // console.log('axios', res);
                setFilteredUsers(res?.data)
                setStockData(res?.data);

                setIsLoading(false);

            })
            .catch((err) => {
                console.log(err);
                if (err == 'Error: Request failed with status code 401') {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push("/kyqu");
                }
            });
    }, []);


    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = stockData?.filter((users) => {

            return (users?.prodData[0]?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser);
        setPageNumber(0);
    }


    console.log('hehe')

    console.log(filteredUsers)

    return (isLoading ? <Loader/> :
            <>
                <div className="biznes_stoku">
                    <div className="fjeshil-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="stoku_title">
                                    <h1>Menaxhimi i Stokut</h1>
                                    <Link to="/shtostokun">
                                        <button>Shto Stokun</button>
                                    </Link>
                                    <div className="col-12 col-md-6 md-form mt-5 pb-3">
                                        <input onChange={searcher} className="form-control"
                                               type="text" placeholder="Kërko..." aria-label="Search"/>
                                        {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                                    </div>
                                    <div className="button-group">
                                        <button type="button" onClick={() => setComponentToBeRendered('grid')}
                                                className="btn btn-primary grid-button">
                                            <i className="fa fa-th-large "></i>
                                        </button>
                                        <button type="button" onClick={() => setComponentToBeRendered('list')}
                                                className="btn btn-primary grid-button">
                                            <i className="fa fa-th-list"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 stoku">
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + usersPerPage)?.map
                                        (filteredUsers => {
                                            return (
                                                <ListoStokun data={filteredUsers} key={filteredUsers?._id}
                                                />
                                            )
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row w-100 ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2 w-100'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-2"><b>Emri i Produktit</b>
                                                                        </th>
                                                                        <th className="col-2"><b>Shitur nga</b></th>
                                                                        <th className="col-2"><b>Sasia</b></th>
                                                                        <th className="col-2"><b>Çmimi</b></th>
                                                                        <th className="col-2"><b>Data e Skadimit</b>
                                                                        </th>
                                                                        <th className="col-1"><b>Modifiko</b></th>
                                                                        <th className="col-1"><b>Fshij</b></th>
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pagesVisited, pagesVisited + usersPerPage)?.map
                                                                            (filteredUsers => {
                                                                                return (
                                                                                    <ListView data={filteredUsers}
                                                                                              key={filteredUsers?._id}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        stockData.length >= usersPerPage &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }

                                </div>
                            </div>
                            : ''
                    }
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        stockData.length >= userPerList &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </>
    );
};

export default MenaxhoStokun;
