import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const ShtoGrupmosh = () => {

    const [agerangeData, addagerangeData] = useState({});
    const history = useHistory();
    const fillimi = e => {
        console.log(e)
        const ud = Object.assign({}, agerangeData);
        ud.start = e.target.value
        addagerangeData(ud);
    }

    const mbarimi = e => {
        const ud = Object.assign({}, agerangeData);
        ud.end = e.target.value;
        addagerangeData(ud);
    }

    const shtoGrupmoshen = (e) => {
        e.preventDefault();

        axios.post('/admin/agerange/add', agerangeData)
            .then(res => {
                // console.log(res.data);
                // window.location = '/grupmoshat';
                toast.success('Grupmosha u shtua me sukses!');
                history.goBack();
            })
            .catch(err => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                // window.localStorage.removeItem('cd'); window.location = "/login";
                toast.error('Diçka shkoi gabim!');
                history.goBack();
                console.log(err);
            });
        window.history.back();

    }



    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Shto Grupmoshën</h3>
                            <div className="shp_groupform">
                                <form onSubmit={shtoGrupmoshen}>
{/* 
                                        <label>
                                            <Select
                                                onChange={shteti}
                                                placeholder="Selekto Shtetin"
                                                options={shtetii}>
                                            </Select>
                                        </label> */}

                                 
                                    <input type='number' className="form-control" placeholder='Fillimi'  onChange={fillimi} required></input>
                                    <input type='number' className="form-control" placeholder='Mbarimi'  onChange={mbarimi} required></input>
                                    
                                    <button type="submit">Shto</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoGrupmosh;
