import axios from 'axios';

let cd = window.localStorage.getItem('cd');
const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL + '/api/v1/',
  headers: {
    Authorization: 'Bearer ' + cd,
  },
});

export default defaultInstance; //asdf
