import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const EditoGrupmosh = (props) => {

    const [agerangeData, addagerangeData] = useState({});
    const history = useHistory();
    const fillimi = e => {
        console.log(e)
        const ud = Object.assign({}, agerangeData);
        ud.start = e.target.value
        addagerangeData(ud);
    }

    const mbarimi = e => {
        const ud = Object.assign({}, agerangeData);
        ud.end = e.target.value;
        addagerangeData(ud);
    }

    const editoGrupmoshen = (e) => {
        e.preventDefault();

        axios.post('/admin/agerange/update/'+ props.match.params.id, agerangeData)
            .then(res => {
                // console.log(res.data);
                // window.location = '/grupmoshat';
                toast.success('Grupmosha u editua me sukses!');
                history.goBack();
            })
            .catch(err => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
                toast.error('Diçka shkoi gabim');
                history.goBack();
            });
        // window.history.back();

    }

    useEffect(() => {
        axios.get('/admin/agerange/'+ props.match.params.id)
        .then(res => {
            addagerangeData(res.data);
        })
        .catch(err => {
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            // window.localStorage.removeItem('cd'); window.location = "/login";
            console.log(err);
        });
 
    }, [])


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Edito Grupmoshen</h3>
                            <div className="shp_groupform">
                                <form onSubmit={editoGrupmoshen}>
                                 
                                    <input type='number' className="form-control" placeholder='Fillimi' onChange={fillimi} defaultValue={agerangeData.start} required></input>
                                    <input type='number' className="form-control" placeholder='Mbarimi' onChange={mbarimi} defaultValue={agerangeData.end} required></input>
                                    
                                    <button type="submit">Edito</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditoGrupmosh;
