import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi.scss';
import jwt_decode from 'jwt-decode';


const FshijShtetin = (props) => {
        const history = useHistory();
        const [stateData, addstateData] = useState({});

        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;


        const emri = e => {
            const ud = Object.assign({}, stateData);
            ud.name = e.target.value;
            addstateData(ud);
        }

        const kodi = e => {
            const ud = Object.assign({}, stateData);
            ud.code = e.target.value;
            addstateData(ud);
        }

        const telefoni = e => {
            const ud = Object.assign({}, stateData);
            ud.phone_code = e.target.value;
            addstateData(ud);
        }

        const fshijShtetinn = (e) => {
            e.preventDefault();
            axios.delete('/admin/countries/' + props.match.params.id, stateData)
                .then((res) => {
                    // window.location = `/shtetet`;
                    toast.success('Shteti u fshij me sukses!');
                    history.goBack();
                    console.log(res.data);
                })
                .catch(err => {
                       toast.error('Diçka shkoi gabim');
                       history.goBack();
                        // window.localStorage.removeItem('cd');
                        // window.location = "/login";
                        console.log(err);
                    }
                );


        }

        useEffect(() => {

            axios.get('/admin/countries/' + props.match.params.id)
                .then((res) => {
                    // console.log('produkti', res.data);
                    addstateData(res.data);
                })
                .catch(err => {
                    console.log(err);
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                    //   window.localStorage.removeItem('cd');
                    //   window.location = "/kyqu";
                });

        }, [])


        return (
            <>
                <div className="shto_produkt">

                    <div className="fjeshil-container">
                        <div className="row">

                            <h3 className='w-100 p-3'> Fshij Shtetin</h3>
                            <p className='p-3'> Konfirmo Fshirjen e Shtetit me parametrat e meposhtem.</p>
                            {/* <h5 className='w-100 p-3'>{stateData.name}</h5> */}
                            <div className="col-md-12">

                                <div className="shp_groupform">
                                    <form onSubmit={fshijShtetinn}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Emri i Shtetit"
                                            defaultValue={stateData.name}
                                            onChange={emri}
                                            disabled={true}
                                            required
                                        />
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Kodi"
                                            defaultValue={stateData.code}
                                            onChange={kodi}
                                            required
                                            disabled={true}
                                        />
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Prefixi Telefonik"
                                            defaultValue={stateData.phone_code}
                                            onChange={telefoni}
                                            disabled={true}
                                        />

                                        <p>* Pasojat e fshirjes se shtetit ndikojn tek Ssfrytëzuesit mobil dhe
                                            furnitoret.</p>

                                        <button type="submit">Fshij</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
;

export default FshijShtetin;
