import React, {useState, useEffect, Fragment} from 'react';
import jwt_decode from 'jwt-decode';
import axios from '../../axios';
import './Profili.scss';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
const Profili = () => {
    const history = useHistory();


    const token = window.localStorage.getItem("cd");
    const decode = token ? jwt_decode(token) : null;

    const [userData, setuserData] = useState([]);
    const [isUserEdit, setisUserEdit] = useState(false);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isPasswordEdit, setisPasswordEdit] = useState(false);
    const [passData, setpassData] = useState([]);
    const [passValidation, setPasswordValidation] = useState('');
    const [imageResMsg, setImageResMsg] = useState('');


    const vleratBaze = () => {
        // e.preventDefault();
        const ud = Object.assign({}, userData);
        ud.gender = 'it';
        ud.agerange = '0-0';
        setuserData(ud);
    }


    const fjalkalimi = (e) => {
        const ud = Object.assign({}, passData);
        ud.pass = e.target.value;
        if (ud.pass.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$')) {
            setPasswordValidation('Te Lutem plotëso komfirmim fjalëkalimin')
            setpassData(ud);
        } else {
            setPasswordValidation('Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer')
        }
    };

    const fjalkalimiKonfirmimi = (e) => {
        const ud = Object.assign({}, passData);
        ud.pass2 = e.target.value;
        setpassData(ud);
    };


    const emri = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setuserData(ud);
    };

    const mbiemri = (e) => {
        const ud = Object.assign({}, userData);
        ud.surname = e.target.value;
        setuserData(ud);
    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setuserData(ud);
    };

    const telefoni = (e) => {
        const ud = Object.assign({}, userData);
        ud.phone = e.target.value;
        setuserData(ud);
    };


    const ndryshoUserin = (e) => {
        e.preventDefault();
        let userEdit = isUserEdit;

        if (userEdit === true) {
            setisUserEdit(false);
        } else {
            setisUserEdit(true);
            vleratBaze();
        }
        ;
    }

    const ruajNdryshimet = (e) => {
        e.preventDefault();
        console.log('para se me shku', userData)
        axios.post("/admin/users/update/" + decode.id, userData)
            .then((res) => {
                console.log(res.data);
                setisUserEdit(false);
                getUserData();
                // setnotifyMsg('Ndryshimet u ruajtën me sukses!');
                toast.success('Ndryshimet u ruajtën me sukses!');
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
            })
            .catch((err) => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                console.log(err)
            });

    }

    const ruajFjalkalimineRi = (e) => {
        e.preventDefault();
        let pass = passData.pass;
        console.log('passData', {pass});
        axios.post("/admin/users/updatepassword/" + decode.id, {pass})
            .then((res) => {
                console.log('Passwordi u Ndryshua!');
                setisUserEdit(false);
                // if (res.status === 200) {
                //     window.localStorage.removeItem('cd');
                //     window.location = "/kyqu";
                // }
                toast.success('Fjalëkalimi u ndrua me sukses!');
                setisPasswordEdit(false);
                // setPasswordValidation('');
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const imageDataChange = (e) => {
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        setimageData(ud);
        console.log('fajllimrena', imageData)
    }

    const imazhiToggle = (e) => {
        e.preventDefault();
        let imazhiEdit = ndryshoImazhin;
        if (imazhiEdit) {
            setndryshoImazhin(false);
        } else {
            setndryshoImazhin(true);
        }
    }

    const passwordToggle = (e) => {
        e.preventDefault();
        let paswdEdit = isPasswordEdit;
        if (paswdEdit) {
            setisPasswordEdit(false);
        } else {
            setisPasswordEdit(true);
        }
    }


    const ruajImazhin = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', imageData.image)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            }
        }
        axios.post("/admin/users/update-image/" + decode.id, formData, config)
            .then((res) => {
                getUserData();
                console.log(res?.data);
                // console.log('image',formData).3
                // setImageResMsg(res?.data)
                // setTimeout(() => {
                //     setImageResMsg('')
                // }, 4000)
                toast.success('Imazh u ndrua me sukses!');
                setndryshoImazhin(false);
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }


    const getUserData = async () => {
        try {
            const token = window.localStorage.getItem('cd');
            const decode = token ? jwt_decode(token) : null;

            axios.get('/admin/users/' + decode.id)
                .then((res) => {
                    console.log('userData', res.data);
                    setuserData(res.data);
                    if (res.status == 401) {
                        toast.error('Authentikimi ju ka skaduar!');
                        window.localStorage.removeItem('cd');
                        history.push('/kyqu');
                    }
                })
                .catch(err => {
                    console.log(err);
                   toast.error('Diçka shkoi gabim!');
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                });
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getUserData();
        // vleratBaze();

    }, []);


    console.log('user dataaaaaaaaaaaaaaaaa', userData.pic_url);

// console.log('userdata',userData);
    console.log('userData', passData);
// console.log('user edit', isUserEdit);

    return (
        <>
            <div className="profili">
                {/* <div className={'titulli'}>  Menaxhimi i Shfrytezuesit</div> */}
                {/* {notifyMsg != '' && renderMsg(notifyMsg)} */}
                <div className="fjeshil-container">
                    {ndryshoImazhin &&
                    <div className='upload-imazhi'>
                        <form onSubmit={ruajImazhin}>
                            <input type='file' name='file' onChange={imageDataChange}/>
                            <button type='submit' disabled={imageData ? false : true}>Ruaje imazhin e ri!</button>
                        </form>
                        <p className="text-left">{imageResMsg}</p>
                    </div>
                    }
                    <div className="row pl-3">
                        <div className="col-md-12 col-12">
                            <div className={'row w-100 '}>
                                <div className={'col-md-10 col-12'}>
                                    <div className="shp_groupform">
                                        <button className='ndrysho-imazhin'
                                                onClick={imazhiToggle}>{ndryshoImazhin ? 'Largohu' : 'Ndrysho Imazhin'}</button>
                                        <div className='user-image-container'>
                                            {/* <img className='user-image' src={'http://localhost:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            {/* <img className='user-image' src={'http://10.10.10.206:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            <img className='user-image'
                                                 src={process.env.REACT_APP_BACK_URL + userData.pic_url}
                                                 alt='userPicture'></img>
                                        </div>
                                        {!isPasswordEdit &&
                                        <form onSubmit={ruajNdryshimet} className='shp_form'>
                                            <div className='row'>

                                                <h4 className='pl-3 pb-4 w-100 text-left'> Menaxhimi i
                                                    Shfrytëzuesin</h4>
                                                <label className='col-6'>Emri: </label>
                                                <input className='col-6 form-control' defaultValue={userData.name}
                                                       onChange={emri} disabled={!isUserEdit}/>

                                                <label className='col-6'> Mbiemri:</label>
                                                <input className='col-6 form-control' defaultValue={userData.surname}
                                                       onChange={mbiemri} disabled={!isUserEdit}/>

                                                <label className='col-6 '> Email: </label>
                                                <input className='col-6 form-control' defaultValue={userData.email}
                                                       onChange={email} disabled={!isUserEdit}/>

                                                <label className='col-6'> Telefoni: </label>
                                                <input className='col-6 form-control' defaultValue={userData.phone}
                                                       onChange={telefoni} disabled={!isUserEdit}/>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <button className='form-control'
                                                            onClick={ndryshoUserin}> {isUserEdit ? 'Largohu' : 'Ndrysho Të Dhënat'} </button>
                                                </div>
                                                <div className='col-6'>
                                                    {isUserEdit &&
                                                    <button type='submit' className='form-control'> Ruaj
                                                        Ndryshimet </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                        }

                                        {isPasswordEdit &&
                                        <form className='shp_form'>
                                            <div className='row w-100'>
                                                <h4 className='pl-3 pb-4 w-100 text-left'> Ndrysho Fjalëkalimin e
                                                    Shfrytëzuesit</h4>

                                                <label className='col-6'>
                                                    Fjalëkalimi:</label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimi}
                                                       required/>

                                                <label className='col-6 '> Konfirmo fjalëkalimin: </label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimiKonfirmimi}
                                                       required/>

                                                {/* <label className='col-6'> Telefoni: </label>
                                        <input className='col-6 form-control'  onChange={''} /> */}
                                            </div>

                                        </form>
                                        }


                                        <form className='row'>
                                            <div className='col-6'>
                                                <button className='form-control'
                                                        onClick={passwordToggle}> {isPasswordEdit ? 'Largohu' : 'Ndrysho Fjalkalimin'} </button>
                                            </div>
                                            {isPasswordEdit &&
                                            <div className='col-6'>
                                                <button className='form-control ' onClick={ruajFjalkalimineRi}
                                                        disabled={passData.pass != null && passData?.pass === passData?.pass2 ? false : true}>  {passData.pass != null && passData?.pass === passData?.pass2 ? 'Ruaj Fjalkalimin' : '...nuk perputhen'} </button>
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <br/>
                    {isPasswordEdit &&
                    <p className='pl-3'> * Nese passwordi juaj ndryshohet me sukses ju ridirektoheni ne formen e
                        kyqjes!</p>}
                    <p className="pl-3"> * {passValidation}</p>
                </div>
            </div>

        </>
    );

}
export default Profili;