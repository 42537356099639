import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';


// CSS
import './Listimi.scss';

const Listimi = (props) => {


    // console.log(props.data)


    return (
        <>
            <div className={'listimi-qytetet'}>
                <div className={'row '}>
                   {/* <div className={'col-3'}> {props.data.country_data[0]?.name}</div> */}
                   <div className={'col-9'}> {props.data.agerange}</div>
                   <div className={'col-3 justify-content-end'}> 
                    <Link to={'/grupmoshat/edito/'+ props.data._id}>
                        <img src={EditIcon} alt='edit' />
                    </Link>
                    <Link to={'/grupmoshat/fshij/'+ props.data._id}>
                        <img src={DeleteIcon} alt='delete' className='ml-3'/>
                    </Link>
                   </div>
                </div>
            </div>
          
        </>
    );
};

export default Listimi;
