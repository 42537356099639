import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ListaProdukteve from './ListaProdukteve';
import axios from '../../axios';
import jwt_decode from 'jwt-decode';
import Loader from '../Loader/loader';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

// CSS
import './MenaxhoProduktet.scss';
import Listimi from "../MenaxhimiQyteteve/Listimi";
import ReactPaginate from "react-paginate";
import ListView from "./ListViewComponent/ListView";


const MenaxhoProduktet = (props) => {
    const history = useHistory();

    const [productsData, setProductsData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');


    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const userPerList = 50;
    const pagesVisited = pageNumber * usersPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);
    const pageListCount = Math.ceil(filteredUsers.length / userPerList)
    const [notifyMsg, setnotifyMsg] = useState('');
    const [notifyMsgStatus, setnotifyMsgStatus] = useState(true);

    const renderMsg = (msg, good) => {
        if (good) {
            return (
                <div id='notifyMsg' className='notify-msg'>{msg}
                    <button onClick={handleMsgClose}><i className='fa fa-close'></i></button>
                </div>
            );
        }
        if (good == false) {
            return (
                <div id='notifyMsg' className='notify-msg-bad'>{msg}
                    <button onClick={handleMsgClose}><i className='fa fa-close'></i></button>
                </div>
            );
        }
    }
    const handleMsgClose = (e) => {
        e.preventDefault();
        setnotifyMsg('');

    }

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        // getCategories();
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;
        axios.get('/admin/store-products/all')
            .then((res) => {
                // console.log('axios', res);
                setProductsData(res?.data);
                setFilteredUsers(res?.data);
                setIsLoading(false);
            })
            .catch((err) => {


                console.log(err);
                if (err == "Error: Request failed with status code 401") {
                    toast.error('Authentikimi ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                } else {
                    toast.error('Diçka shkoi gabim!');
                }
                // window.location = "/kyqu";

            })
    }, []);

    const searcher = async (text) => {
        let searchText = text.target.value.toLowerCase();
        let filteredUser = productsData?.filter((users) => {
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser);
        setPageNumber(0);
    }


    return (isLoading ? <Loader/> :
            <>
                <div className="biznes_produktet">
                    {notifyMsg != '' && renderMsg(notifyMsg, notifyMsgStatus)}
                    <div className="fjeshil-container">
                        <div className="row">
                            <div className="col-md-12 biznes_title">
                                <h1>Menaxhimi i Produkteve</h1>
                                <Link to="/shtoprodukt">
                                    <button className="add-button">Shto Produkt</button>
                                </Link>
                                <div className="col-md-6 col-12 md-form mt-5 pb-3">
                                    <input onChange={searcher} className="form-control"
                                           type="text" placeholder="Kërko..." aria-label="Search"/>
                                    {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                                </div>
                                <div className="button-group">
                                    <button type="button"
                                            onClick={() => setComponentToBeRendered('grid')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-large "></i>
                                    </button>
                                    <button type="button"
                                            onClick={() => setComponentToBeRendered('list')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-list"></i>
                                    </button>
                                </div>
                            </div>
                            <div className={'row p-3 w-100'}>
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + usersPerPage)?.map
                                        (filteredUsers => {
                                            return (
                                                <ListaProdukteve data={filteredUsers} key={filteredUsers?._id}
                                                />
                                            )
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row w-100 ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2 w-100'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-3"><b>Emri i produktit</b>
                                                                        </th>
                                                                        <th className="col-2"><b>Kategoria</b></th>
                                                                        <th className="col-2"><b>Statusi</b></th>
                                                                        <th className="col-2"><b>Imazhi</b></th>
                                                                        <th className="col-1"><b>Statistikat</b></th>
                                                                        <th className="col-1"><b>Modifiko</b></th>
                                                                        <th className="col-1"><b>Fshij</b></th>
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pagesVisited, pagesVisited + userPerList)?.map
                                                                            (filteredUsers => {
                                                                                return (
                                                                                    <ListView data={filteredUsers} key={filteredUsers?._id}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData.length >= usersPerPage &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }
                                </div>
                            </div>
                            : ''
                    }
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData.length >= userPerList &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageListCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </>
    );
};

export default MenaxhoProduktet;
