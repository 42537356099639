import './Transactions.scss';
import React, {useEffect, useState} from "react";
import moment from 'moment'
import jwt_decode from "jwt-decode";
import axios from '../../axios';
import ReactPaginate from "react-paginate";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ListimiTransakcionet from './Listimi';

const Transactions = () => {

    let date = new Date();

    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [customDateInputValidation, setCustomDateInputValidation] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [transactionEmptyMsg, setTransactionEmptyMsg] = useState('');
    const [searchText, setSearchText] = useState('')
    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 25;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredData.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;


    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = transactions?.filter((users) => {
            console.log('users incoming', users)
            return (users?.user_name.toLowerCase().includes(searchText))
        })
        setFilteredData(filteredUser)
        setPageNumber(0)
    }


    // const filterByDropDown = (text) => {
    //
    // }
    //
    // console.log('metoda',searchText)

// transaction id, qty, price, status, export exl

    // let searchText = ''
    // let searchText = ''
    const submitDate = async (event) => {
        event.preventDefault();


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        let mergedDate = objectDate.firstDayOfMonth + "/" + objectDate.customDayOfMonth


        let data;
        try {
            data = await axios.get(`/admin/store-transactions/filterbydate/${mergedDate}`)
            console.log(data.data);
            // setFilteredData(data?.data);
            setTransactions(data?.data)
            setSearchText(event?.target.filteredThing.value.toLowerCase())
            console.log('mjau', searchText)
            // let filteredUser = transactions?.filter((filterByStatus) => {
            //     return (filterByStatus?.status.toLowerCase().includes(searchText))
            // })
            // setFilteredData(filteredUser)

            if (searchText) {
                setCustomDateInputValidation('Selekto Statusin')
            } else {
                setCustomDateInputValidation('')
            }
            // setPageNumber(0);
        } catch (error) {
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            console.log(error);
        }
    }


    useEffect(() => {
        console.log('mjau', searchText)
        let filteredUser = transactions?.filter((filterByStatus) => {
            return (filterByStatus?.status.toLowerCase().includes(searchText))
        })
        setFilteredData(filteredUser)
        setPageNumber(0);
    }, [transactions, searchText])


    // console.log('transaction error', transactionEmptyMsg);

    // console.log('heheheh', customDateInputValidation)


    // api/v12021-06-19/2021-06-26/60995ba7a0a354ba7ea6e812


    let formatedDate = moment(firstDayOfMonth).format();
    let firstDayOfTheMonth = formatedDate.toString().slice(0, 10);
    let today = moment(new Date()).format().toString().slice(0, 10);

    console.log(today)

    return (
        <>
            <div className="Transactions">
                <div className="row Transactions ">
                    <div className="col-md-12">
                        <h1>Menaxhimi i Transaksioneve</h1>
                    </div>
                </div>
                <div className="row Transactions">
                    <div className="col-md-12">
                        <div className="row mb-4">
                            <div className="col-md-3">
                                <input className="form-control"
                                       type="text"
                                       placeholder="Kërko..."
                                       aria-label="Search"
                                       onChange={searcher}
                                />
                            </div>
                        </div>
                        <form onSubmit={submitDate}>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <label for="first-date">Data e parë: </label>
                                    <input type='date'
                                           className="form-control first-date"
                                           name="firstDateOfMonth"
                                           defaultValue={firstDayOfTheMonth}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label for="last-date">Data e fundit: </label>
                                    <input className="form-control last-date"
                                           name="customDayOfMonth"
                                           defaultValue={today}
                                           type="date" placeholder="Kërko..."
                                           aria-label="customday"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label>Statusi:</label>
                                    <select
                                        id="filteredThing"
                                        name="filteredThing"
                                        className="form-control filter-dropdown">
                                        <option selected disabled>Selekto Statusin</option>
                                        <option value="">Të Gjitha</option>
                                        <option value="approved">Të Aprovuara</option>
                                        <option value="expired">Të Skaduara</option>
                                        <option value="declined">Të Anuluara</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label>Kërko: </label>
                                    <button type="submit" value="Search by Date"
                                            className='btn btn-success search-button'>
                                        Kërko me date
                                    </button>
                                </div>
                                <div className="col-md-2">
                                    <label>Shkarko si XLS: </label>
                                    <ReactHTMLTableToExcel
                                        id="tabela-transakcioneve"
                                        className="btn btn-success search-button"
                                        table="table-to-xls"
                                        filename="Transaksionet"
                                        sheet="Transaksionet"
                                        buttonText="Shkarko si XLS"
                                    />
                                </div>
                            </div>
                        </form>
                        <span className="validation-text">{customDateInputValidation}</span>
                        <span className="validation-text">{transactionEmptyMsg}</span>
                        <div className="Transactions">
                            <div className='row w-100 ml-1'>
                                <div className={'listimi-transactions sticky-top'}>

                                    <div className={'row p-2 w-100'}>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th className="col-3"><b>Klienti</b></th>
                                                    <th className="col-2"><b>Produkti</b></th>
                                                    <th className="col-1"><b>Sasia</b></th>
                                                    <th className="col-2"><b>Shuma</b></th>
                                                    <th className="col-1"><b>Metoda e Pagesës</b></th>
                                                    <th className="col-2"><b>Emri i Biznesit</b></th>
                                                    <th className="col-1"><b>Statusi</b></th>
                                                </tr>
                                                </thead>

                                                {
                                                    displayUsers = filteredData?.slice
                                                    (pagesVisited, pagesVisited + productsPerPage)?.map
                                                    ((el, i) => {
                                                        return (
                                                            <ListimiTransakcionet data={el}/>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fjeshil-table table-responsive">
                            <table id="table-to-xls" className="table mb-5">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col">Emri</th>
                                    <th scope="col">Mbiemri</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Emri i Dyqanit</th>
                                    <th scope="col">Sasia</th>
                                    <th scope="col">Çmimi</th>
                                    <th scope="col">Produkti</th>
                                    <th scope="col">Njesia Matese</th>
                                    <th scope="col">Statusi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    transactions?.map
                                    ((el, i) => {
                                        return (
                                            <tr key={el?._id}>
                                                <th scope="row">{i + 1}</th>
                                                <td>{el?._id}</td>
                                                <td>{el?.user_name}</td>
                                                <td>{el?.user_surname}</td>
                                                <td>{el?.user_email}</td>
                                                <td>{el?.store_name}</td>
                                                <td>{el?.qty}</td>
                                                <td>{el?.total_price}</td>
                                                <td>{el?.product_name}</td>
                                                <td>{el?.messurment_name}</td>
                                                <td>{el?.status}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {/*<span className="validation-text">{customDateInputValidation}</span>*/}
                    </div>
                </div>
                {
                    filteredData?.length > 8 ?
                        <div className="row pagination">
                            <div className="col-md-12">
                                <ReactPaginate
                                    previousLabel={"Mbrapa"}
                                    nextLabel={"Para"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                />
                            </div>
                        </div>
                        :
                        ''
                }
            </div>
        </>
    )
}

export default Transactions;