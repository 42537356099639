import './ListView.scss'
import {Link} from "react-router-dom";
// import DeleteIcon from "../../../images/delete.svg";
// import EditIcon from "../../../images/edit.svg";
// import Reports from '../../../images/chart-bar-regular.svg';
import React from "react";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/delete.svg";
import Report from '../../../images/chart-bar-regular.svg'

const ListView = (props) => {

    console.log('', props)

    return (
        <>

            <tbody>
            <tr>
                <td className="col-2">{props.data?.prodData[0]?.name} </td>
                <td className="col-2"> {props.data.storeData.name}</td>
                <td className="col-2">{props.data.qty}</td>
                <td className="col-2"> {props.data.price} EUR</td>
                <td className="col-2"> {props.data.expiration_date.slice(0, 10)}</td>
                <td className="col-1">
                    {
                        <Link to={"/editostokun/" + props.data._id}>
                            <img
                                alt="delete"
                                src={EditIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                <td className="col-1">
                    {
                        <Link to={"/fshijstokun/" + props.data._id}>
                            <img
                                alt="delete"
                                src={DeleteIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                {/*<div className="col-1">*/}
                {/*    {*/}
                {/*        <Link to={"/singleproduct/" + props.data._id}>*/}
                {/*            <img*/}
                {/*                alt="delete"*/}
                {/*                src={Report}*/}
                {/*                className='actions'*/}
                {/*            />*/}
                {/*        </Link>*/}
                {/*    }*/}
                {/*</div>*/}
            </tr>
            </tbody>
        </>
    )
}

export default ListView;