import './Statistics.scss';
import React, {useEffect, useState} from "react";
import axios from "../../axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import {getAllByDisplayValue} from "@testing-library/react";
import {Bar} from "react-chartjs-2";
import Loader from "../Loader/loader";
import Select from "react-select";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Statistics = () => {

    let date = new Date();

    const [customDateInputValidation, setCustomDateInputValidation] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const [storeValidation, setStoreValidation] = useState('');
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;
    const [userStores, setUserStores] = useState([]);
    const [donation, setDonation] = useState([])
    const [categoryStatsTrans, setCategoryStatsTrans] = useState([])
    const [statisticsPerAge, setStatisticsPerAge] = useState([]);
    const [statisticsPerFurnitor, setStatisticsPerFurnitor] = useState([]);
    const [prodCategoryTransactions, setProdCategoryTransactions] = useState([]);
    const [storeCategoryTransactions, setStoreCategoryTransactions] = useState([]);
    const [measurement, setMeasurement] = useState([])
    const [donationProducts, setDonationProducts] = useState([])
    // const [allTransactions, setAllTransactions] = useState([]);
    // const [completedTransactions, setCompletedTransactions] = useState([]);
    // const [topTenTransactions, setTopTenTransactions] = useState([]);
    // const [perProductTransactions, setPerProductTransactions] = useState([]);
    // const [perCategoryTransactions, setPerCategoryTransactions] = useState([]);
    // const [stockPerProductTransactions, setStockPerProductTransactions] = useState([])
    const [users, setAllUsers] = useState([])
    const [transactionsPerCity, setTransactionsPerCity] = useState([])
    const [isLoading, setIsLoading] = useState(null);

    console.log('decode', decode)

    const callAllFunctions = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true)
            await getAllTransactions(event);
            await getTransactionsPerProductCategory(event);
            await getCategoriesPerStore(event);
            await getStatisticsPerAge(event);
            await getMeasurementTransactions(event)
            await getDonationTotal(event)
            await getAllUsers(event)
            await getDonationPerProduct(event)
            await getTransactionsPerCity(event)
            setTimeout(() => {
                setIsLoading(false)
            }, 3000)
        } catch (error) {
            console.log(error)
        }
    }

    let storeId;
    const storeSelection = (event) => {
        console.log('id-ja', event.value);
        // console.log('opcioni',event.target.options[event.target.selectedIndex].text);
        storeId = event.value;
        // this.setState({storeName: storeName});
        if (storeId === undefined || storeId === null || storeId === '') {
            setStoreValidation('Please select a store');
            return false;
        }
        // console.log('store id id id id', storeId)
    }


    const getUserStores = async () => {
        try {
            let userStores = await axios.get('/admin/user-store/all').then(res => {
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                }
            });
            console.log(userStores.data);
            setUserStores(userStores?.data)
        } catch (error) {

            toast.warn("Ju lutemi selektoni dy inputet e datës nga/deri!");
            console.log(error)
        }
    }

    useEffect(() => {
        getUserStores();
    }, [])


    let formatedDate = moment(firstDayOfMonth).format();
    let firstDayOfTheMonth = formatedDate.toString().slice(0, 10);
    let today = moment(new Date()).format().toString().slice(0, 10);

    let storeIds = userStores?.map((el, i) => {
        // userStoresId = {value: el?._id, label: el?._id}
        return {value: el?._id, label: el?.name}
    })


    console.log('TRANSACTIONS LIST', transactionsPerCity)

    const getTransactionsPerCity = async (event) => {
        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide");
            return;
        } else {
            setCustomDateInputValidation('')
        }
        //
        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`admin/stats/percity/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`)
            console.log('REZS', response)
            // setIsLoading(false)
            setTransactionsPerCity(response.data)
        } catch (error) {
            console.log(error)
        }
    }


    const getMeasurementTransactions = async (event) => {
        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide");
            return;
        } else {
            setCustomDateInputValidation('')
        }
        //
        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/admin/stats/permeasurement/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`)
            console.log(response)
            // setIsLoading(false)
            setMeasurement(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    //
    //
    const getAllTransactions = async (event) => {
        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/newstatistics/perfurnitor/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log('ALLL TRANSACTIONS', response)
            setStatisticsPerFurnitor(response?.data)
            // setIsLoading(false)
            if (response.status == 401) {
                toast.error('Authentikimi ju ka skaduar!');
            }
        } catch (error) {
            toast.error('Authentikimi ju ka skaduar!');
            console.log(error)
        }
    }

    const getTransactionsPerProductCategory = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/newstatistics/perprodcat/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response);
            setProdCategoryTransactions(response?.data);
            if (response.status == 401) {
                toast.error('Authentikimi ju ka skaduar!');
            }
        } catch (error) {
            toast.error('Authentikimi ju ka skaduar!');
            console.log(error)
        }
    }

    const getCategoriesPerStore = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/newstatistics/perstorecat/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response);
            setStoreCategoryTransactions(response?.data);
            if (response.status == 401) {
                toast.error('Authentikimi ju ka skaduar!');
            }
        } catch (error) {
            toast.error('Authentikimi ju ka skaduar!');
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            console.log(error)
        }
    }


    const getStatisticsPerAge = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`admin/newstatistics/percatagegroup/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`)

            console.log(response)
            setStatisticsPerAge(response?.data)
            if (response.status == 401) {
                toast.error('Authentikimi ju ka skaduar!');
            }
        } catch (error) {
            toast.error('Authentikimi ju ka skaduar!');
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            console.log(error)
        }
    }

    const getDonationTotal = async (event) => {

        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/admin/stats/donationtotal/`);
            console.log(response)
            setDonation(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getDonationPerProduct = async (event) => {

        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/admin/stats/donationperfurnitor/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setDonationProducts(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getAllUsers = async (event) => {
        //
        // let objectDate = {
        //     firstDayOfMonth: event.target.firstDateOfMonth.value,
        //     customDayOfMonth: event.target.customDayOfMonth.value
        // }
        //
        // if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
        //     setCustomDateInputValidation("Please provide a valid date");
        //     return;
        // }

        try {
            let response = await axios.get(`/admin/users/users/by-role`)

            console.log(response)
            setAllUsers(response?.data)
            if (response.status == 401) {
                toast.error('Authentikimi ju ka skaduar!');
            }
        } catch (error) {
            toast.error('Authentikimi ju ka skaduar!');
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            console.log(error)
        }
    }


// ---------------------------------------------- furnitor id


    //
    // console.log('completed', completedStats)
    // console.log('top 10', statsPerTopTen);
    // console.log('prodct', statsPerProduct);
    // console.log('category', statsPerCategory);
    // console.log('all', allStats);
    //
    //
    // useEffect(() => {
    //
    // }, []);
    //

    const StatisticsPerFurnitorQty = {
        labels: [...statisticsPerFurnitor?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...statisticsPerFurnitor?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...statisticsPerFurnitor?.map((el => el?.total_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Transaksionet ', // statusin
                data: [...statisticsPerFurnitor?.map((el => el?.total_transactions))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    //
    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };


    //
    const TransactionsPerProductCategory = {
        labels: [...prodCategoryTransactions?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...prodCategoryTransactions?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...prodCategoryTransactions?.map((el => el?.total_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Transaksionet', // statusin
                data: [...prodCategoryTransactions?.map((el => el?.total_transactions))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const PerStoreCategory = {
        labels: [...storeCategoryTransactions?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...storeCategoryTransactions?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...storeCategoryTransactions?.map((el => el?.total_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Transaksionet', // statusin
                data: [...storeCategoryTransactions?.map((el => el?.total_transactions))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const perAgeGroupStatistics = {
        labels: [...statisticsPerAge?.map((el => el?.agerange + ' ' + el?.prod_cat))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...statisticsPerAge?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],

                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...statisticsPerAge?.map((el => el?.total_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Transaksionet', // statusin
                data: [...statisticsPerAge?.map((el => el?.total_transactions))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const statisticsPerMeasurement = {
        labels: [...measurement?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...measurement?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...measurement?.map((el => el?.total_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Transaksionet', // statusin
                data: [...measurement?.map((el => el?.total_transactions))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const totalDonation = {
        labels: [...donation?.map((el => el?._id))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...donation?.map((el => el?.donation_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...donation?.map((el => el?.donation_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            // {
            //     label: 'Transaksionet', // statusin
            //     data: [...donation?.map((el => el?.total_transactions))],
            //     backgroundColor: [
            //         // 'rgba(255, 99, 132, 0.2)',
            //         // 'rgba(54, 162, 235, 0.2)',
            //         'rgba(255, 206, 86, 0.2)',
            //         // 'rgba(75, 192, 192, 0.2)',
            //         // 'rgba(153, 102, 255, 0.2)',
            //         // 'rgba(255, 159, 64, 0.2)',
            //     ],
            //     borderColor: [
            //         // 'rgba(255, 99, 132, 1)',
            //         // 'rgba(54, 162, 235, 1)',
            //         'rgba(255, 206, 86, 1)',
            //         // 'rgba(75, 192, 192, 1)',
            //         // 'rgba(153, 102, 255, 1)',
            //         // 'rgba(255, 159, 64, 1)',
            //     ],
            //     borderWidth: 1,
            // },
        ],
    };

    const totalDonationPerProduct = {
        labels: [...donationProducts?.map((el => el?._id))], // statusin
        datasets: [
            {
                label: 'Totali EUR', // statusin
                data: [...donationProducts?.map((el => el?.donation_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Sasia', // statusin
                data: [...donationProducts?.map((el => el?.donation_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            // {
            //     label: 'Transaksionet', // statusin
            //     data: [...donation?.map((el => el?.total_transactions))],
            //     backgroundColor: [
            //         // 'rgba(255, 99, 132, 0.2)',
            //         // 'rgba(54, 162, 235, 0.2)',
            //         'rgba(255, 206, 86, 0.2)',
            //         // 'rgba(75, 192, 192, 0.2)',
            //         // 'rgba(153, 102, 255, 0.2)',
            //         // 'rgba(255, 159, 64, 0.2)',
            //     ],
            //     borderColor: [
            //         // 'rgba(255, 99, 132, 1)',
            //         // 'rgba(54, 162, 235, 1)',
            //         'rgba(255, 206, 86, 1)',
            //         // 'rgba(75, 192, 192, 1)',
            //         // 'rgba(153, 102, 255, 1)',
            //         // 'rgba(255, 159, 64, 1)',
            //     ],
            //     borderWidth: 1,
            // },
        ],
    };

    const chartData = {
        labels: ['Shfrytëzues Total'],
        // datalabels: ['# of Users','asdfasdf','asdfasdf'],
        datasets: [
            {
                label: '# of Mobile',
                data: [users[0]],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 1,
            },
            {
                label: '# of Furnitors',
                data: [users[1]],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: '# of Admins',
                data: [users[2]],
                backgroundColor: [
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    const transactionPerCity = {
        labels: [...transactionsPerCity?.map((el => el?.city_name))], // statusin
        datasets: [
            {
                label: 'Quantiteti e Donactionit', // statusin
                data: [...transactionsPerCity?.map((el => el?.donation_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Çmimi total i donactionit', // statusin
                data: [...transactionsPerCity?.map((el => el?.donation_total_price))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Çmimi Total', // statusin
                data: [...transactionsPerCity?.map((el => el?.total_price))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'Quantiteti Total', // statusin
                data: [...transactionsPerCity?.map((el => el?.total_qty))],
                backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    // 'rgba(255, 99, 132, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    return (
        <>
            <div className="biznes_produktet">
                <div className="fjeshil-container">
                    <div className="Statistikat">
                        <div className="row mt-5"></div>
                        <h1>Statistikat për Admin</h1>
                        <form onSubmit={callAllFunctions}>
                            <div className="row mt-5 form-input">
                                <div className="input-container">
                                    <div className={'col-md-2 col-12 col-sm-12'}>
                                        <input type='date'
                                               className="form-control first-date"
                                               name="firstDateOfMonth"
                                               defaultValue={firstDayOfTheMonth}
                                        />
                                    </div>
                                    <div className={'col-md-2 col-12 col-sm-12'}>
                                        <input type='date'
                                               className="form-control first-date"
                                               name="customDayOfMonth"
                                               defaultValue={today}
                                        />
                                    </div>
                                    <div className="col-md-2 col-sm-2">
                                        <button type="submit"
                                                value="Search by Date"
                                                className='btn btn-success search-button'>
                                            Kërko
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            isLoading ? <Loader/> :
                                <>
                                    <div className="row mt-5">
                                        {/*<div className="col-md-6">*/}
                                        {/*    <h3>Totali i Transaksioneve per Furnitor</h3>*/}
                                        {/*    <div className="col-md-12 ">*/}
                                        {/*        <Bar data={StatisticsPerFurnitor} options={options}/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-md-12">
                                            <h3>Furnitor</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={StatisticsPerFurnitorQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="row mt-5">*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*    <h3>Transaksionet per Produkt Kategori</h3>*/}
                                    {/*    <div className="col-md-12 ">*/}
                                    {/*        <Bar data={TransactionsPerProductCategory} options={options}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*    <div className="col-md-12">*/}
                                    {/*        <h3>Transaksionet per Produkt Kategori Sasia</h3>*/}
                                    {/*        <div className="col-md-12 ">*/}
                                    {/*            /!*<Bar data={TransactionsPerProductCategoryQty} options={options}/>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <h3>Kategoritë e Produkteve</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={TransactionsPerProductCategory} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <h3>Kategoritë e Bizneseve</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={PerStoreCategory} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <h3>Statistikat për Grupmoshe</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={perAgeGroupStatistics} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <h3>Statistikat për Njesi Matese</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={statisticsPerMeasurement} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12">
                                            <h3>Statistikat për te gjitha Donacionet</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={totalDonation} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12">
                                            <h3>Statistikat e produkteve për Donacion</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={totalDonationPerProduct} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12">
                                            <h3>Statistikat për Perdorues </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={chartData} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12">
                                            <h3>Statistikat e transaksione sipas Qytetit </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={transactionPerCity} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Statistics;