import './StatistikatPerBiznes.scss';
import React, {useEffect, useState} from "react";
import axios from "../../axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import {getAllByDisplayValue} from "@testing-library/react";
import {Bar} from "react-chartjs-2";
import Loader from "../Loader/loader";
import Select from "react-select";

const StatistikatPerBiznes = () => {

    let date = new Date();

    const [customDateInputValidation, setCustomDateInputValidation] = useState('');
    const [statsPerCategory, setByCategory] = useState([]);
    const [statsPerProduct, setStatsPerProduct] = useState([]);
    const [statsPerTopTen, setStatsPerTopTen] = useState([]);
    const [allStats, setAllStats] = useState([]);
    const [completedStats, setCompletedStats] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [storeValidation, setStoreValidation] = useState('');
    const [userStores, setUserStores] = useState([]);
    const [stockPerProduct, setStockPerProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;


    const callAllFunctions = async (event) => {
        event.preventDefault();
        console.log('a po thir')
        try {
            setIsLoading(true)
            await getAllTransactions(event);
            await getCompletedTransactions(event);
            await getStatsPerCategory(event);
            await getTopTenPerStats(event);
            await getStatsPerProduct(event);
            await getStockPerProduct(event);
            setTimeout(() => {
                setIsLoading(false)
            }, 3000)
        } catch (error) {
            console.log(error)
        }
    }


    let storeId;
    const storeSelection = (event) => {
        console.log('id-ja', event.value);
        // console.log('opcioni',event.target.options[event.target.selectedIndex].text);
        storeId = event.value;
        // this.setState({storeName: storeName});
        if (storeId === undefined || storeId === null || storeId === '') {
            setStoreValidation('Please select a store');
            return false;
        }
        // console.log('store id id id id', storeId)
    }

    const getUserStores = async () => {
        try {
            let userStores = await axios.get('/admin/user-store/all');
            console.log(userStores.data);
            setUserStores(userStores?.data)
        } catch (error) {
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            console.log(error)
        }
    }

    useEffect(() => {
        getUserStores();
    }, [])


    const getAllTransactions = async (event) => {

        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/furnitorstatistics/alltransactions/${storeId}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`)
            console.log(response)
            setAllStats(response.data)
        } catch (error) {
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            console.log(error)
        }
    }

    const getCompletedTransactions = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/furnitorstatistics/completedtransactions/${storeId}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setCompletedStats(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getStatsPerCategory = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/furnitorstatistics/percat/${storeId}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setByCategory(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getTopTenPerStats = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/furnitorstatistics/top10/${storeId}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setStatsPerTopTen(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getStatsPerProduct = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/furnitorstatistics/perprod/${storeId}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setStatsPerProduct(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getStockPerProduct = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Please provide a valid date");
            return;
        }

        try {
            let response = await axios.get(`/admin/furnitorstatistics/stockperprod/${storeId}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setStockPerProduct(response?.data);
        } catch (error) {
            console.log(error)
        }
    }


    // console.log('completed', completedStats)
    // console.log('top 10', statsPerTopTen);
    // console.log('prodct', statsPerProduct);
    // console.log('category', statsPerCategory);
    // console.log('all', allStats);


    // useEffect(() => {
    //
    // }, []);


    const chartData = {
        labels: [...allStats?.map((el => {
            if(el?.status === 'approved') {
                return 'Aprovuar'
            }
            if(el?.status === 'initial') {
                return 'Inicuar'
            }
            if(el?.status === 'expired') {
                return 'Skaduar'
            }
            if(el?.status === 'declined') {
                return 'Refuzuar'
            }else{
                return el.status;
            }
        }))], // statusin
        datasets: [
            {
                label: 'Totali i Transaksioneve', // statusin
                data: [...allStats?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const chartDataQty = {
        labels: [...allStats?.map((el => {
            if(el?.status === 'approved') {
                return 'Aprovuar'
            }
            if(el?.status === 'initial') {
                return 'Inicuar'
            }
            if(el?.status === 'expired') {
                return 'Skaduar'
            }
            if(el?.status === 'declined') {
                return 'Refuzuar'
            }else{
                return el.status;
            }
        }))], // statusin
        datasets: [
            {
                label: 'Sasia e Transaksioneve', // statusin
                data: [...allStats.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    const topTen = {
        labels: [...statsPerTopTen?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Top 10 Totali', // statusin
                data: [...statsPerTopTen?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const topTenQty = {
        labels: [...statsPerTopTen?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Top 10 Sasia', // statusin
                data: [...statsPerTopTen?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const categoryStats = {
        labels: [...statsPerCategory?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali për Kategori', // statusin
                data: [...statsPerCategory?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const categoryStatsQty = {
        labels: [...statsPerCategory?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Sasia për Kategori', // statusin
                data: [...statsPerCategory?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const productStats = {
        labels: [...statsPerProduct?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Të Gjitha Statistikat', // statusin
                data: [...statsPerProduct?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const productStatsQty = {
        labels: [...statsPerProduct?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Sasia për Produkt', // statusin
                data: [...statsPerProduct?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const StockStats = {
        labels: [...statsPerProduct?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Të Gjitha Stoqët', // statusin
                data: [...statsPerProduct?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const StockStatsQty = {
        labels: [...stockPerProduct?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Sasia e Stoqëve', // statusin
                data: [...stockPerProduct?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    let storeIds = userStores?.map((el, i) => {
        // userStoresId = {value: el?._id, label: el?._id}
        return {value: el?._id, label: el?.name}
    })


    let formatedDate = moment(firstDayOfMonth).format();
    let firstDayOfTheMonth = formatedDate.toString().slice(0, 10);
    let today = moment(new Date()).format().toString().slice(0, 10);


    return (
        <>
            <div className="biznes_produktet">
                <div className="fjeshil-container">
                    <div className="Statistikat">
                        <div className="row mt-5"></div>
                        <h1>Statistikat për Biznes</h1>
                        <form onSubmit={callAllFunctions}>
                            <div className="row mt-5 form-input">
                                <div className="input-container">
                                    <div className={'col-md-2 col-12 col-sm-12'}>

                                        <input type='date'
                                               className="form-control first-date"
                                               name="firstDateOfMonth"
                                               defaultValue={firstDayOfTheMonth}
                                        />
                                    </div>
                                    <div className={'col-md-2 col-12 col-sm-12'}>
                                        <input type='date'
                                               className="form-control first-date"
                                               name="customDayOfMonth"
                                               defaultValue={today}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Select
                                            onChange={(event) => storeSelection(event)}
                                            placeholder="Selekto Shitoren"
                                            // className="form-control"
                                            // style={{backgroundColor: 'Grey'}}
                                            options={storeIds}
                                        >
                                            {/*<option value={null}>Selekto Produktin</option>*/}
                                            {/*{productData?.map(*/}
                                            {/*  (el, index) => {*/}
                                            {/*    return <option key={index} value={el._id}>{el.name}</option>*/}
                                            {/*  }*/}
                                            {/*)}*/}
                                        </Select>
                                    </div>
                                    <div className="col-md-2 col-sm-12">
                                        <button type="submit"
                                                value="Search by Date"
                                                className='btn btn-success search-button'>
                                            Kërko
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            isLoading ? <Loader/> :
                                <>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali i Transaksioneve</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={chartData} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia e Transaksioneve</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={chartDataQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Top 10 Totali</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={topTen} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Top 10 Sasia </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={topTenQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali për Produkt</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={productStats} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Produkt </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={productStatsQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali për Stok</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={StockStats} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Stok</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={StockStatsQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatistikatPerBiznes;