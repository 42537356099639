import React, {useState, useEffect, Fragment} from 'react';
import jwt_decode from 'jwt-decode';
import axios from '../../axios';
import './ShtoShfrytzues.scss';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const FshijShfrytezues = (props) => {

    const history = useHistory();
    const token = window.localStorage.getItem("cd");
    const decode = token ? jwt_decode(token) : null;

    const [userData, setuserData] = useState([]);
    const [isUserEdit, setisUserEdit] = useState(false);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isPasswordEdit, setisPasswordEdit] = useState(false);
    const [passData, setpassData] = useState([]);
    const [ageRange, setAgeRange] = useState([]);

    const vleratBaze = () => {
        // e.preventDefault();
        const ud = Object.assign({}, userData);
        ud.gender = 'it';
        ud.agerange = '0-0';
        setuserData(ud);
    }


    const fjalkalimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        setuserData(ud);
    };

    const fjalkalimiKonfirmimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass2 = e.target.value;
        setuserData(ud);
    };


    const emri = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setuserData(ud);
    };

    const mbiemri = (e) => {
        const ud = Object.assign({}, userData);
        ud.surname = e.target.value;
        setuserData(ud);
    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setuserData(ud);
    };

    const telefoni = (e) => {
        const ud = Object.assign({}, userData);
        ud.phone = e.target.value;
        setuserData(ud);
    };

    const setGender = (e) => {
        console.log(e.target.value)
        const ud = Object.assign({}, userData);
        ud.gender = e.target.value
        setuserData(ud)
    }

    const role = (e) => {
        console.log('log incopming', e.target.value)
        const ud = Object.assign({}, userData);
        ud.role_id = e.target.value;
        setuserData(ud);
    }
    //
    // const active = (e) => {
    //     console.log('checkboxi', e.target.checked)
    //     const ud = Object.assign({}, userData);
    //     ud.active = e.target.checked;
    //     setuserData(ud);
    // }


    const age = (e) => {
        console.log('age rage id incoming', e.target.value)
        const ud = Object.assign({}, userData);
        ud.agerange = e.target.value;
        setuserData(ud)
    }

    const ndryshoUserin = (e) => {
        e.preventDefault();
        let userEdit = isUserEdit;

        if (userEdit === true) {
            setisUserEdit(false);
        } else {
            setisUserEdit(true);
            vleratBaze();
        }
        ;
    }

    const ruajNdryshimet = (e) => {
        e.preventDefault();
        // console.log('para se me shku', userData)
        axios.delete("/admin/users/" + props.match.params.id)
            .then((res) => {
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
                if (res.status == 200) {
                    console.log(res.data);
                    toast.success('Shfrytëzuesi u fshij me sukses!');
                    history.push('/menaxhoshfrytezuesit');
                } 
                if (res.status == 500) {
                    console.log(res.data);
                    toast.error('Diçka shkoi gabim!');
                    history.goBack();
                }

            })
            .catch((err) => {
                // window.localStorage.removeItem('cd')
                // window.location = '/kycu';
                toast.error('Diçka shkoi gabim!');
                console.log(err)
            });

    }

    const ruajFjalkalimineRi = (e) => {
        e.preventDefault();
        let pass = passData.pass;
        console.log('passData', {pass});
        axios.post("/admin/users/updatepassword/" + props.match.params.id, {pass})
            .then((res) => {
                console.log('Passwordi u Ndryshua!');
                setisUserEdit(false);
                if (res.status === 200) {
                    window.localStorage.removeItem('cd');
                    window.location = "/kyqu";
                }
            })
            .catch((err) => {
                console.log(err)
                window.localStorage.removeItem('cd')
                window.lcation = '/kycu';
            });
    }

    const imageDataChange = (e) => {
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        setimageData(ud);
        console.log('fajllimrena', imageData)
    }

    const imazhiToggle = (e) => {
        e.preventDefault();
        let imazhiEdit = ndryshoImazhin;
        if (imazhiEdit) {
            setndryshoImazhin(false);
        } else {
            setndryshoImazhin(true);
        }
    }

    const passwordToggle = (e) => {
        e.preventDefault();
        let paswdEdit = isPasswordEdit;
        if (paswdEdit) {
            setisPasswordEdit(false);
        } else {
            setisPasswordEdit(true);
        }
    }

    const ruajImazhin = (e) => {
        // e.preventDefault();
        // const formData = new FormData();
        // formData.append('image', imageData.image)
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         'Access-Control-Allow-Origin': '*'
        //     }
        // }
        axios.delete("/admin/users/" + props.match.params.id)
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                window.localStorage.removeItem('cd')
                window.location = '/kycu';
                console.log(err)
            });

    }
    //
    const getAgeRange = async () => {
        try {
            let ageRange = await axios.get("/admin/agerange/all");
            // console.log("AHJHAJHAHH", ageRange.data)
            setAgeRange(ageRange.data)
        } catch (error) {
            window.localStorage.removeItem('cd');
            window.location = "/kyqu";
            console.log(error)
        }
    }


    useEffect(() => {
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;

        axios.get('/admin/users/' + props.match.params.id)
            .then((res) => {
                console.log('userData', res.data);
                setuserData(res.data);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
            });
        // vleratBaze();
        // axios.get('/admin/agerange/all')
        //     .then((res) => {
        //         console.log('incoming age range',res)
        //         setAgeRange(res?.data)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        getAgeRange();
    }, []);

// console.log('userdata',userData);
//     console.log('userData', passData);
// console.log('user edit', isUserEdit);

    return (
        <>
            <div className="shfrytezuesi">
                {/* <div className={'titulli'}>  Menaxhimi i Shfrytezuesit</div> */}

                <div className="fjeshil-container">
                    {ndryshoImazhin &&
                    <div className='upload-imazhi'>
                        <form onSubmit={ruajImazhin}>
                            <input type='file' name='file' onChange={imageDataChange}/>
                            <button type='submit' disabled={imageData ? false : true}>Ruaje imazhin e ri!</button>
                        </form>
                    </div>
                    }
                    <div className="row pl-3">
                        <div className="col-md-12">

                            <div className={'row w-100 '}>

                                <div className={'col-10'}>
                                    <div className="shp_groupform">

                                        {/* <button className='ndrysho-imazhin'
                                                onClick={imazhiToggle}>{ndryshoImazhin ? 'Largohu' : 'Ndrysho Imazhin'}</button> */}

                                        <div className='user-image-container'>
                                            {/* <img className='user-image' src={'http://localhost:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            {/* <img className='user-image' src={'http://10.10.10.206:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            <img className='user-image'
                                                 src={process.env.REACT_APP_BACK_URL + userData.pic_url}
                                                 alt='userPicture'></img>
                                        </div>

                                        <form onSubmit={ruajNdryshimet} className='shp_form'>
                                            <div className='row'>

                                                <h4 className='pl-3 pb-4 w-100 text-left'>Fshij Shfrytëzuesin</h4>
                                                <label className='col-6'>Emri: </label>
                                                <input className='col-6 form-control'
                                                       onChange={emri}
                                                       defaultValue={userData.name}
                                                       disabled/>
                                                <label className='col-6'> Mbiemri:</label>
                                                <input className='col-6 form-control' defaultValue={userData.surname}
                                                       onChange={mbiemri}
                                                       disabled/>

                                                <label className='col-6 '> Email: </label>
                                                <input className='col-6 form-control' defaultValue={userData.email}
                                                       onChange={email}
                                                       disabled/>

                                                <label className='col-6'> Telefoni: </label>
                                                <input className='col-6 form-control' defaultValue={userData.phone}
                                                       onChange={telefoni}/>
                                                <label className="col-md-6 mb-4">Roli</label>
                                                <select
                                                    onChange={role}
                                                    value={userData.role_id}
                                                    disabled
                                                    className="form-control col-6">
                                                    <option disabled selected>Selekto Rolin</option>
                                                    <option value={1}>SuperAdmin</option>
                                                    <option value={2}>Administrator</option>
                                                    <option value={3}>Furnitor</option>
                                                    <option value={4}>Mobil</option>
                                                </select>
                                                <label className="col-md-6 mb-4">Grupmosha</label>
                                                <select disabled
                                                        onChange={age}
                                                        value={userData.agerange}
                                                        className="form-control col-6">
                                                    <option disabled selected>Zgjidhe Grupmoshen</option>
                                                    {
                                                        ageRange?.map((el, i) => {
                                                            // console.log('agEEEEEEEEEEEEEEEEEEEe range comming', el)
                                                            return (
                                                                <option key={el?._id}
                                                                        value={el?._id}>{el?.agerange}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                <label className="col-md-6 mb-4">Gjina</label>
                                                <select
                                                    disabled
                                                    onChange={setGender}
                                                    value={userData.gender}
                                                    className="form-control col-6">
                                                    <option disabled selected>Zgjidhe Gjininë</option>
                                                    <option value="Male">Mashkull</option>
                                                    <option value="Female">Femer</option>
                                                    <option value="Other">Tjetër</option>
                                                </select>


                                                {/*<label className='col-6'> Active:</label>*/}
                                                {/*<input className='col-6 form-control'*/}
                                                {/*       type="checkbox"*/}
                                                {/*       // checked={userData?.active ? 'checked' : null}*/}
                                                {/*       // defaultValue={userData.active}*/}
                                                {/*       onChange={active}*/}
                                                {/*    // disabled={!isUserEdit }*/}
                                                {/*/>*/}
                                                {/* <label className='col-6'> Fjalekalimi:</label>
                                                <input type='password' className='col-6 form-control'
                                                       onChange={fjalkalimi} required/>

                                                <label className='col-6 '> Konfirmo fjalekalimin: </label>
                                                <input type='password' className='col-6 form-control'
                                                       onChange={fjalkalimiKonfirmimi} required/> */}
                                            </div>
                                            <div className='row'>

                                                <div className='col-6'>

                                                    <button type='submit' className='form-control'> Fshij</button>

                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    {isPasswordEdit &&
                    <p className='pl-3'> * Nese passwordi juaj ndryshohet me sukses ju ridirektoheni ne formen e
                        kyqjes!</p>}
                </div>
            </div>

        </>
    );

}
export default FshijShfrytezues;