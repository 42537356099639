import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';


// CSS
import './Listimi.scss';

const Listimi = (props) => {


    return (
        <>
            <div className={'listimi-dyqanet'}>
                <div className={'row '}>
                    {/*foto*/}
                    <div className={'col-3'}>
                        <img
                            src={process.env.REACT_APP_BACK_URL + (props.data.pic_url != null ? props.data.pic_url : props.data?.cat_data[0]?.pic_url)}
                            alt='#'
                            className="picture-location"/>
                    </div>
                    {/*content*/}
                    <div className={'col-9 pl-4'}>
                        <div className={'row'}>
                            {/*name*/}
                            <div className={'col-12'}><h5>{props.data.name}</h5></div>
                            {/*actions*/}
                            <div className={'img-container'}>
                                <div className="iimg-container">
                                    <Link to={"/edito-dyqanin/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={EditIcon}
                                            className='actions'/>
                                    </Link>
                                    <Link to={"/fshijbiznesin/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={DeleteIcon}
                                            className='actions'/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={'row category--name '}>Numri Unik Identifikues
                            (NUI): {props.data.fiscal_nr}</div>
                        <div className={'row category--name '}> Kategoria: {props.data.cat_data[0]?.name}</div>
                        <div className={'row category--name '}> Email: {props.data.user_email}</div>
                        {/* <div className={'row category--name '}> Telefoni: {props.data.user_phone}</div> */}
                        {/* <div className={props.data.active ? 'row status--name text-success' : 'row status--name text-danger' }>{props.data.active && "Aktive"} {!props.data.active && 'Pasive'}</div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Listimi;
