import './ListView.scss'
import {Link} from "react-router-dom";
// import DeleteIcon from "../../../images/delete.svg";
// import EditIcon from "../../../images/edit.svg";
// import Reports from '../../../images/chart-bar-regular.svg';
import React from "react";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/delete.svg";

const ListView = (props) => {


    return (
        <>
            <tbody>
            <tr>
                <td>{props.data.name}</td>
                <td> {props.data.cat_data[0]?.name}</td>
                {/*<div className="col-2"> {props?.data?.active ? "Active" : "Passive"}</div>*/}
                <td> {props.data.fiscal_nr}</td>
                {/*<td className='col-2'>*/}
                {/*    <img*/}
                {/*        src={process.env.REACT_APP_BACK_URL + (props.data.pic_url != null ? props.data.pic_url : props.data?.cat_data[0]?.pic_url)}*/}
                {/*        alt='shfrytezuesi'*/}
                {/*        className="picture-location"/>*/}
                {/*</td>*/}
                {/*<div className="col-2">{props.data.fiscal_nr}</div>*/}
                <td>
                    <Link to={"/edito-dyqanin/" + props.data._id}>
                        <img
                            alt="delete"
                            src={EditIcon}
                            className='actions'/>
                    </Link>
                </td>
                <td>
                    <Link to={"/fshij-dyqanin/" + props.data._id}>
                        <img
                            alt="delete"
                            src={DeleteIcon}
                            className='actions'/>
                    </Link>
                </td>
            </tr>
            </tbody>
        </>
    )
}

export default ListView;