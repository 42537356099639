import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const ShtoBexhin = () => {

    const [badgeData, addbadgeData] = useState({});

    const history = useHistory();

    // const emri = e => {
    //     console.log(e)
    //     const ud = Object.assign({}, badgeData);
    //     ud.name = e.target.value
    //     addbadgeData(ud);
    // }
    //
    // const vlera = e => {
    //     const ud = Object.assign({}, badgeData);
    //     ud.value = e.target.value;
    //     addbadgeData(ud);
    // }

    const shtoBexhinnn = async (e) => {
        e.preventDefault();

        let formData = new FormData();

        formData.append('name', e.target.emri.value);
        formData.append('value', e.target.vlera.value);
        formData.append('image', e.target.pic_url.files[0]);
        formData.append('description_on', e.target.description_on.value)
        formData.append('description_off', e.target.description_off.value)

        for (let [key, value] of formData) {
            console.log(`${key}: ${value}`)
        }

        for (let [key, value] of formData) {
            console.log(`${key}: ${value}`)
        }

        await axios.post('/admin/badges/add',formData)
            .then(res => {
                // console.log(res.data);
                // window.location = '/bexhi';
            toast.success('Bexhi u shtua me sukses!');
            history.goBack();
            })
            .catch(err => {
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                history.goBack();
            });
        // window.history.back();
    }


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Shto Bexhin</h3>
                            <div className="shp_groupform">
                                <form onSubmit={shtoBexhinnn}>
{/* 
                                        <label>
                                            <Select
                                                onChange={shteti}
                                                placeholder="Selekto Shtetin"
                                                options={shtetii}>
                                            </Select>
                                        </label> */}

                                 
                                    <input type='text' className="form-control" placeholder='emri' name="emri"  required></input>
                                    <input type='number' className="form-control" placeholder='vlera' name="vlera"  required></input>
                                    <input type='file' className="form-control" placeholder='përshkrimi' name="pic_url" ></input>
                                    <textarea type='text' className="form-control"  style={{marginBottom: '10px'}}  placeholder='Përshkimi-on' name="description_on" />
                                    <textarea type='text' className="form-control" placeholder='Përshkimi-off' name="description_off" />
                                    <button type="submit">Shto</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoBexhin;
