import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Listimi from './Listimi';
import axios from '../../axios';
import Loader from '../Loader/loader';


// CSS
import './Listimi.scss';
import ReactPaginate from "react-paginate";
import ListView from "./ListViewComponent/ListView";


const MenaxhimiiDyqaneve = (props) => {


    const [productsData, setProductsData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');


    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const userPerList = 50;
    const pagesVisited = pageNumber * usersPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        // getCategories();
        axios.get('/admin/user-store/all')
            .then((res) => {
                // console.log('axios', res);
                setProductsData(res?.data);
                setFilteredUsers(res?.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }, []);


    const searcher = async (text) => {
        let searchText = text.target.value.toLowerCase();
        let filteredUser = productsData?.filter((users) => {
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }


    return (isLoading ? <Loader/> :
            <>
                <div className="biznes_produktet">
                    <div className="fjeshil-container">
                        <div className="row">
                            <div className="col-md-12 col-12 biznes_title">
                                <h1>Menaxhimi i Dyqaneve</h1>
                                <Link to="/shtobiznes">
                                    <button className="add-shop">Shto Dyqanin</button>
                                </Link>
                                <div className="col-12 col-md-6 md-form mt-5 pb-3">
                                    <input onChange={searcher} className="form-control"
                                           type="text" placeholder="Kërko..." aria-label="Search"/>
                                    {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                                </div>
                                <div className="button-group">
                                    <button type="button" onClick={() => setComponentToBeRendered('grid')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-large "></i>
                                    </button>
                                    <button type="button" onClick={() => setComponentToBeRendered('list')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-list"></i>
                                    </button>
                                </div>
                            </div>
                            <div className={'row  w-100 p-3 '}>
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + usersPerPage)?.map
                                        (filteredUsers => {
                                            return (
                                                <Listimi data={filteredUsers} key={filteredUsers?._id}
                                                />
                                            )
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-1"><b>Emri i Furnitorit</b>
                                                                        </th>
                                                                        <th><b>Kategoria</b></th>
                                                                        {/*<div className="col-2"><b>Statusi</b></div>*/}
                                                                        <th><b>Numri Unik Identifikues
                                                                            (NUI)</b>
                                                                        </th>
                                                                        {/*<td  className="col-2"><b>Imazhi</b></td>*/}
                                                                        <th><b>Modifiko</b></th>
                                                                        <th><b>Fshij</b></th>
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pagesVisited, pagesVisited + userPerList)?.map
                                                                            (filteredUsers => {
                                                                                return (
                                                                                    <ListView data={filteredUsers}
                                                                                              key={filteredUsers?._id}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }

                                                                </table>
                                                            </div>
                                                            {/*</div>*/}
                                                        </div>
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData?.length >= usersPerPage &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }

                                </div>
                            </div>
                            : ''
                    }
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData?.length >= userPerList &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </>
    );
};

export default MenaxhimiiDyqaneve;
