import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const FshijBexhin = (props) => {

    const [badgeData, addbadgeData] = useState({});
    const history = useHistory();

    const fshij = (e) => {
        e.preventDefault();

        axios.delete('/admin/badges/'+ props.match.params.id)
            .then(res => {
                // console.log(res.data);
                // window.location = '/bexhi';
                toast.success('Bexhi u fshij me sukses!');
                history.goBack();
            })
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                history.goBack();
                // window.localStorage.removeItem('cd'); window.location = "/login";
            });
        // window.history.back();

    }

    useEffect(() => {
        axios.get('/admin/badges/'+ props.match.params.id)
        .then(res => {
            addbadgeData(res.data);
        })
        .catch(err => {
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            // window.localStorage.removeItem('cd'); window.location = "/login";
            console.log(err);
        });
    }, [])

    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Fshij Bexhin</h3>
                            <p className={'p-3'}>Konfirmo Fshirjen e bexhit me parametrat e meposhtem!</p>
                            <div className="shp_groupform">
                                <form onSubmit={fshij}>

                                <input type='text' className="form-control" placeholder='Fillimi' defaultValue={badgeData.name} disabled></input>
                                    <input type='number' className="form-control" placeholder='Mbarimi'  defaultValue={badgeData.value} disabled></input>
                                    
                                 
                                    <p >* Fshirja e bexhit ndikon tek shfrytëzuesit mobil.</p>

                                    <button type="submit">Fshij</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FshijBexhin;
