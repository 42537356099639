import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Bar} from 'react-chartjs-2';
import axios from '../../axios';

// CSS
import './Listimi.scss';
import { toast } from 'react-toastify';

const ProdCatStats = (props) => {

    const [stats, setStats] = useState([]);

useEffect(()=>{
    axios.get('/admin/store-products/statistic/product-category/'+ props.match.params.id)
         .then((data)=> {
             setStats(data.data);
             console.log('prodcatstat', data.data);
         })
         .catch(err => {
             console.log(err)
             toast.error('Statistika nuk munden me u përditësuar!');
            //  window.localStorage.removeItem('cd');
            //  window.location = "/kyqu";
         });

},[]);


    return (
        <>

            <div className="shto_produkt">

                <div className="fjeshil-container">
                    <div className="row">

                        <h3 className='w-100 p-3'>Statistikat për Kategori te Produkteve</h3>

                        <div className="col-md-12">


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProdCatStats;
