import * as RiIcons from 'react-icons/ri';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
    {
        title: 'Paneli i Kontrollit',
        path: '/',
        cName: 'active',
    },
    {
        title: 'Profili Im',
        path: '/profili',
        cName: 'active1',
    },
    {
        title: 'Menaxhimi i Dyqaneve',
        path: '/menaxhodyqanet',
        cName: 'active2',
    },
    {
        title: 'Menaxhimi i Shfrytëzuesve',
        path: '/menaxhoshfrytezuesit',
        cName: 'active2',
    },
    {
        title: 'Menaxhimi i Produkteve',
        path: '/menaxhoproduktet',
        cName: 'active2',
    },
    {
        title: 'Menaxhimi i Stokut',
        path: '/biznesstoku',
        cName: 'active2',
    },
    {
        title: 'Menaxhimi i Transaksioneve',
        path: '/transactions',
        cName: 'active2',
    },
    {
        title: "Menaxhimi i Bulk Data",
        path: "/bulk-data",
        cName: 'active2',
    },
    {
        title: "Statistikat për Admin",
        path: "/statistics",
        cName: 'active2',
    },
    {
        title: "Statistikat për Biznes",
        path: "/statistics-per-business",
        cName: 'active2',
    },

    {
        title: 'Administrimi',
        path: '#',
        cName: 'active3',
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: "Grupmoshat",
                path: "/grupmoshat",
                cName: 'active2',
            },
            {
                title: "Bexhi",
                path: "/bexhi",
                cName: 'active2',
            },
            {
                title: "Qytetet",
                path: "/qytetet",
                cName: 'active2',
            },
            {
                title: "Shtetet",
                path: "/shtetet",
                cName: 'active2',

            },
            {
                title: "Kategoritë e Produkteve",
                path: "/kategorite/produktet",
                cName: 'active2',

            },
            {
                title: "Kategoritë e Biznesit",
                path: "/kategorite/bizneset",
                cName: 'active2',

            },
            {
                title: "Emailat Administrues",
                path: "/admin-emails",
                cName: 'active2',
            },
        ]
    },
];
