import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const FshijGrupmoshe = (props) => {

    const [agerangeData, addagerangeData] = useState({});
    const [StateData, setStateData] = useState([]);
    const history = useHistory();
    // const [errors, addErrors] = useState(false);


    // const emri = e => {
    //     console.log(e)
    //     const ud = Object.assign({}, agerangeData);
    //     ud.name = e.target.value
    //     addagerangeData(ud);
    // }

    // const mbarimi = e => {
    //     const ud = Object.assign({}, agerangeData);
    //     ud.country_id = e.target.value;
    //     addagerangeData(ud);
    // }

    const fshij = (e) => {
        e.preventDefault();

        axios.delete('/admin/agerange/' + props.match.params.id)
            .then(res => {
                // console.log(res.data);
                // window.location = '/grupmoshat';
                toast.success('Grupmosha u fshim me sukses!');
                history.goBack();
            })
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                history.goBack();
            });
        // window.history.back();

    }

    useEffect(() => {
        axios.get('/admin/agerange/' + props.match.params.id)
            .then(res => {
                addagerangeData(res.data);
            })
            .catch(err => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
            });
    }, [])

    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Fshij Grupmoshen</h3>
                            <p className={'p-3'}>Konfirmo Fshirjen e grupmoshes me parametrat e meposhtem!</p>
                            <div className="shp_groupform">
                                <form onSubmit={fshij}>

                                    <input type='number' className="form-control" placeholder='Fillimi'
                                           defaultValue={agerangeData.start} disabled></input>
                                    <input type='number' className="form-control" placeholder='Mbarimi'
                                           defaultValue={agerangeData.end} disabled></input>


                                    <p>* Fshirja e grupmoshes ndikon tek shfrytëzuesit mobil dhe furnitoret.</p>

                                    <button type="submit">Fshij</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FshijGrupmoshe;
