import {render} from '@testing-library/react';
import axios from './../../../axios';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Statistikat from '../../../images/Statistics.png';
import ListimiUserat from './../ListimiUserat';
import {Bar} from 'react-chartjs-2';
import ReactPaginate from "react-paginate";


const TeReja = () => {

    const [userData, setUserData] = useState([]);
    const [userStat, setUserStat] = useState({});
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);
    let displayUsers = [];

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    const getNewUsers = () => {
        axios.get('/admin/user-store/users/get-new-users')
            .then((data) => {
                setUserData(data.data);
                setFilteredUsers(data.data)
            })
            .catch((err) => {
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
                console.log(err);
            })

    }

    const getUserStats = () => {
        axios.get('/admin/users/users/by-role')
            .then((data) => {
                setUserStat(data.data);
            })
            .catch((err) => {
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
                console.log(err);
            })

    }


// console.log('stat',userStat);
// 'rgba(54, 162, 235, 1)',
// 'rgba(255, 206, 86, 1)',
// 'rgba(75, 192, 192, 1)',
// 'rgba(153, 102, 255, 1)',
// 'rgba(255, 159, 64, 1)',
    const chartData = {
        labels: ['Shfrytëzues Total'],
        // datalabels: ['# of Users','asdfasdf','asdfasdf'],
        datasets: [
            {
                label: '# Mobil',
                data: [userStat[0]],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 1,
            },
            {
                label: '# Furnitorë',
                data: [userStat[1]],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: '# Administratorë',
                data: [userStat[2]],
                backgroundColor: [
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    useEffect(() => {
        getNewUsers();
        getUserStats();
    }, [])

// console.log('newStores',userData);

    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = userData?.filter((users) => {
            console.log('users incoming', users)
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }

    console.log('filtered', filteredUsers)

    return (
        <>
            <div className="TeReja">
                <div className="col-12 col-sm-6 md-form mt-5 pb-3">
                    <input className="form-control"
                           type="text" onChange={searcher} placeholder="Kërko..." aria-label="Search"/>
                    {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                </div>
                <div className="statistikat1">
                    {/* <p>Shfrytezues Total</p> */}
                    <div className="statistikat p-2">
                        {/* <img src={Statistikat} alt="statistikat"/> */}
                        <Bar data={chartData} options={options}/>
                    </div>
                </div>
                <div className="op">
                    {displayUsers = filteredUsers?.slice
                    (pagesVisited, pagesVisited + usersPerPage)?.map
                    (filteredUsers => {
                        // console.log(el)
                        return <ListimiUserat data={filteredUsers} key={filteredUsers?._id}/>
                    })
                    }
                </div>

                {filteredUsers.length == 0 ? '' : <div className="row">
                    <div className="col-md-12">
                        <ReactPaginate
                            previousLabel={"Mbrapa"}
                            nextLabel={"Para"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </div>}
            </div>
        </>
    );

}
export default TeReja