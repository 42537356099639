import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Listimi from './Listimi';
import axios from '../../axios';
import Loader from '../Loader/loader';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

// CSS
import './Listimi.scss';
import ReactPaginate from "react-paginate";
import ListView from "../KategoriteBizneseve/ListViewComponent/ListView";


const KategoriteBizneseve = (props) => {


    const [productsData, setProductsData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');


    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 8;
    const userPerList = 50;
    const pagesVisited = pageNumber * usersPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    const history = useHistory();
    useEffect(() => {
        // getCategories();
        axios.get('/admin/store-categories/all')
            .then((res) => {
                // console.log('axios', res);
                setProductsData(res?.data);
                setFilteredUsers(res?.data);
                setIsLoading(false);
                if (res.status == 401) {
                    toast.error('Authentikimi ju ka skaduar!');
                    window.localStorage.removeItem('cd');
                    history.push('/kyqu');
                }
            })
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }, []);


    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = productsData?.filter((users) => {
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser);
        setPageNumber(0);
    }

    return (isLoading ? <Loader/> :
            <>
                <div className="biznes_produktet">
                    <div className="fjeshil-container">
                        <div className="row">
                            <div className="col-md-12 biznes_title">
                                <h1>Kategoritë e Bizneseve</h1>
                                <Link to="/kategorite/bizneset/shto">
                                    <button className="add-shop">Shto Kategori</button>
                                </Link>
                                <div className="col-md-6 col-12 md-form mt-5 pb-3">
                                    <input onChange={searcher} className="form-control"
                                           type="text" placeholder="Kërko..." aria-label="Search"/>
                                    {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                                </div>
                                <div className="button-group">
                                    <button type="button" onClick={() => setComponentToBeRendered('grid')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-large "></i>
                                    </button>
                                    <button type="button" onClick={() => setComponentToBeRendered('list')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-list"></i>
                                    </button>
                                </div>
                            </div>
                            <div className={'row w-100 p-3'}>
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + usersPerPage)?.map
                                        (filteredUsers => {
                                            return (
                                                <Listimi data={filteredUsers} key={filteredUsers?._id}
                                                />
                                            )
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-4"><b>Kategoria</b></th>
                                                                        <th className="col-3"><b>Statusi</b></th>
                                                                        <th className="col-3"><b>Imazhi</b></th>
                                                                        <th className="col-1"><b>Modifiko</b></th>
                                                                        <th className="col-1"><b>Fshij</b></th>
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pagesVisited, pagesVisited + userPerList)?.map
                                                                            (filteredUsers => {
                                                                                return (
                                                                                    <ListView data={filteredUsers}
                                                                                              key={filteredUsers?._id}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData.length >= usersPerPage &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }

                                </div>
                            </div>
                            : ''
                    }
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row">
                                <div className="col-12">
                                    {
                                        productsData.length >= userPerList &&
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>

            </>
    );
};

export default KategoriteBizneseve;
