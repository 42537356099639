import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ListimiTransakcionet from "../Transactions/Listimi";
import React, {useEffect, useState} from "react";
import download from 'js-file-download';
import axios from "../../axios";
import './BulkData.scss';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router-dom";

const BulkData = () => {

    const [userStores, setUserStores] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [storeValidation, setStoreValidation] = useState('');
    const [responseMessageFromBack, setResponseMessageFromBack] = useState('')
    const [isXLSXloading, setisXLSXloading] = useState(false);
    const [xlsxMSG, setxlsxMSG] = useState('');
    const [xlsxMSGerr, setxlsxMSGerr] = useState([]);
    const [storeName, setStoreName] = useState('');
    const [hasFile, setHasFile] = useState(false);
    const [storeId, setStoreId] = useState();

    const getUserStores = async () => {
        try {
            let userStores = await axios.get('/admin/user-store/all');
            console.log(userStores.data);
            setUserStores(userStores?.data)
        } catch (error) {
            console.log(error)
            // window.localStorage.removeItem('cd');
            // window.location('/login')
            toast.error('Diçka shkoi gabim!');

        }
    }

    // let storeName;
    const storeSelection = (event) => {
        // console.log('id-ja',event.target.value);
        console.log('opcioni',event);


        setStoreName(event.label);
        setStoreId(event.value);
        console.log('storeid',storeId);
        // this.setState({storeName: storeName});
        if (storeId === undefined || storeId === null || storeId === '') {
            setStoreValidation('Ju lutemi Selektoni dyqanin!');
            return false;
        } else {
            setStoreValidation('');
        }
        // console.log('store id id id id', storeId)
    }
    // console.log('store id id id id', storeId);
    // console.log('option',storeName);


    useEffect(() => {
        getUserStores();
    }, []);


    const uploadXls = async (e) => {
        e.preventDefault();
        let file = e?.target.filee.files[0];

        let forme = new FormData()
        forme.append('file', file);
        console.log(file)
        setisXLSXloading(true);
        axios.post(`/admin/xls/bulk-import/${storeId}`, forme)
            .then((data) => {
                setisXLSXloading(false);
                if (data.data.success == true) {
                    // setxlsxMSG('Te dhenat u importuan me sukses!');
                    toast.success('Te dhenat u importuan me sukses!');
                } else {
                    // setxlsxMSG('Diçka Shkoi Gabim. Reshtat e meposhtem kane gabime!');
                    toast.error('Diçka Shkoi Gabim. Reshtat e meposhtem kane gabime!');
                    setxlsxMSGerr(data.data.data);
                }
                console.log('suksesi', data.data.data);
            })
            .catch((err) => {
                console.log(err);
                setxlsxMSGerr(err);
                // window.localStorage.removeItem('cd');
                // window.location = '/kyqu';
                toast.error('Diçka shkoi gabim!');
            });
    }

    let storeIds = userStores?.map((el, i) => {
        // userStoresId = {value: el?._id, label: el?._id}
        return {value: el?._id, label: el?.name}
    })

    const checkFile = (event) => {
        let file = event?.target.files[0]
        if(file) {
            setHasFile(true)
        } else {
            setHasFile(false);
        }
    }

    return (
        <>
            <div className="BulkData">
                <div className="row BulkData">
                    <div className="col-md-12">
                        <h1>Menaxhimi i Bulk Data</h1>
                    </div>
                </div>
                <div className="row Transactions">
                    <div className="col-md-12">
                        <div className="row mb-2">
                            <div className='col-md-3 col-12'>
                                <a type="submit"
                                   href={process.env.REACT_APP_BACK_URL + '/uploads/bulk-import-template.xlsx'}
                                   target="_blank"
                                    // value="Search by Date"
                                   className='btn btn-success search-button'>
                                    Shkarko Shembullin
                                </a>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-3 col-12">
                                <Select
                                    onChange={(event) => storeSelection(event)}
                                    placeholder="Selekto Shitoren"
                                    options={storeIds}
                                >

                                </Select>
                            </div>
                            <div className="col-md-9 col-12">
                                <form onSubmit={uploadXls}>
                                    <input type='file'
                                           name='filee'
                                           id='file'
                                           className="input-xlsx"
                                        onChange={checkFile}
                                    />
                                    {
                                        hasFile ? <button type="submit" value="Ngarko XLSX"
                                                          className='btn btn-success search-button upload'>
                                            Ngarko te dhenat bulk per {storeName}
                                        </button>: ''
                                    }
                                </form>
                            </div>
                        </div>
                        <div className='row p-5'>
                            {xlsxMSG == 'Te dhenat u importuan me sukses!' || xlsxMSG == '' || xlsxMSG == [] ?
                                <div className='text-success'>  {xlsxMSG}</div> :
                                <div className='text-danger'>  {storeName} : {xlsxMSG}</div>
                            }
                            {
                                xlsxMSGerr.length > 0 &&
                                <div className='row p-3'>
                                    <table className='table'>
                                        <thead>
                                        <tr>
                                            <th>Nr</th>
                                            <th>Produkti</th>
                                            <th>Kategoria</th>
                                            <th>Detajet</th>
                                            <th>MePagese</th>
                                            <th>Qty</th>
                                            <th>njesija matese</th>
                                            <th>cmimi per qty</th>
                                            <th>data e skadimit</th>
                                            <th>pickup</th>
                                        </tr>
                                        </thead>
                                        {xlsxMSGerr.map((el) => {
                                            return (
                                                <tr>
                                                    <td>{el.row}</td>
                                                    <td>{el.data.produkti}</td>
                                                    <td>{el.data.kategoria}</td>
                                                    <td>{el.data.detajet}</td>
                                                    <td>{el.data.mepages}</td>
                                                    <td>{el.data.qty}</td>
                                                    <td>{el.data['njesija matese']}</td>
                                                    <td>{el.data['cmimi per qty']}</td>
                                                    <td>{el.data['data skadimit']}</td>
                                                    <td>{el.data.pickup}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </div>
                            }
                        </div>
                        <p className="validation-text">{storeValidation}</p>
                        <p className="validation-text">{validationMessage}</p>
                        {/*<span className="validation-text">{customDateInputValidation}</span>*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkData;
