import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi.scss';
import jwt_decode from 'jwt-decode';



const EditoShtetin = (props) => {
  const history = useHistory();
  const [stateData, addstateData] = useState({});

  const token = window.localStorage.getItem('cd');
  const decode = token ? jwt_decode(token) : null;


  const emri = e => {
    const ud = Object.assign({}, stateData);
    ud.name = e.target.value;
    addstateData(ud);
  }

  const kodi = e => {
    const ud = Object.assign({}, stateData);
    ud.code = e.target.value;
    addstateData(ud);
  }

  const telefoni = e => {
    const ud = Object.assign({}, stateData);
    ud.phone_code = e.target.value;
    addstateData(ud);
  }

  const editoShtetin = (e) => {
    e.preventDefault();
    axios.post('/admin/countries/update/' + props.match.params.id, stateData)
      .then((res) => {
        toast.success('Shteti u editua me sukses!');
        history.goBack();
        console.log(res.data);
      })
      .catch(err => {
        toast.error('Diçka shkoi gabim!');
        history.goBack();
        console.log(err);
      });


  }

  useEffect(() => {

    axios.get('/admin/countries/'+ props.match.params.id)
      .then((res) => {
        // console.log('produkti', res.data);
        addstateData(res.data);
      })
      .catch(err => {
          console.log(err);
        //   window.localStorage.removeItem('cd');
        //   window.location = "/kyqu";
        });

  }, [])



  return (
    <>
      <div className="shto_produkt">

            <div className="fjeshil-container">
              <div className="row">

                  <h3 className='w-100 p-3'> Perditeso Shtetin</h3>
                  {/* <h5 className='w-100 p-3'>{stateData.name}</h5> */}
                <div className="col-md-12">
                  <div className="shp_groupform">
                    <form onSubmit={editoShtetin}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Emri i Shtetit"
                        defaultValue={stateData.name}
                        onChange={emri}
                        required
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Kodi"
                        defaultValue={stateData.code}
                        onChange={kodi}
                        required
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prefixi Telefonik"
                        defaultValue={stateData.phone_code}
                        onChange={telefoni}
                      />
                      
              

                      <button type="submit">Perditeso </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  );
};

export default EditoShtetin;
