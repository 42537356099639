import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const ShtoQytet = (props) => {
    const history = useHistory();
    const [cityData, addcityData] = useState({});
    const [StateData, setStateData] = useState([]);

    // const [errors, addErrors] = useState(false);




    const emri = e => {
        console.log(e)
        const ud = Object.assign({}, cityData);
        ud.name = e.target.value
        addcityData(ud);
    }

    const shteti = e => {
        const ud = Object.assign({}, cityData);
        ud.country_id = e.value;
        addcityData(ud);
    }

    const editoQytetin = (e) => {
        e.preventDefault();

        axios.post('/admin/cities/update/'+ props.match.params.id, cityData)
            .then(res => {
                // console.log(res.data);
                // window.location = '/qytetet';
                toast.success('Qyteti u editua me sukses!');
                history.goBack();
            })
            .catch(err => {
                toast.error('Diçka shkoi gabim!');
                history.goBack();
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
            });
        // window.history.back();

    }

    useEffect(() => {
        axios.get('/admin/cities/'+ props.match.params.id)
        .then(res => {
            addcityData(res.data);
        })
        .catch(err => {
            // window.localStorage.removeItem('cd');
            // window.location = "/kyqu";
            // window.localStorage.removeItem('cd'); window.location = "/login";
            console.log(err);
        });
 
        axios.get('/admin/countries/all')
            .then((res) => {
                // console.log('axios', res.data);
                setStateData(res.data);
            })
            .catch(err => {
                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }, [])


    // console.log('produkt data', productData)

    let ar = '';
    let id = '';

    let shtetii = StateData?.map((el, i) => {
        if (el?._id == cityData?.country_id) {
            ar = el?.name;
            id = cityData?._id;
            console.log(ar);
        }
        return {value: el?._id, label: el?.name}
    });


    console.log('shteti',shtetii)


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Edito Qytetin</h3>
                            <div className="shp_groupform">
                                <form onSubmit={editoQytetin}>

                                        <label>
                                            <Select
                                                value={{label: ar}}
                                                onChange={shteti}
                                                placeholder="Selekto Shtetin"
                                                options={shtetii}
                                                disabeled={true}
                                                >
                                                    
                                            </Select>
                                        </label>

                                 
                                    <input type='text' className="form-control" placeholder='Emri'
                                           onChange={emri} defaultValue={cityData.name} required></input>
                                    
                                    <button type="submit">Edito</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoQytet;
