import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';
import ChartIcon from '../../images/chart-bar-regular.svg';

// CSS
import './Listimi.scss';

const Listimi = (props) => {


    console.log(props.data)


    return (
        <>
            <div className={'listimi-cat-produktet'}>
                <div className={'row '}>
                   {/* <div className={'col-3'}> {props.data.country_data[0]?.name}</div> */}
                   <div className={'col-3'}> <img src={process.env.REACT_APP_BACK_URL + props.data.pic_url} className='listimi-img' alt='category' /></div>
                   <div className={'col-6'}> <h5>{props.data.name}</h5> <br/> <p className={props.data.active ? 'text-success' : 'text-danger'}>{props.data.active ? 'Aktiv' : 'Pasiv'}</p></div>
                   <div className={'col-3 justify-content-end'}> 
                    <Link to={'/kategorite/produktet/stats/'+ props.data._id}>
                        <img src={ChartIcon} alt='stats' />
                    </Link>
                    <Link to={'/kategorite/produktet/edito/'+ props.data._id}>
                        <img src={EditIcon} alt='edit' className='ml-2' />
                    </Link>
                    <Link to={'/kategorite/produktet/fshij/'+ props.data._id}>
                        <img src={DeleteIcon} alt='delete' className='ml-2'/>
                    </Link>
                   </div>
                </div>
            </div>
          
        </>
    );
};

export default Listimi;
