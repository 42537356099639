import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const ShtoQytet = () => {

    const [cityData, addcityData] = useState({});
    const [StateData, setStateData] = useState([]);

    // const [errors, addErrors] = useState(false);
    
    const history = useHistory();


    const emri = e => {
        console.log(e)
        const ud = Object.assign({}, cityData);
        ud.name = e.target.value
        addcityData(ud);
    }

    const shteti = e => {
        const ud = Object.assign({}, cityData);
        ud.country_id = e.value;
        addcityData(ud);
    }

    const shtoQytetin = (e) => {
        e.preventDefault();

        axios.post('/admin/cities/add', cityData)
            .then(res => {
                // console.log(res.data);
                // window.location = '/qytetet';
                toast.success('Qyteti u shtua me sukses!');
                history.goBack();

            })
            .catch(err => {
            
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                history.goBack();
            });
        // window.history.back();

    }

    useEffect(() => {
 
        axios.get('/admin/countries/all')
            .then((res) => {
                // console.log('axios', res.data);
                setStateData(res.data);
            })
            .catch(err => {
                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }, [])


    // console.log('produkt data', productData)

    let shtetii = StateData?.map((el, i) => {
        return {value: el?._id, label: el?.name}
    })


    console.log('shteti',shtetii)


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Shto Qytetin</h3>
                            <div className="shp_groupform">
                                <form onSubmit={shtoQytetin}>

                                        <label>
                                            <Select
                                                onChange={shteti}
                                                placeholder="Selekto Shtetin"
                                                options={shtetii}>
                                            </Select>
                                        </label>

                                 
                                    <input type='text' className="form-control" placeholder='Emri'
                                           onChange={emri} required></input>
                                    
                                    <button type="submit">Shto</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoQytet;
