import axios from '../../axios';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const ListimiUserat = (props) => {

    const handleApprove = (e) => {
        e.preventDefault();
        axios.post('/admin/user-store/approve-user', {user_id: props.data.user_id})
            .then(res => {
                console.log(res);
                window.location = '/';
            })
            .catch(err => {
                window.localStorage.removeItem('cd');
                window.location = '/kycu';
                console.log(err)
            })
    }

    const handleDecline = (e) => {
        e.preventDefault();
        axios.post('/admin/user-store/disable-user', {user_id: props.data.user_id, store_id: props.data._id})
            .then(res => {
                console.log(res);
                window.location = '/';
            })
            .catch(err => console.log(err))
    }


    return (
        <div className="kerkesa_tereja">
            <div className="emri_produktit">
                <p className="produkt">{props.data.name}</p>
                <p className="person">{'User: ' + props.data.user_name + ' ' + props.data.user_surname}
                    <br/> {'Email: ' + props.data.user_email} <br/> {'Nr. Unik: ' + props.data.fiscal_nr} </p>
            </div>
            <div className="data mt-n3">
                <p className="data_text">Data</p>
                <p className="data_">{props.data.user_date?.slice(0, 10)}</p>
            </div>
            <hr/>
            <div className="prano_anulo">
                <button onClick={handleApprove} className='btn-success rounded'>Prano</button>
                <button onClick={handleDecline} className='btn-danger rounded ml-2'>Anulo</button>
            </div>
        </div>
    );
}

export default ListimiUserat;