import './ListView.scss'
import {Link} from "react-router-dom";
// import DeleteIcon from "../../../images/delete.svg";
// import EditIcon from "../../../images/edit.svg";
// import Reports from '../../../images/chart-bar-regular.svg';
import React from "react";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/delete.svg";
import Report from '../../../images/chart-bar-regular.svg'

const ListView = (props) => {

    console.log('', props)

    return (
        <>
            <tbody>
            <tr>
                <td className="col-4">{props.data.name} </td>
                <td className="col-2"> {props?.data?.active ? "Aktiv" : "Pasiv"}</td>
                <td className="col-3">
                    {
                        <img
                            src={process.env.REACT_APP_BACK_URL + (props.data.pic_url != null ? props.data.pic_url : props.data?.store_prod[0]?.pic_url)}
                            alt='produkti'
                            className="picture-location"
                        />
                    }
                </td>
                <td className="col-1">
                    {
                        <Link to={"/kategorite/produktet/stats/" + props.data._id}>
                            <img
                                alt="delete"
                                src={Report}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                <td className="col-1">
                    {
                        <Link to={"/kategorite/produktet/edito/" + props.data._id}>
                            <img
                                alt="delete"
                                src={EditIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                <td className="col-1">
                    {
                        <Link to={"/kategorite/produktet/fshij/" + props.data._id}>
                            <img
                                alt="delete"
                                src={DeleteIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                {/*<div className="col-1">*/}
                {/*    {*/}
                {/*        <Link to={"/singleproduct/" + props.data._id}>*/}
                {/*            <img*/}
                {/*                alt="delete"*/}
                {/*                src={Report}*/}
                {/*                className='actions'*/}
                {/*            />*/}
                {/*        </Link>*/}
                {/*    }*/}
                {/*</div>*/}
            </tr>
            </tbody>
        </>
    )
}

export default ListView;