import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../axios";
import './AdminEmails.scss';
import silhouette from "../../images/imagesss.jpg";


const AdminEmails = () => {

    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [newEmails, setNewEmails] = useState("");
    const [imageData, setimageData] = useState(null);

    const getEmails = () => {
        axios.get('/admin/admin-email')
            .then((data) => {
                console.log(data);
                setEmails(data.data);

                console.log('EMAILS EMAILS ', data)
            }).then(
            data => setIsLoading(true)
        )
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
            });
    }

    const handleEdit = () => {
        setIsEdit(!isEdit);
    }

    const imageDataChange = (e) => {
        e.preventDefault();
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        setimageData(ud);
        // console.log('imageData', imageData);
        // console.log('fajllimrena', imageData)
    }


    console.log('image image image', imageData?.image)
    console.log('image ', imageData)

    // console.log('image image image ', imageData)
    const handleChange = (e) => {
        e.preventDefault();
        const emailsss = e.target.value;
        console.log('emailat', emails);
        setNewEmails(emailsss);
    }

    const handleSave = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('email', newEmails);
        formData.append('slogan', e.target.slogani.value);
        formData.append('contact_email', e.target.contact_email.value);
        formData.append('contact_address', e.target.adresa.value);
        formData.append('facebook_url', e.target.facebook_url.value);
        formData.append('instagram_url', e.target.instagram_url.value);
        formData.append('linkedin_url', e.target.linkedin_url.value);
        formData.append('twitter_url', e.target.twitter_url.value);
        formData.append('phone', e.target.telefoni.value);
        formData.append('platform_url', e.target.platform_url.value);
        formData.append('organization_url', e.target.organization_url.value);
        formData.append('nrb', e.target.nrb.value);
        formData.append('image', imageData?.image);

        axios.post('/admin/admin-email/update', formData).then(data => {
            console.log(data);
            toast.success('Administrator emailat u përditësuan me sukses!');
            setIsEdit(false);
            getEmails();
        }).catch(err => {
            console.log(err);
            toast.error('Diçka shkoi gabim gjat ruajtjës të emailave!');
        })
    }

    useEffect(() => {
        getEmails();
    }, []);


    return (
        isLoading &&
        <div className='fjeshil-container p-5 mt-5'>
            <div className='admin-emails'>
                <div className='row'><h1>Emailat e Administratorëve</h1></div>
                <div className='row'><p>Emailat e meposhtëm përdoren për të informuar administratorët me emaila të
                    ndryshëm qe i dergon sistemi!.</p></div>
                <div className='emails'>
                    {
                        emails?.email.map((el, index) => {
                                return (
                                    <li key={index}>{el}  </li>
                                )
                            }
                        )
                    }
                </div>
                <button onClick={handleEdit}>{!isEdit && 'Edito'} {isEdit && 'Largohu'}</button>
                {isEdit &&
                <form onSubmit={handleSave}>
                    <textarea type='text'
                              defaultValue={emails.email}
                              name="email"
                              onChange={handleChange}
                              width='500px'
                    >
                    </textarea>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <img src={process.env.REACT_APP_BACK_URL + emails?.logo_url}
                                     className="user-image"
                                />
                                {/*<img*/}
                                {/*    src={imageData}*/}
                                {/*    onError={() => {*/}
                                {/*        setimageData(silhouette)*/}
                                {/*    }}/>*/}


                            </div>
                            <div className="col-md-12">
                                <label>Imazhi: </label>
                                <div className="input-group mb-3">
                                    <div className="custom-file">
                                        <input type="file"
                                               className="custom-file-input"
                                               id="inputGroupFile02"
                                               name="image"
                                            // defaultValue={imageData?.name}
                                               onChange={imageDataChange}
                                        />
                                        <label className="custom-file-label"
                                               htmlFor="inputGroupFile02">
                                            {imageData?.image?.name ? imageData?.image.name : 'Imazhi'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label>Kontakt Emaili: </label>
                                <input type='email'
                                       name="contact_email"
                                       defaultValue={emails?.contact_email}
                                       className="form-control input-mail"
                                       placeholder='Kontakt Email'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>Slogani: </label>
                                <input type='text'
                                       name="slogani"
                                       defaultValue={emails?.slogan}
                                       className="form-control input-mail"
                                       placeholder='Slogan'
                                       required
                                >
                                </input>
                            </div>

                            <div className="col-md-12">
                                <label>Kontakt Adresa: </label>
                                <input type='text'
                                       name="adresa"
                                       defaultValue={emails?.contact_address}
                                       className="form-control input-mail"
                                       placeholder='Kontakt Adresa'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>Numri i Telefonit: </label>
                                <input type='text'
                                       name="telefoni"
                                       defaultValue={emails?.phone}
                                       className="form-control input-mail"
                                       placeholder='Telefoni'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>URL i Facebook: </label>
                                <input type='text'
                                       name="facebook_url"
                                       defaultValue={emails?.facebook_url}
                                       className="form-control input-mail"
                                       placeholder='Facebook URL'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>URL i Instagram: </label>
                                <input type='text'
                                       name="instagram_url"
                                       defaultValue={emails?.instagram_url}
                                       className="form-control input-mail"
                                       placeholder='Instagram URL'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>URL i Twitter: </label>
                                <input type='text'
                                       name="twitter_url"
                                       defaultValue={emails?.twitter_url}
                                       className="form-control input-mail"
                                       placeholder='Twitter URL'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>URL i Linkedin: </label>
                                <input type='text'
                                       name="linkedin_url"
                                       defaultValue={emails?.linkedin_url}
                                       className="form-control input-mail"
                                       placeholder='Linkedin URL'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>URL i Platformes: </label>
                                <input type='text'
                                       name="platform_url"
                                       defaultValue={emails?.platform_url}
                                       className="form-control input-mail"
                                       placeholder='URL i Platformes'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>URL i Organizates: </label>
                                <input type='text'
                                       name="organization_url"
                                       defaultValue={emails?.organization_url}
                                       className="form-control input-mail"
                                       placeholder='URL i Organizates'
                                       required
                                >
                                </input>
                            </div>
                            <div className="col-md-12">
                                <label>Numri Bankar: </label>
                                <input type='text'
                                       name="nrb"
                                       defaultValue={emails?.nrb}
                                       className="form-control input-mail"
                                       placeholder='Numri Bankar'
                                       required
                                >
                                </input>
                            </div>
                        </div>
                    </div>
                    <button type='submit'>Ruaj</button>
                </form>
                }
            </div>
        </div>
    );
}
export default AdminEmails;