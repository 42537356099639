import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi.scss';
import jwt_decode from 'jwt-decode';



const ShtoKategoriTeProdukteve = (props) => {

  const [catProdData, addcatProdData] = useState({});
  const [imgData, addimgData] = useState({});
  const history = useHistory();
    const emri = e => {
    const ud = Object.assign({}, catProdData);
    ud.name = e.target.value;
    ud.active = true;
    addcatProdData(ud);
  }

  const image = e => {

    // e.preventDefault();
    const ud = Object.assign({}, catProdData);
    ud.image = e.target.files[0];
    addcatProdData(ud);
    // addimgData(e.target.files);
    
  }

  // const isActive = e => {
  //   const ud = Object.assign({}, catProdData);
  //   ud.active = e.target.value;
  //   addcatProdData(ud);
  // }

  const shtoKategori = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', catProdData.image);
    formData.append('name', catProdData.name);
    formData.append('active', catProdData.active);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      }
    }

    axios.post('/admin/product-categories/add', formData, config)
      .then((res) => {
        // window.location = `/kategorite/produktet`;
        console.log(res.data);
        toast.success('Kategoria u shtua me sukses!');
        history.goBack();
      })
      .catch(err => {

        console.log(err);
        toast.error('Diçka shkoi gabim!');
        history.goBack();
      });


  }

  useEffect(() => {

    // axios.get('/admin/countries/'+ props.match.params.id)
    //   .then((res) => {
    //     // console.log('produkti', res.data);
    //     addcatProdData(res.data);
    //   })
    //   .catch(err => {
    //       console.log(err);
    //     //   window.localStorage.removeItem('cd');
    //     //   window.location = "/kyqu";
    //     });

  }, [])

  console.log('imggg', imgData);

  return (
    <>
      <div className="shto_produkt">

            <div className="fjeshil-container">
              <div className="row">

                  <h3 className='w-100 p-3'> Shto Kategori</h3>
                  {/* <h5 className='w-100 p-3'>{catProdData.name}</h5> */}
                <div className="col-md-12">
                  <div className="shp_groupform">
                    <form onSubmit={shtoKategori}>

                    {/* <img src={`data:image/jpeg;base64,${data}`} /> */}

                    <input
                        type="file"
                        name='file'
                        className="form-control"
                        placeholder="picture"
                        onChange={image}
                        required
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Emri i Kategorisë"
                        // defaultValue={catProdData.name}
                        onChange={emri}
                        required
                      />
         
                      {/* <select className="form-control" defaultValue={true} onChange={isActive} required>
                          <option value={true} >Aktive</option>
                          <option value={false} >Passive</option>
                      </select>
                       */}
              

                      <button type="submit">Shto </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  );
};

export default ShtoKategoriTeProdukteve;
