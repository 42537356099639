import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './RegjistroBiznesin.scss';
import jwt_decode from "jwt-decode";
import axios from "../../axios";

const Form = (props) => {
    const [agree, setAgree] = useState(false);
    const token = window.localStorage.getItem("cd");
    const decode = token ? jwt_decode(token) : null;
    const history = useHistory();
    const [userData, setuserData] = useState([]);
    // const [isUserEdit, setisUserEdit] = useState(false);
    // const [imageData, setimageData] = useState(null);
    // const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    // const [isPasswordEdit, setisPasswordEdit] = useState(false);
    // const [passData, setpassData] = useState([]);
    // const [ageRange, setAgeRange] = useState([]);
    const [country, setCountry] = useState([]);
    const [city, setcity] = useState([]);
    const [categories, setCategories] = useState([]);
    const [passValidation, setPasswordValidation] = useState('');
    const [validationError, setValidationError] = useState('');


    const btnHandler = (props) => {
        ruajNdryshimet();
        // alert('Regjistrimi u krye me sukses!');
        console.log('data', userData);


    };


    // user data biznes handler
    const fjalkalimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        if (ud.pass.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$')) {
            setPasswordValidation('Fjalëkalimi i plotëson kushtet.')
            setuserData(ud);
        }
        else {
            setPasswordValidation('Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer.')
        }

    };
    //
    // const fjalkalimiKonfirmimi = (e) => {
    //     const ud = Object.assign({}, passData);
    //     ud.pass2 = e.target.value;
    //     if (ud.pass2.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$') && ud.pass === ud.pass2) {
    //         setPasswordValidation('Passwordi i ploteson kushtet dhe eshte i njejte')
    //         setpassData(ud);
    //     } else {
    //         setPasswordValidation('Fjalekalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer')
    //     }
    // };


    // const fjalkalimiKonfirmimi = (e) => {
    //     const ud = Object.assign({}, userData);
    //     ud.pass2 = e.target.value;
    //     setpassData(ud);
    // };

    const emri = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setuserData(ud);
    };

    const mbiemri = (e) => {
        const ud = Object.assign({}, userData);
        ud.surname = e.target.value;
        setuserData(ud);
    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setuserData(ud);
    };

    const telefoni = (e) => {
        // console.log('teleFOOOOni',e)
        const ud = Object.assign({}, userData);
        ud.store_phone = e.target.value;
        setuserData(ud);
    };

    // biznes data event handler
    const emriBiznesit = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.storename = e.target.value;
        setuserData(ud);
    }

    const numbriBiznesit = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.fiscal_nr = e.target.value;
        setuserData(ud);
    }

    const adresa = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.address = e.target.value;
        setuserData(ud);
    }

    const orariHapjes = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.opening_hour = e.target.value;
        setuserData(ud);
    }

    const orariMbylljes = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.closing_hour = e.target.value;
        setuserData(ud);
    }


    const description = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.store_description = e.target.value;
        setuserData(ud);
    }



    const shtetiBiznesit = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.country_id = e.target.value;
        setuserData(ud)
    }
    const qytetiBiznesit = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.city_id = e.target.value;
        setuserData(ud)
    }

    const kategoriaBiznesit = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.store_cat = e.target.value
        setuserData(ud);
    }


    const biznesTextArea = async (e) => {
        const ud = await Object.assign({}, userData);
        ud.text_area = e.target.value;
        setuserData(ud);
    }

    const checkboxHandler = async () => {
        setAgree(!agree);
        const ud = await Object.assign({}, userData);
        ud.terms_accept = true;
        setuserData(ud);
    }


    const getCountry = async () => {
        try {
            let country = await axios.get('/admin/countries/all');
            console.log(country);
            setCountry(country?.data);
        } catch (error) {
            console.log(error)
        }
    }
    const getCity = async () => {
        try {
            let city = await axios.get('/admin/cities/all');
            setcity(city?.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getCategories = async () => {
        try {
            let categories = await axios.get('/admin/store-categories/all');
            setCategories(categories?.data);
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getCountry();
        getCategories();
        getCity();
    }, [])


    //

    const ruajNdryshimet = async () => {
        // console.log('para se me shku', userData)
        await axios.post("/furnitor/register", userData)
            .then((res) => {
                console.log(res.data);
                // setisUserEdit(false);
                // window.location = "/menaxhodyqanet";
                toast.success('Biznesi u regjistrua me sukses!');
                history.goBack();
            })
            .catch((err) => {
                console.log('this shit',err.response.data)

                    setValidationError('Please fill all fields');
                    

            });
    }


    function numberOnly(event) {
        console.log(event.target.value)
        // Get element by id which passed as parameter within HTML element event
        if(event.target.value === 9) {
            return true;
        } else {
            return false;
        }
        // This removes any other character but numbers as entered by user
        // element.value = element.value.replace(/[^0-9]/gi, "");
    }



    return (
        <>
            <div className="regjistrohu_form">
                <form onSubmit={ruajNdryshimet}>
                    <div className="row w-100">
                        <div className="col-md-6 col-sm-12">
                            <p>
                                <b>Te dhënat e përdoruesit:</b>
                            </p>
                            <label className="name_surname">
                                <input
                                    name="name"
                                    onChange={emri}
                                    type="text"
                                    placeholder="Emri"
                                    className="select_emri"
                                />
                                <input
                                    name="surname"
                                    type="text"
                                    onChange={mbiemri}
                                    placeholder="Mbiemri"
                                    className="select_mbiemri"
                                />
                            </label>
                            <label>
                                <input
                                    name="email"
                                    type="email"
                                    onChange={email}
                                    placeholder="Email"
                                    className="rb_select"
                                />
                            </label>
                            <label>
                                <input
                                    name="store_phone"
                                    type="phone"
                                    onChange={telefoni}
                                    placeholder="Numri Tel"
                                    className="rb_select"
                                />
                            </label>
                            <label>
                                <input
                                    name="pass"
                                    pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                    type="password"
                                    onChange={fjalkalimi}
                                    placeholder="Fjalëkalimi"
                                    className="rb_select"
                                />
                            </label>
                            <p className="text-password">{passValidation}</p>
                        </div>
                        <div className="col-md-6  col-sm-12 form_right">
                            <p>
                                <b>Te dhënat e biznesit:</b>
                            </p>
                            <label>
                                <input
                                    name="storename"
                                    type="text"
                                    onChange={emriBiznesit}
                                    placeholder=" Emri i Biznesit"
                                    className="rb_select"
                                />
                            </label>
                            <label>
                                <input
                                    name="fiscal_nr"
                                    type="number"
                                    pattern="/^-?\d+\.?\d*$/"
                                    maxLength="9"
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 9)}
                                    onChange={numbriBiznesit}
                                    placeholder="Numri Unik Identifikues (NUI)"
                                    className="rb_select"
                                />
                                <span className="fiscal-nr">Numri Unik Identifikues (NUI) mund te shkruhet vetem maximum 9 numra.</span>

                            </label>

                            <label>
                                <select
                                    name="country_id"
                                    type="text"
                                    onChange={shtetiBiznesit}
                                    placeholder="Shteti"
                                    className="rb_select"
                                >
                                    <option disabled selected hidden>
                                        Shteti
                                    </option>
                                    {
                                        country?.map((el, i) => {
                                            return (
                                                <option value={el?._id} key={el?._id}>{el?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </label>
                            <label>
                                <select
                                    name="country_id"
                                    type="text"
                                    onChange={qytetiBiznesit}
                                    placeholder="Qyteti"
                                    className="rb_select"
                                >
                                    <option disabled selected hidden>
                                        Qyteti
                                    </option>
                                    {
                                        city?.map((el, i) => {
                                            return (
                                                <option value={el?._id} key={el?._id}>{el?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </label>
                            <label>
                                <select className="rb_select"
                                        name="store_cat"
                                        onChange={kategoriaBiznesit}
                                >
                                    <option disabled selected hidden>
                                        Kategoria e Biznesit
                                    </option>
                                    {
                                        categories?.map((el, i) => {
                                            return (
                                                <option value={el?._id} key={el?._id}>
                                                    {el?.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </label>
                            <label>
                                <input
                                    name="address"
                                    type="text"
                                    onChange={adresa}
                                    placeholder="Adresa"
                                    className="rb_select"
                                />
                            </label>
                            <label><b>Orari i Punes</b></label>
                            <label className="name_surname">
                                <input type="number"
                                       className="select_emri"
                                       placeholder="Ora e Fillimit"
                                       name="opening_hour"
                                       onChange={orariHapjes}
                                       min="0"
                                       max="23"
                                />
                                <input type="number"
                                       className="select_mbiemri"
                                       placeholder="Ora e Mbarimit"
                                       onChange={orariMbylljes}
                                       name="closing_hour"
                                       min="0"
                                       max="23"
                                />
                            </label>
                            <label>
                                <textarea
                                    name="store_description"
                                    type="text"
                                    onChange={description}
                                    placeholder="Përshkrimi"
                                    className="rb_select"
                                />
                            </label>
                        </div>
                        <p className="validation-field">{validationError}</p>
                        <div className="col-md-12 terms_condition">
                            <div className="check_">
                                <input
                                    type="checkbox"
                                    id="agree"
                                    onChange={checkboxHandler}
                                />
                                <label htmlFor="agree" id="kushtet">
                                    Pajtohem me <b>termat dhe kushtet</b>
                                </label>
                            </div>
                            <Link to="/shtobiznes">
                                <button
                                    disabled={!agree}
                                    className="btn"
                                    onClick={btnHandler}
                                >
                                    Vazhdo
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Form;
