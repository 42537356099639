import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
// import './ShtoProdukt.scss';
// import jwt_decode from 'jwt-decode';



const FshijProdukt = (props) => {


    const [storeData, setStoreData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const history = useHistory();


    const fshijStore = (e) => {
        e.preventDefault();

        axios.delete('/admin/user-store/' + props.match.params.id  , storeData)
            .then((res) => {
                toast.success('Biznesi u fshi me sukses!');
                history.push(`/menaxhodyqanet`);
                console.log(res.data);
            })
            .catch(err => {
                toast.error('Diqka shkoi gabim!');
                history.goBack();
                // window.localStorage.removeItem('cd')
                // window.location = "/login";
                console.log(err);
            });


    }

    useEffect(() => {
        //
        // axios.get('/admin/user-store/all')
        //     .then((res) => {
        //         console.log('axios', res);
        //         setCategoriesData(res.data);
        //     })
        //     .catch( err => {
        //         console.log(err);
        //         window.localStorage.removeItem('cd');
        //         window.location = "/kyqu";
        //     });


        axios.get('/admin/user-store/'+ props.match.params.id)
            .then((res) => {
                console.log('produkti', res.data);
                setStoreData(res.data);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
            });

    }, [props.match.params.id])


    console.log('store store',storeData)


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container">
                    <div className="row">
                        <h6 className='p-3 w-100'>Konfirmo fshirjen e dyqanit në vijim:</h6>
                        {/* <h5 className='w-100 p-3'>{productData.name}</h5> */}
                        <div className="col-md-6">
                            <div className="shp_groupform">
                                <form onSubmit={fshijStore}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        defaultValue={storeData.name}
                                        disabled
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        defaultValue={storeData.fiscal_nr}
                                        disabled
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        defaultValue={storeData.address}
                                        disabled
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        defaultValue={storeData.phone}
                                        disabled
                                    />
                                    <label>
                        <textarea
                            className="rb_textarea"
                            rows={3}
                            cols={60}
                            placeholder="Përshkrimi..."
                            defaultValue={storeData.description}
                            disabled
                        ></textarea>
                                    </label>

                                    <button type="submit">Fshij </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default FshijProdukt;
