import './ListView.scss'
import {Link} from "react-router-dom";
// import DeleteIcon from "../../../images/delete.svg";
// import EditIcon from "../../../images/edit.svg";
// import Reports from '../../../images/chart-bar-regular.svg';
import React from "react";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/delete.svg";
import Report from '../../../images/chart-bar-regular.svg'

const ListView = (props) => {

    console.log('', props)

    console.log('hehe', props.data?.role_id);

    let roleName = ''
    if (props.data.role_id == 1) {
        roleName = "Superadmin"
    } else if (props.data.role_id == 2) {
        roleName = "Administrator"
    } else if (props.data?.role_id == 3) {
        roleName = "Furnitor"
    } else {
        roleName = "Mobil"
    }

    return (
        <>

            <tbody>
            <tr>
                <td className="col-3 text-break">{props.data.name} {props?.data?.surname} </td>
                {/*<div className="col-2"> </div>*/}
                <td className="col-1 text-break"> {props?.data?.active ? "Aktiv" : "Pasiv"}</td>
                <td className="col-2"> {roleName}</td>
                <td className="col-3 text-break"> {props.data.email}</td>
                <td className='col-1 text-break'>{props?.data?.emailVerification ? 'po' : 'jo'}</td>
                {/*<div className="col-1"> {props?.data.phone}</div>*/}
                <td className="col-1">
                    {
                        <Link to={"/edito-shfrytezuesin/" + props.data._id}>
                            <img
                                alt="delete"
                                src={EditIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                <td className="col-1">
                    {
                        <Link to={"/fshij-shfrytezuesin/" + props.data._id}>
                            <img
                                alt="delete"
                                src={DeleteIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                {/*<div className="col-1">*/}
                {/*    {*/}
                {/*        <Link to={"/singleproduct/" + props.data._id}>*/}
                {/*            <img*/}
                {/*                alt="delete"*/}
                {/*                src={Report}*/}
                {/*                className='actions'*/}
                {/*            />*/}
                {/*        </Link>*/}
                {/*    }*/}
                {/*</div>*/}
            </tr>
            </tbody>
        </>
    )
}

export default ListView;