import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';
import Category from '../../images/category.png';

// CSS
import './Transactions.scss';
import jwt_decode from "jwt-decode";

const ListimiTransakcionet = (props) => {

    const [errMsg, setErrMsg] = useState('')

    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;

    console.log(props?.data)


    // console.log(props.data.store_prod[0].name)

    // console.log('data incoming', props.data.url)

    console.log('error mesazhi error mesazhi', errMsg)

    console.log(props.data)

    return (
        <>
            <tbody>
            <tr>
                <td className="col-3 text-break"> {props?.data.user_email}</td>
                <td className="col-2 text-break"> {props?.data.product_name}</td>
                <td className="col-1"> {props?.data.qty}</td>
                <td className="col-2">{props?.data.total_price + ' EUR'}</td>
                <td className="col-1">
                    {props?.data.type === 'Free' ? 'Falas'
                        : props?.data.type === 'Paid' ? 'Paguar' : ''
                    }
                </td>
                <td className="col-2 text-break"> {props?.data.store_name}</td>
                <td>{props?.data.status === 'expired' ? 'Skaduar' : props.data.status === 'declined' ? 'Refuzuar' : props.data.status === 'approved' ? 'Miratuar' : ''}</td>
            </tr>
            </tbody>
        </>
    );
};

export default ListimiTransakcionet;
