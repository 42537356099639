import './MenaxhoStokun.scss';
import React from 'react';
import {Link} from 'react-router-dom';
 
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';


const ListoStokun = (props) => {

    return (
        <>
            <div className="stok_produktet p-3">
                <div className="produkt_title" >
                    <div className='row' >
                        <div className='col-9'> <h5>{props.data?.prodData[0]?.name}</h5></div>
                        <div className='col-1 pt-3 text-right'><Link to={'/editostokun/'+props.data._id} > <img src={EditIcon} alt="edit" /> </Link></div>
                        <div className='col-1 pt-3 text-right'><Link to={'/fshijstokun/'+props.data._id}> <img src={DeleteIcon} alt="delete"  /> </Link></div>
                    
                    </div>
                    <div className='row pl-5'>Shitur nga: <b> {" " + props.data.storeData.name}</b></div>
                    {/* <p>{props.data?.prodData[0]?.name}</p>
                   <Link to={'/editostokun/'+props.data._id} > <img src={EditIcon} alt="edit" /> </Link>
                   <Link to={'/fshij stokun'}> <img src={DeleteIcon} alt="delete"  /> </Link> */}
                {/* <div className='row '>
                    <div className='col-3 sasia'>Sasia <br /> <b>{props.data.qty}</b></div>
                    <div className='col-4 data'> Cmimi <br /><b> {props.data.price} EUR</b></div>
                    <div className='col-5 data'>Data Skadimit <br /> <b>{props.data.expiration_date.slice(0, 10)}</b></div>
                </div>
                 */}
                </div>
                
                <div className="produkt_description">
                    <p>Sasia</p>
                    <p>Çmimi</p>
                    <p>Data e Skadimit</p>
                </div>
                <div className="produkt_values">
                    <p className="sasia">{props.data.qty}</p>
                    <p className="data">{props.data.price} EUR</p>
                    <p>{props.data.expiration_date.slice(0, 10)}</p>
                </div>
            </div>
        </>
    );
};

export default ListoStokun;
