import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';


// CSS
import './Listimi.scss';

const Listimi = (props) => {


    console.log(props.data)


    return (
        <>
            <div className={'listimi-bexhet'}>
                <div className={'row w-100'}>
                    <div className={'col-2'}>            <img className="badge-img" src={ process.env.REACT_APP_BACK_URL + props.data.pic_url }/></div>
                   <div className={'col-6'}>{props.data.name} , {props.data.value}</div>
                   <div className={'col-3 justify-content-end '}>
                    <Link to={'/bexhi/edito/'+ props.data._id}>
                        <img src={EditIcon} alt='edit' />
                    </Link>
                    <Link to={'/bexhi/fshij/'+ props.data._id}>
                        <img src={DeleteIcon} alt='delete' className='ml-3'/>
                    </Link>
                   </div>
                </div>
            </div>
          
        </>
    );
};

export default Listimi;
