import React from 'react';
import {FaExpand} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {SidebarData} from "./SidebarData"
import SubNav from "./SubNav"
import jwt_decode from 'jwt-decode';
// CSS
import './Sidebar.scss';

const token = window.localStorage.getItem('cd');
const role = token ? jwt_decode(token).role_id : null;

const Sidebar = () => {

    const signout = (e) => {
        e.preventDefault();
        window.localStorage.removeItem('cd');
        window.location = "/kycu";
    }

    return (
        <>
            <div className="biznes_sidebar">
                <div className="biznes_menu">
                    <ul>
                        <li>
                            {SidebarData.map((item, index) => {
                                if (role == 2 && item.title == 'Administrimi') {
                                    return null
                                } else
                                    return <SubNav item={item} key={index} className={item.cName}/>;
                                // console.log('')
                            })}
                        </li>
                        <li className={'shkyqu'}>
                            <FaExpand value={{color: "#00000", className: "global-class-name"}}/>
                            <a onClick={signout}>
                            <span>
                            Shkyçu
                             </span>
                            </a>
                        </li>
                    </ul>
                    {/* <ul>
           {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      <span><FaExpand />{' '}</span>
                      {item.title}
                    </Link>
                  </li>
                );
              })}
          </ul> */}
                </div>
                {/* <div className="signout">
               
                   
                </div> */}
            </div>
        </>
    );
};

export default Sidebar;
