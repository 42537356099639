import React, {useState, useEffect} from 'react';

import axios from '../../axios';
import Select from "react-select";
import {Line, Bar} from 'react-chartjs-2';

// CSS
import './ShtoProdukt.scss';
import jwt_decode from 'jwt-decode';


const StatsProdukt = (props) => {

    const [stats, setStats] = useState([]);
    const [total, setTotal] = useState({});
    const [notifyMsg, setnotifyMsg] = useState('');

    const renderMsg = (msg) => {
        return (
            <div id='notifyMsg' className='notify-msg-bad'>{msg}
                <button onClick={handleMsgClose}><i className='fa fa-close'></i></button>
            </div>
        );
    }

    const handleMsgClose = (e) => {
        e.preventDefault();
        setnotifyMsg('');
    }

    useEffect(() => {
        axios.get('/admin/statistics/single-product/transactions/' + props.match.params.id)
            .then((data) => {
                console.log(data.data)
                setStats(data.data);
            })
            .catch(err => {
                window.localStorage.removeItem('cd');
                window.location = '/kycu'
                console.log(err)
            });
        axios.get('/admin/statistics/single-product/' + props.match.params.id)
            .then((data) => {
                console.log('single', data.data)
                setTotal(data.data);
            })
            .catch((err) => {
                console.log(err);
                setnotifyMsg('Produkti nuk është shtuar në stok ende!');
            });
    }, [])

    const palidhje = stats.map((el) => el.total_price,);
    const emnat = stats.map((el) => el.order_date?.slice(0, 10) + ' ' + el.order_date?.slice(11, 19) + ' QTY:' + el.qty,);
    console.log({palidhje})

    const chartData = {
        labels: emnat,
        datasets: [
            {
                label: 'Shuma e Tranzakcionit',
                data: palidhje,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (
        <>
            <div className="shto_produkt">
                {notifyMsg != '' && renderMsg(notifyMsg)}
                <div className="fjeshil-container">
                    <div className="row ">
                        <h3 className='w-100 p-3'> Raportet e Produktit <b>{' ' + total.name}</b></h3>

                        <div className="row w-100 pt-5 pl-5">
                            <div className='col-12 col-md-4 ml-4 mb-2'>
                                <div className='card p-4'>Shuma Totale e Shitur <br/>{'EUR ' + total.total_price}</div>
                            </div>
                            <div className='col-12 col-md-4 ml-4 mb-2'>
                                <div className='card p-4'>Sasia Totale e Shitur <br/>{total.qty}</div>
                            </div>
                        </div>
                        <br/>
                        <div className="col-md-12 p-5">
                            <Bar data={chartData} options={options}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatsProdukt;
