import React from 'react';

// Components
// import Menu from '../Menu/Menu';
import Form from './Form';
import Footer from '../../components/Footer/Footer';

// images
import Logo from '../../images/logo.png';

// CSS
import './RegjistroBiznesin.scss';

const RegjistroBiznesin = () => {

    return (
        <>
            <div className="regjistro_biznesin">
                <div className="fjeshil-container">
                    <div className="row w-100">
                        <div className="col-md-12">
                            <div className="title pl-4">
                                <h1>Regjistro Biznesin</h1>
                            </div>
                            <div className="regjistro_form">
                                <Form/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
            {/*<Footer />*/}
        </>
    );
};

export default RegjistroBiznesin;
