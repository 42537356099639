import React, {useState, useEffect} from 'react';
import axios from '../../axios';

// CSS
import './Dashbordi.scss';
import TeRealizuara from "./TeRealizuara/TeRealizuara";
import TeSkaduara from "./TeSkaduara/TeSkaduara";
import TeReja from './TeReja/TeReja';

const Dashbordi = () => {
    // const [active, setActive] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('new');
    // new/ old/ donetotalAmmount, setTotalAmount
    const [totalAmmount, setTotalAmount] = useState([]);
    const [transactionNr, setTransactionNr] = useState([]);
    const [totalPending, setTotalPending] = useState([]);
    const [donationTotal, setDonationTotal] = useState('')
    let color1 = ''
    let color2 = ''
    let color3 = ''

    if (componentToBeRendered === 'old') {
        color1 = '#000';
    } else {
        color1 = '#bbc5d5';
    }
    //
    if (componentToBeRendered === 'new') {
        color3 = '#000';
    } else {
        color3 = '#bbc5d5';
    }

    if (componentToBeRendered === 'done') {
        color2 = '#000';
    } else {
        color2 = '#bbc5d5';
    }

    useEffect(()=>{
        axios.get('/admin/stats/donationtotal')
            .then((data)=> {
                setDonationTotal(data.data);
                console.log('sold-amount',data.data)
            })
            .catch(err => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                // console.log(err)
            });
        axios.get('/admin/statistics/total-amount')
             .then((data)=> {
                 setTotalAmount(data.data);
                 console.log('sold-amount',data.data)
                })
             .catch(err => {
                 window.localStorage.removeItem('cd');
                 window.location = "/kyqu";
                 console.log(err)
             });
        axios.get('/admin/statistics/total-count')
             .then((data)=> {
                 setTransactionNr(data.data);
                 console.log('transaction number',data.data)
                })
             .catch(err => {
                 window.localStorage.removeItem('cd');
                 window.location = "/kyqu";
                 console.log(err)
             });
        axios.get('/admin/statistics/total-pending')
             .then((data)=> {
                 setTotalPending(data.data);
                 console.log('totalPending',data.data)
                })
             .catch(err => {
                 window.localStorage.removeItem('cd');
                 window.location = "/kyqu";
                 console.log(err)
             });
    
    },[])



    return (
        <>
            <div className="dashbordi">
                <div className="row mt-5">
                    <div className="col-md-9 dashboard">
                        <div className="container-fluid">
                            <div className="row ">
                                <div className="col-md-12 col-12">
                                    <div className="dashboard_title">
                                        <h1>Paneli i Kontrollit</h1>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="charts">
                                        <div className="teardhurat_total mb-2">
                                            <p>Të ardhurat totale</p>
                                            <hr/>
                                            <p className="price">EUR {(totalAmmount[0]?.total_price?.slice(0,10))}</p>
                                        </div>
                                        <div className="porosite mb-2">
                                            <p>Të Gjitha porositë</p>
                                            <hr/>
                                            <p className="price"> {transactionNr[0]?.total_count} </p>
                                        </div>
                                        <div className="porosite mb-2">
                                            <p>Në pritje</p>
                                            <hr/>
                                            <p className="price">{ totalPending[0]?.total_count}</p>
                                        </div>
                                        <div className="porosite mb-2">
                                            <p>Total Donacione</p>
                                            <hr/>
                                            <p className="price">{donationTotal[0]?.donation_price}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 kerkesat">
                                    <div className="submenu">
                                        <p onClick={() => setComponentToBeRendered('new')} style={{color: `${color3}`}}
                                           className="te_reja">Aprovo Shfrytëzuesit e Ri</p>
                                        {/*<p onClick={() => setComponentToBeRendered('old')} style={{color: `${color1}`}}*/}
                                        {/*   className="te_skaduara">Te*/}
                                        {/*    Skaduara</p>*/}
                                        {/*<p onClick={() => setComponentToBeRendered('done')} style={{color: `${color2}`}}*/}
                                        {/*   className="te_realizuara">Te*/}
                                        {/*    realizuarat</p>*/}
                                        <hr />
                                    </div>
                                    {
                                        componentToBeRendered === 'new' ? <TeReja/> : ''
                                    }
                                    {
                                        componentToBeRendered === 'old' ? <TeSkaduara/> : ''
                                    }
                                    {
                                        componentToBeRendered === 'done' ? <TeRealizuara /> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashbordi;
