import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Listimi';
import Select from 'react-select';


const EditoBexhin = (props) => {

    const [badgeData, addbadgeData] = useState({});
    const history = useHistory();
    const emri = e => {
        console.log(e)
        const ud = Object.assign({}, badgeData);
        ud.name = e.target.value
        addbadgeData(ud);
    }

    const vlera = e => {
        const ud = Object.assign({}, badgeData);
        ud.value = e.target.value;
        addbadgeData(ud);
    }

    const editoGrupmoshen = async (e) => {
        e.preventDefault();
        let formData = new FormData();

        formData.append('name', e.target.emri.value);
        formData.append('value', e.target.vlera.value);
        formData.append('image', e.target.pic_url.files[0]);
        formData.append('description_on', e.target.description_on.value)
        formData.append('description_off', e.target.description_off.value)
        // console.log(formData);

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        await axios.post('/admin/badges/update/' + props.match.params.id, formData)
            .then(res => {
                // console.log(res.data);
                // window.location = '/bexhi';
                toast.success('Bexhi u editua me sukses!');
                history.goBack();
            })
            .catch(err => {
              
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                history.goBack();
            });
        // window.history.back();
    }

    useEffect(() => {
        axios.get('/admin/badges/' + props.match.params.id)
            .then(res => {
                addbadgeData(res.data);
            })
            .catch(err => {
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
                // window.localStorage.removeItem('cd'); window.location = "/login";
                console.log(err);
            });

    }, [])


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-2">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className='mb-5'>Edito Bexhin</h3>
                            <div className="shp_groupform">
                                <form onSubmit={editoGrupmoshen}>

                                    <input type='text' className="form-control" placeholder='Emri' onChange={emri}
                                           name="emri" defaultValue={badgeData.name} required></input>
                                    <input type='number' className="form-control" placeholder='Vlera' onChange={vlera}
                                           name="vlera" defaultValue={badgeData.value} required></input>
                                    <input type='file' className="form-control" placeholder='vlera'
                                           name="pic_url"></input>
                                    <textarea type='text' className="form-control" defaultValue={badgeData?.description_on} style={{marginBottom: '10px'}}  placeholder='Përshkimi-on' name="description_on" />
                                    <textarea type='text' className="form-control" defaultValue={badgeData?.description_off} placeholder='Përshkimi-off' name="description_off" />
                                    <button type="submit">Edito</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditoBexhin;
