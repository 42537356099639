import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {IconContext} from 'react-icons';
import {FaExpand} from 'react-icons/fa';
import jwt_decode from 'jwt-decode';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import './Sidebar.scss';
import {isMobileDevice} from "react-select/dist/index-fe3694ff.cjs.dev";
import MobileMenu from "./mobilemenu";

const token = window.localStorage.getItem('cd');
const role = token ? jwt_decode(token).role_id : null;
// CSS

const SubNav = ({item, setopen, open, click}) => {

    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);
    const setOpen = () => setopen(!open)

    return (
        <>
            <Link to={item.path} onClick={showSubnav}>
                <div className={item.cName}>
                    <Link to={item.path}>
                         <span>
                             <FaExpand value={{color: "#00000", className: "global-class-name"}}/>
                             </span>
                        {item.title}
                    </Link>
                    <div className="subnav">
                        {item?.subNav && subnav
                            ? item?.iconOpened
                            : item?.subNav
                                ? item?.iconClosed
                                : null}
                    </div>
                </div>
            </Link>
            {
                window.innerWidth <= 600 ? subnav && role == 1 &&
                    item?.subNav?.map((item, index) => {
                        return (
                            <div onClick={setOpen} className="dropdown">
                                <Link to={item?.path} key={index}>
                                    {item?.title}
                                </Link>
                            </div>
                        );
                    }) : null
            }
            {
                window.innerWidth >= 700 ? subnav && role == 1 &&
                    item?.subNav?.map((item, index) => {
                        return (
                            <div className="dropdown">
                                <Link to={item?.path} key={index}>
                                    {item?.title}
                                </Link>
                            </div>
                        );
                    }) : null
            }
        </>
    )
}

export default SubNav
