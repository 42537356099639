import React, {useState, useEffect, Fragment} from "react";
import jwt_decode from "jwt-decode";
import axios from "../../axios";
import "./BiznesFaqja.scss";
import {getNodeText} from "@testing-library/dom";
import {MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents} from 'react-leaflet'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router-dom";

const BiznesFaqja = (props) => {
    const [shopData, setshopData] = useState([]);
    const [stateData, setstateData] = useState([]);
    const [cityData, setcityData] = useState([]);
    const [catData, setcatData] = useState([]);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isShopEdit, setisShopEdit] = useState(false);
    const [mapState, setMapState] = useState([0, 0]);
    const [center, setCenter] = useState([0, 0]);
    const [located, setLocated] = useState(false);


    let map;

    const token = window.localStorage.getItem("cd");
    const decode = token ? jwt_decode(token) : null;

    const getStore = () => {
        axios
            .get("/admin/user-store/" + props.match.params.id)
            .then((res) => {
                // console.log('userData', res.data);
                let newShop = res.data;
                newShop.store_description = res.data.description;
                newShop.store_phone = res.data.phone;
                setshopData(newShop);
            })
            .catch((err) => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
            });
    }

    const emri = (e) => {
        const ud = Object.assign({}, shopData);
        ud.name = e.target.value;
        setshopData(ud);
    };
    const nrunik = (e) => {
        const ud = Object.assign({}, shopData);
        ud.fiscal_nr = e.target.value;
        setshopData(ud);
    };
    const shteti = (e) => {
        const ud = Object.assign({}, shopData);
        ud.country_id = e.target.value;
        setshopData(ud);
    };
    const qyteti = (e) => {
        const ud = Object.assign({}, shopData);
        ud.city_id = e.target.value;
        setshopData(ud);
    };
    const kategoria = (e) => {
        const ud = Object.assign({}, shopData);
        ud.store_cat = e.target.value;
        setshopData(ud);
    };
    const latitude = (e) => {
        const ud = Object.assign({}, shopData);
        ud.latitude = e.target.value;
        setshopData(ud);
    };
    const longitude = (e) => {
        const ud = Object.assign({}, shopData);
        ud.longitude = e.target.value;
        setshopData(ud);
    };


    const adresa = (e) => {
        const ud = Object.assign({}, shopData);
        ud.address = e.target.value;
        setshopData(ud);
    }

    const telefoni = (e) => {
        const ud = Object.assign({}, shopData);
        ud.store_phone = e.target.value;
        ud.phone = e.target.value;
        setshopData(ud);
    }

    const orariHapjes = (e) => {
        const ud = Object.assign({}, shopData);
        ud.opening_hour = e.target.value;
        setshopData(ud);
    }

    const orariMbylljes = (e) => {
        const ud = Object.assign({}, shopData);
        ud.closing_hour = e.target.value;
        setshopData(ud);
    }


    const description = (e) => {
        const ud = Object.assign({}, shopData);
        ud.description = e.target.value;
        ud.store_description = e.target.value;
        setshopData(ud);
    }


    const imageDataChange = (e) => {
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        setimageData(ud);
        console.log('fajllimrena', imageData)
    }

    const ruajImazhin = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', imageData.image)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            }
        }
        axios.post("/admin/user-store/update-storeimage/" + props.match.params.id, formData, config)
            .then((res) => {
                console.log(res.data);
                toast.success('Imazhi u ndryshua me sukses!');
                getStore();
                setndryshoImazhin(false);
                // console.log('image',formData)
            })
            .catch((err) => {
                toast.error('Diçka shkoi gabim gjat ruajtjës të imazhit!');
                console.log(err)
                setndryshoImazhin(false);
            });
        // getStore();
        // window.location.reload();
    }

    const ruajNdryshimet = (e) => {
        e.preventDefault();
        console.log("modifikimet e dyqanit", shopData);

        axios.post("/admin/user-store/update/" + props.match.params.id, shopData)
            .then((res) => {
                console.log(res.data);
                toast.success('Ndryshimet u ruajtën me sukses!');
                setisShopEdit(false);
           
            })
            .catch((err) => {
                toast.error('Diçka shkoi gabim!');
                console.log(err);
                setisShopEdit(false);
            });
    };

    const ndryshoDyqanin = (e) => {
        e.preventDefault();
        let userEdit = isShopEdit;

        if (userEdit === true) {
            setisShopEdit(false);
        } else {
            setisShopEdit(true);
        }
    };

    const imazhi = (e) => {
        e.preventDefault();
        let imazhiEdit = ndryshoImazhin;
        if (imazhiEdit) {
            setndryshoImazhin(false);
        } else {
            setndryshoImazhin(true);
        }
    }


    const latLongFunc = async (e) => {
        const ud = Object.assign({}, shopData);
        ud.latitude = e[0];
        ud.longitude = e[1];
        // console.log('inside latitude',ud)
        await setshopData(ud);
    }

    // console.log('ZHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHZZZZZZZZZZZZZZZZZZZZZZZZZ shit ',shopData)


    function MyComponent() {
        // useEffect(()=>{
        //     map.locate();
        // },[])
        map = useMapEvents({
            // whenReady: () => {
            //     console.log('loaded')
            //     locationCenter();
            // },
            click: async (e) => {
                let i = await map.mouseEventToLatLng(e.originalEvent)
                await setMapState([Number(i.lat), Number(i.lng)])
                console.log(map)
                await latLongFunc([Number(i.lat), Number(i.lng)]);
                // await latitude(i.lat);
                //  latitude(i.lat).then(()=>longitude(i.lng));
                // setTimeout(()=>latitude(i.lat), 105);
                // console.log('shop data inside click', shopData)
                // map.locate();

            },
            locationfound: async (location) => {
                console.log(location)
                console.log('location found:', [location.latlng.lat, location.latlng.lng]);
                //
                // if (mapState[0] === 0 && mapState[1] === 0) {
                //     setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                //     setLocated(true);
                //     await map.flyTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                //         animate: false
                //     })]);
                //     await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);
                //
                // }

                if (shopData.longitude === 0 && shopData.latitude === 0) {
                    latLongFunc([location.latlng.lat, location.latlng.lng])
                    await map.panTo([Number(shopData?.latitude), Number(shopData?.longitude, {
                        animate: false,
                    })]);
                    await setCenter([Number(shopData?.latitude), Number(shopData?.longitude)]);
                }

                if (shopData != '') {
                    // console.log('po hin te shopi', ([Number(shopData?.latitude), Number(location?.longitude)]));
                    // console.log('centraaaa', [Number(shopData?.latitude), Number(location?.longitude)]);
                    // using flyTo instead of panTo, it has some animation in it
                    await map.panTo([Number(shopData?.latitude), Number(shopData?.longitude, {
                        animate: false,
                    })]);
                    await setCenter([Number(shopData?.latitude), Number(shopData?.longitude)]);
                } else {
                    // console.log('po hin te location defauklt')
                    // using flyTo instead of panTo, it has some animation in it
                    await map.panTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                        animate: false
                    })]);
                    await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);
                }
                // );
                // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
                setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                setLocated(true);
                map.stopLocate();
                // console.log(mapState)
            },
        })
        if (located == false) {
            map.locate();
        }
        return null
    }

    console.log('map state a je ka di', mapState)


    useEffect(() => {
        // const token = window.localStorage.getItem("cd");
        // const decode = token ? jwt_decode(token) : null;
        getStore();
        axios
            .get("/general/countries/all")
            .then((res) => {
                // console.log('userData', res.data);
                setstateData(res.data);
            })
            .catch((err) => {
                console.log(err);
                
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            });
        axios
            .get("/general/cities/all")
            .then((res) => {
                setcityData(res.data);
            })
            .catch((err) => {
                // window.localStorage.removeItem('cd')
                // window.location = '/kyqu';
                // toast.error('Authentikimi ju ka skaduar!');
                // <Redirect exact to="/kyqu"/>
                console.log(err);
            });
        axios
            .get("/general/store-categories/all")
            .then((res) => {
                setcatData(res.data);
            })
            .catch((err) => {
                console.log(err);
                // window.localStorage.removeItem('cd');
                // window.location = '/kyqu';
            });


    }, []);


    return (
        <>
            <div className="biznis-faqja">
                <div className="fjeshil-container">
                    <div className="row pl-3">
                        <div className="col-md-12">
                            <div className='store-image'>
                                {shopData.pic_url != null ?
                                    <img src={process.env.REACT_APP_BACK_URL + shopData.pic_url} alt='Shop'/> :
                                    catData.map((el) => {
                                        if (el._id == shopData.store_cat) {
                                            return <img src={process.env.REACT_APP_BACK_URL + el.pic_url} alt='Shop'
                                                        className='catImage'/>
                                        } else return null
                                    })

                                }
                                <button className="storeimage-btn"
                                        onClick={imazhi}>{ndryshoImazhin ? 'Largohu' : 'Ndrysho Imazhin'}</button>
                                {ndryshoImazhin &&
                                <Fragment>
                                    <div className='image-form'>
                                        <form className='shp_groupform' onSubmit={ruajImazhin}>
                                            <div className='row w-100 '>
                                                <div className='col-6'>
                                                    <input type='file' name='file' id='file'
                                                           onChange={imageDataChange}/>
                                                </div>
                                                <div className='col-6'>
                                                    <button type='submit' className=' form-control'
                                                            disabled={!!imageData ? false : true}>Ruaj Imazhin
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Fragment>
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className={"row shp_groupform w-100"}>
                                <div className={"col-10"}>
                                    <h4 className="pl-2 pb-5">Menaxhimi i Dyqanit</h4>
                                    <form className="form p-2" onSubmit={""}>
                                        <div className="row">
                                            <label className="col-6">Emri i Dyqanit: </label>
                                            <input
                                                className="col-6 input__button"
                                                value={shopData.name}
                                                onChange={emri}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="col-6">Numri Unik Identifikues (NUI) </label>
                                            <input
                                                className="col-6 input__button"
                                                value={shopData.fiscal_nr}
                                                pattern="/^-?\d+\.?\d*$/"
                                                maxLength="9"
                                                onInput={(e) => e.target.value = e.target.value.slice(0, 9)}
                                                onChange={nrunik}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        {isShopEdit ?
                                            <p className="fiscal-nr">Numri Unik Identifikues (NUI) mund te shkruhet
                                                vetem maximum 9 numra.</p> : ''}
                                        <div className="row">
                                            <label className="col-6">Shteti: </label>
                                            <select className="col-6" value={shopData.city_id} onChange={shteti}
                                                    disabled={!isShopEdit}>
                                                {/* <option value={null} >Selekto</option> */}
                                                {stateData?.map((el, index) => {

                                                    return <option value={el._id}>{el.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="row">
                                            <label className="col-6">Qyteti: </label>
                                            <select className="col-6"
                                                    value={shopData.city_id}
                                                    onChange={qyteti}
                                                    disabled={!isShopEdit}>
                                                {cityData?.map((el, index) => {
                                                    return <option key={index} value={el?._id}>{el?.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="row">
                                            <label className="col-6">
                                                Kategoria e Biznesit:{" "}
                                            </label>
                                            <select className="col-6"
                                                    value={shopData.store_cat}
                                                    onChange={kategoria}
                                                    disabled={!isShopEdit}>
                                                {catData?.map((el, index) => {
                                                    return <option key={index} value={el?._id}>{el?.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="row">
                                            <label className="col-6">
                                                {" "}
                                                Lartësia Gjeografike:{" "}
                                            </label>
                                            <input
                                                type='number'
                                                step=".0001"
                                                className="col-6 input__button"
                                                value={shopData.latitude}
                                                onChange={latitude}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="col-6">
                                                {" "}
                                                Gjërsia Gjeografike:{" "}
                                            </label>
                                            <input
                                                type='number'
                                                step=".0001"
                                                className="col-6 input__button"
                                                value={shopData.longitude}
                                                onChange={longitude}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        {/*qitu ja nis gjeshka prej telefoni adresa descsription owrking hours */}
                                        <div className="row">
                                            <label className="col-6">
                                                {" "}
                                                Adresa:{" "}
                                            </label>
                                            <input
                                                type='text'
                                                className="col-6 input__button"
                                                value={shopData.address}
                                                onChange={adresa}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="col-6">
                                                {" "}
                                                Telefoni:{" "}
                                            </label>
                                            <input
                                                type='number'
                                                name="store_phone"
                                                className="col-6 input__button"
                                                value={shopData.phone}
                                                onChange={telefoni}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="col-6">
                                                {" "}
                                                Ora e Fillimit: {" "}
                                            </label>
                                            <input
                                                type='number'
                                                className="col-6 input__button"
                                                value={shopData.opening_hour}
                                                onChange={orariHapjes}
                                                disabled={!isShopEdit}
                                            />
                                            <label className="col-6">
                                                {" "}
                                                Ora e Mbarimit:{" "}
                                            </label>
                                            <input
                                                type='number'
                                                className="col-6 input__button"
                                                value={shopData.closing_hour}
                                                onChange={orariMbylljes}
                                                disabled={!isShopEdit}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="col-6">
                                                {" "}
                                                Përshkrimi:{" "}
                                            </label>
                                            <textarea
                                                // name="store_description"
                                                type="text"
                                                onChange={description}
                                                value={shopData.description}
                                                disabled={!isShopEdit}
                                                className="col-6 text-area-form"
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 text-center'>
                                                <button
                                                    className="form-control mb-2"
                                                    onClick={ndryshoDyqanin}
                                                >
                                                    {isShopEdit ? 'Largohu' : 'Ndrysho Të Dhënat'}
                                                </button>
                                            </div>
                                            <div className='col-6 text-center'>
                                                {isShopEdit &&
                                                <button className="form-control mb-2"
                                                        onClick={ruajNdryshimet}>Ruaj
                                                    Ndryshimet
                                                </button>
                                                }
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
                                        <MyComponent/>
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {shopData != '' ?
                                            <Marker position={[shopData.latitude, shopData.longitude]}>
                                                <Popup>
                                                    A pretty CSS3 popup. <br/> Easily customizable.
                                                </Popup>
                                            </Marker> :
                                            mapState != '' ?
                                                <Marker position={[mapState[0], mapState[1]]}>
                                                    <Popup>
                                                        Pointer. <br/> Pointer.
                                                    </Popup>
                                                </Marker> : ''

                                        }
                                    </MapContainer>
                                </div>
                            </div>
                            {/*<iframe*/}
                            {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.028901992423!2d21.156152950823138!3d42.660742923950295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354f2cbab67d493%3A0x5c97e5834932545a!2sNEWBORN!5e0!3m2!1sen!2s!4v1620776523316!5m2!1sen!2s"*/}
                            {/*    width="100%" height="350" title='gmap' style={{border: 'unset'}} allowfullscreen=""*/}
                            {/*    loading="lazy"></iframe>*/}
                            {/*/!* <iframe src={'https://maps.google.com/maps?q='+shopData.latitude+','+shopData.latitude+'&hl=es&z=14&amp;output=embed'} title='maps' width="600" height="450"  allowfullscreen="" loading="lazy" /> *!/*/}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default BiznesFaqja;
