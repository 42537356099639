import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import Select from "react-select";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './ShtoProdukt.scss';
import jwt_decode from 'jwt-decode';


const ShtoProdukt = (props) => {
    const history = useHistory();
    const [productData, addproductdata] = useState({});
    const [categoriesData, setCategoriesData] = useState([]);
    const [userStores, setUserStores] = useState([]);
    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;
    const [store_id, setStore_id] = useState();


    const vleratBaze = () => {
        const ud = Object.assign({}, productData);
        ud.active = true;
        addproductdata(ud);
    }
    const emri = e => {
        const ud = Object.assign({}, productData);
        ud.name = e.target.value;
        addproductdata(ud);
    }


    const active = (e) => {
        // console.log('checkboxi', e.target.checked)
        console.log('value value valuve active', e.target.value)
        const ud = Object.assign({}, productData);
        ud.active = e.target.value;
        addproductdata(ud);
    }

    const kategoria = e => {
        console.log('SELECTED CATEOGFRYYYYYYYYYYYYYYYYYYYYYYYY', e.value, e.label)
        const ud = Object.assign({}, productData);
        ud.product_cat_id = e.value;
        addproductdata(ud);
    }

    const storeKategori = (e) => {
        console.log('STORE KATEOGRIIIII', e.target.value);
        const ud = Object.assign({}, productData);
        ud.store_id = e.target.value;
        addproductdata(ud);
    }

    const description = e => {
        const ud = Object.assign({}, productData);
        ud.details = e.target.value;
        addproductdata(ud);
    }
    const image = e => {
        // e.preventDefault();
        const ud = Object.assign({}, productData);
        ud.image = e.target.files[0];
        addproductdata(ud);
    }
    // const rmimage = e => {
    //     e.preventDefault();
    //     const ud = Object.assign({}, productData);
    //     ud.image = null;
    //     addproductdata(ud);
    // }

    console.log('store id ', store_id)


    let ar = ''
    let id = ''

    let product = categoriesData?.map((el, i) => {
        // console.log(el)
        if (el?._id == productData?.product_cat_id) {
            ar = el?.name
            id = productData?.product_cat_id
            console.log('AR AR AR AR',ar)
        }
        return {value: el?._id, label: el?.name}
    })


    const editoProduktin = (e) => {
        e.preventDefault();


        console.log('eeeeeeeeeeee', e.target)

        let formDataa = new FormData();

        formDataa.append('name', e.target.name.value);
        formDataa.append('product_cat_id', id);
        formDataa.append("image", e.target.pic_url.files[0]);
        formDataa.append('details', e.target.description.value);
        formDataa.append("store_id", e.target.store_id.value);
        formDataa.append("active", e.target.active.value);
        for (let [key, value] of formDataa) {
            console.log(`${key}: ${value}`)
        }


        axios.post('/admin/store-products/update/' + props.match.params.id, formDataa)
            .then((res) => {
                toast.success('Produkti u editua me sukses!');
                history.push(`/menaxhoproduktet`);
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                history.goBack();
                // window.localStorage.removeItem('cd');
                // window.location = "/login";
            });
    }

    useEffect(() => {

        axios.get('/admin/product-categories/all')
            .then((res) => {
                console.log('axios', res.data);
                setCategoriesData(res.data);
            })
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            });
        axios.get('/admin/store-products/' + props.match.params.id)
            .then((res) => {
                console.log('produkti', res.data);
                addproductdata(res.data);
                setStore_id(res.data.store_id);
            })
            .catch(err => {
                console.log(err);
                toast.error('Diçka shkoi gabim!');
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            });
        getUserStores();
    }, [])


    // console.log('PROOOOOOOOOOOOOOOD', product)

    console.log('ARRRRRR', ar)
    console.log('IDDDDDD', id);


    const getUserStores = async () => {
        try {
            let userStores = await axios.get('/admin/user-store/all');
            console.log(userStores.data);
            setUserStores(userStores?.data)
        } catch (error) {
            window.localStorage.removeItem('cd');
            window.location = "/kyqu";
            console.log(error)
        }
    }


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container">
                    <div className="row">
                        <h3 className='w-100 p-3'> Edito Produktin</h3>
                        <div className="col-md-12">
                            <div className="shp_groupform">
                                <form onSubmit={editoProduktin}>
                                <img src={process.env.REACT_APP_BACK_URL +( productData?.pic_url != null ? productData?.pic_url : categoriesData.pic_url )} width='500px' className='mb-5' alt='userPicture'/>
                                
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        defaultValue={productData.name}
                                        onChange={emri}
                                        required
                                    />
                                    <label>
                                        <Select onChange={kategoria}
                                                name="product_cat_id"
                                                value={{label: ar}}
                                                options={product}
                                        >
                                        </Select>
                                    </label>
                                    <label>
                                        <select
                                            className="form-control"
                                            name="store_id"
                                            value={productData?.store_id}
                                            onChange={storeKategori}>
                                            <option disabled hidden>Select Store</option>
                                            {
                                                userStores?.map((el, i) => {
                                                    return (
                                                        <option value={el?._id} key={el?._id}>{el?.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </label>
                                    <label>
                                    <textarea
                                        name="description"
                                        className="rb_textarea"
                                        rows={3}
                                        cols={60}
                                        placeholder="   Description..."
                                        defaultValue={productData.details}
                                        onChange={description}
                                    >
                                    </textarea>
                                    </label>
                                    
                                    <input
                                        name='pic_url'
                                        type="file"
                                        id='file'
                                        className="form-control"
                                        placeholder="picture"
                                        onChange={image}

                                    />
                                    {/*<div className="active--class">*/}
                                    {/*    <label className='active--label'> Active:</label>*/}
                                    {/*    <input className='active--input form-control'*/}
                                    {/*           type="checkbox"*/}
                                    {/*           checked={productData?.active ? 'checked' : null}*/}
                                    {/*           defaultValue={productData.active}*/}
                                    {/*           onChange={active}*/}
                                    {/*        // disabled={!isUserEdit }*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<label className='col-6'> Active:</label>*/}
                                    <select
                                        className="form-control"
                                        onChange={active}
                                        name="active"
                                        defaultValue={productData?.active}
                                    >
                                        <option value={true}>Aktiv</option>
                                        <option value={false}>Pasiv</option>
                                    </select>
                                    {/* <button onClick={rmimage}>Fshij Imazhin</button> */}
                                    <button type="submit">Edito</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoProdukt;
